import {TeaserRowWithText} from '@smart/editorial-components';
import type {TeaserRowWithTextContent} from '@smart/website-aem-utils';
import {createLinkProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';

export interface TeaserRowWithTextWrapperProps {
  readonly content: TeaserRowWithTextContent;
}

export const TeaserRowWithTextWrapper = React.memo(
  ({content}: TeaserRowWithTextWrapperProps) => {
    const {historyService} = useFeatureServices();

    return (
      <TeaserRowWithText
        componentHeadline={content.componentHeadline}
        indicatorBar={content.indicatorBar}
        items={content.items.map(
          ({headline, imageUrl, imageAltText, copyText, link}) => ({
            headline,
            image: {imageUrl, imageAltText},
            copyText,
            link: createLinkProps({historyService, content: link}),
          }),
        )}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

TeaserRowWithTextWrapper.displayName = `TeaserRowWithTextWrapper`;
