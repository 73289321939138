import type {Content} from '@smart/website-aem-utils';
import type {PageModel} from '@smart/website-page-model';
import type {ActivityTrackingData} from '@smart/website-personalization';
import * as React from 'react';
import type {Personalization} from '../utils/client/fetch-personalization';
import {fetchPersonalization} from '../utils/client/fetch-personalization';
import {useEcidForPersonalization} from './use-ecid-for-personalization';
import {useFeatureServices} from './use-feature-services';

export type ActivityDataByMboxName = Record<string, ActivityData>;

export interface ActivityData {
  readonly trackingData: ActivityTrackingData;
  /** If undefined, the default content must be preserved. */
  readonly content?: Content;
}

export function usePersonalization(
  pageModel: PageModel,
): Personalization | undefined {
  const {logger} = useFeatureServices();
  const ecid = useEcidForPersonalization();
  const [currentPageModel, setCurrentPageModel] = React.useState(pageModel);
  const [currentEcid, setCurrentEcid] = React.useState(ecid);

  const [personalization, setPersonalization] =
    React.useState<Personalization>();

  // See: https://beta.reactjs.org/apis/react/useState#storing-information-from-previous-renders
  if (pageModel !== currentPageModel || ecid !== currentEcid) {
    setCurrentPageModel(pageModel);
    setCurrentEcid(ecid);
    setPersonalization(undefined);
  }

  React.useEffect(() => {
    if (!ecid) {
      return;
    }

    const mboxNames = new Set<string>();

    for (const content of pageModel.contents) {
      if (content.mboxName) {
        mboxNames.add(content.mboxName);
      }
    }

    if (mboxNames.size === 0) {
      return;
    }

    const abortController = new AbortController();

    fetchPersonalization({
      ecid,
      mboxNames: [...mboxNames],
      signal: abortController.signal,
    })
      .then(setPersonalization)
      .catch((error) => {
        if (!abortController.signal.aborted) {
          logger.error(error);
        }
      });

    return () => abortController.abort();
  }, [pageModel, ecid]);

  return personalization;
}
