/* eslint-disable no-console */

import type {ConsumerLoggerCreator} from '@feature-hub/logger';

export const createConsumerConsole: ConsumerLoggerCreator = (
  consumerId,
  consumerName,
) => {
  const prefix = consumerName || consumerId;
  const prefixArgs = [`%c${prefix}`, `font-weight: bold`];

  return {
    trace: console.trace.bind(console, ...prefixArgs),
    debug: console.debug.bind(console, ...prefixArgs),
    info: console.info.bind(console, ...prefixArgs),
    warn: console.warn.bind(console, ...prefixArgs),
    error: console.error.bind(console, ...prefixArgs),
  };
};
