import type {Content} from '@smart/website-aem-utils';
import type {ActivityTrackingData} from '@smart/website-personalization';
import {fetchPersonalizationActivityMetaData} from './fetch-personalization-activity-meta-data';
import {fetchPersonalizationContents} from './fetch-personalization-contents';

export interface Personalization {
  readonly ecid: string;
  readonly activityData: ActivityDataByMboxName;
}

export interface FetchPersonalizationOptions {
  readonly ecid: string;
  readonly mboxNames: string[];
  readonly signal: AbortSignal;
}

// TODO: cache in module scope?
export type ActivityDataByMboxName = Record<string, ActivityData>;

export interface ActivityData {
  readonly trackingData: ActivityTrackingData;
  /** If undefined, the default content must be preserved. */
  readonly content?: Content;
}

const cache = new Map<string, Promise<Personalization>>();

// Must not be an aysnc function so that `use()` can mutate the promise to store
// the value/error, and this mutated promise is a stable reference that is
// stored in the cache map.
// eslint-disable-next-line @typescript-eslint/promise-function-async
export function fetchPersonalization(
  options: FetchPersonalizationOptions,
): Promise<Personalization> {
  const {ecid, mboxNames, signal} = options;
  const cacheKey = `${ecid}${mboxNames.sort().join()}`;

  let promise = cache.get(cacheKey);

  if (promise) {
    return promise;
  }

  promise = (async () => {
    const activityMetaData = await fetchPersonalizationActivityMetaData({
      ecid,
      mboxNames,
      signal,
    });

    const contents = await fetchPersonalizationContents({
      activityMetaData,
      signal,
    });

    const activityData: ActivityDataByMboxName = {};

    for (const [mboxName, {trackingData}] of Object.entries(activityMetaData)) {
      activityData[mboxName] = {trackingData, content: contents[mboxName]};
    }

    return {ecid, activityData};
  })();

  cache.set(cacheKey, promise);

  return promise;
}
