import {useFeatureHubContext} from '@feature-hub/react-hooks';
import type {ExtraLanguageContents} from '@smart/website-extra-language-contents';
import * as React from 'react';
import {fetchExtraLanguageContents} from '../utils/client/fetch-extra-language-contents';
import {useFeatureServices} from './use-feature-services';

export function useExtraLanguageContents(
  language: string,
): ExtraLanguageContents | undefined {
  const [extraLanguageContents, setExtraLanguageContents] =
    React.useState<ExtraLanguageContents>();

  const {logger} = useFeatureServices();
  const {featureAppManager} = useFeatureHubContext();

  React.useEffect(() => {
    const abortController = new AbortController();

    fetchExtraLanguageContents({language, signal: abortController.signal})
      .then((fetchedExtraLanguageContents) => {
        setExtraLanguageContents(fetchedExtraLanguageContents);

        (typeof requestIdleCallback !== `undefined`
          ? requestIdleCallback
          : setTimeout)(async () => {
          if (fetchedExtraLanguageContents?.paymentCalculatorFeatureApp) {
            const {baseUrl, src} =
              fetchedExtraLanguageContents.paymentCalculatorFeatureApp;

            // TODO: move to utils? see also use-async-feature-app-scope.ts
            const url = baseUrl
              ? `${baseUrl.replace(/\/$/, ``)}/${src.replace(/^\//, ``)}`
              : src;

            try {
              await featureAppManager.preloadFeatureApp(url);
            } catch {}
          }
        });
      })
      .catch((error) => {
        if (!abortController.signal.aborted) {
          logger.error(error);
        }
      });

    return () => abortController.abort();
  }, [language]);

  return extraLanguageContents;
}
