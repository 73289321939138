import type {TextParagraphProps} from '@smart/editorial-components';
import {TextParagraph} from '@smart/editorial-components';
import type {TextParagraphContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useLink} from '../hooks/use-link';
import {getItemContent} from '../utils/client/accordion-get-content';
import {PageModelContext} from './page';

export interface TextParagraphWrapperProps {
  readonly content: TextParagraphContent;
  readonly cdnOrigin: string;
}

export const TextParagraphWrapper = React.memo(
  ({content, cdnOrigin}: TextParagraphWrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const {pubSubService} = useFeatureServices();
    const link = useLink(content.link, {
      websiteTrackingData: {
        type: `navigation`,
        region: `text_paragraph`,
        name: content.link?.label || ``,
      },
      languageTag,
      marketId,
    });
    const secondaryLink = useLink(content.secondaryLink, {
      websiteTrackingData: {
        type: `navigation`,
        region: `text_paragraph`,
        name: content.secondaryLink?.label || ``,
      },
      languageTag,
      marketId,
    });
    const textParagraph: TextParagraphProps = {
      layoutAlign: content.layoutAlign,
      headlineTagType: content.headlineTagType,
      title: content.title,
      componentHeadline: content.componentHeadline,
      headlineAlign: content.headlineAlign,
      logo: content.logo,
      size: content.size,
      asAccordion: content.asAccordion,
      richTextColumn1: content.richTextColumn1,
      richTextColumn2: content.richTextColumn2,
      useLimeGreen: content.useLimeTheme,
      removeTopSpacing: content.removeTopSpacing,
      link,
      secondaryLink,
      renderSubscribePopupButton: content.renderSubscribePopupButton,
      subscribePopupButtonText: content.subscribePopupButtonText,
      mode: content.modeSelector,
      publishFunc: pubSubService.publish,
      content: getItemContent(content.components, cdnOrigin),
    };

    return <TextParagraph {...textParagraph} />;
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.cdnOrigin === nextProps.cdnOrigin,
);

TextParagraphWrapper.displayName = `TextParagraphWrapper`;
