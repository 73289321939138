import {parseJsonCookie} from '@smart/utils';
import {z} from 'zod';

export function getDemoVehicleMode(): boolean {
  if (window.sessionStorage.getItem(`demoVehicle`) === `true`) {
    return true;
  }
  const {demoVehicle: isDemoVehicle} = parseJsonCookie({
    cookieName: `smart-adb-demo_vehicle`,
    schema: z.object({demoVehicle: z.boolean()}),
    cookieData: document.cookie,
  }) ?? {demoVehicle: false};

  if (isDemoVehicle) {
    window.sessionStorage.setItem(`demoVehicle`, `true`);
  }

  return isDemoVehicle;
}
