import type {Logger} from '@feature-hub/core';
import {createFeatureHub} from '@feature-hub/core';
import type {HistoryServiceV3} from '@feature-hub/history-service';
import {loadFederatedModule} from '@feature-hub/module-loader-federation';
import {FeatureHubContext} from '@feature-hub/react';
import type {AdbContextServiceV2} from '@smart/adb-context-service';
import {defineAdbContextService} from '@smart/adb-context-service';
import type {CartUpdateServiceV1} from '@smart/cart-update-service';
import type {EndpointDirectoryV2} from '@smart/endpoint-directory';
import {readFeatureFlagsDOM} from '@smart/feature-app';
import type {LocaleListenerV3, LocaleServiceV3} from '@smart/locale-service';
import type {LoginServiceV3} from '@smart/login-service';
import {defineLoginService} from '@smart/login-service';
import type {
  PageContextListenerV1,
  PageContextServiceV1,
} from '@smart/page-context-service';
import type {PaymentConfigServiceV1} from '@smart/payment-config-service';
import {paymentConfigServiceDefinition} from '@smart/payment-config-service';
import {setGlobalFeatureFlags} from '@smart/platform-utils';
import type {PricePreferencesProviderV1} from '@smart/price-preferences-provider';
import type {PubSubServiceV1} from '@smart/pub-sub-service';
import type {SelectedVehicleServiceV1} from '@smart/selected-vehicle-service';
import {
  adbContextStateCookieName,
  authorizeStateCookieName,
  loginStateCookieName,
} from '@smart/website-universal-utils';
import * as React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {App} from './app';
// The Cookie Pro Dialog is only rendered in the server, but still imported here
// to extract its styles.
import './components/cookie-pro-dialog';
import {IntegratorContext} from './contexts/integrator-context';
import './global.scss';
import type {FeatureServicesContextValue} from './hooks/use-feature-services';
import {FeatureServicesContext} from './hooks/use-feature-services';
import {PageModelResponseCacheContext} from './hooks/use-page-model-response-cache-context';
import {getMainElement, getUniversalState} from './template.client';
import {connectSelectedVehicleService} from './utils/client/connect-selected-vehicle-service';
import {createConsumerConsole} from './utils/universal/create-consumer-console';
import {
  defineUniversalFeatureServices,
  integratorId,
} from './utils/universal/define-universal-feature-services';
import {adobeLaunchScriptUrls} from './utils/universal/env-consts';
import {isFeatureAppDefined} from './utils/universal/is-feature-app-defined';

(async () => {
  const {pageModel, endpointDirectory, maintenanceMode} =
    await getUniversalState();

  const {featureFlags} = pageModel;
  // global feature flags become available from here
  setGlobalFeatureFlags(featureFlags);

  const {envName, preview} = endpointDirectory;

  void import(`./datadog-rum`).then(({setupDatadogBrowserRum}) => {
    setupDatadogBrowserRum(envName, preview);
  });

  let updateLocale: LocaleListenerV3;
  let updatePageContext: PageContextListenerV1;

  const featureServiceDefinitions = defineUniversalFeatureServices({
    locale: pageModel.locale,
    setLocaleListener: (listener) => {
      updateLocale = listener;
    },
    endpointDirectory,
    initialPageContext: {
      isCustomizerFeatureAppDefined: isFeatureAppDefined(
        pageModel,
        `CustomizerFeatureAppModel`,
      ),
    },
    setPageContextListener: (listener) => {
      updatePageContext = listener;
    },
  });

  const {
    cartUpdateServiceDefinition,
    endpointDirectoryDefinition,
    historyServiceDefinition,
    localeServiceDefinition,
    loggerDefinition,
    pageContextServiceDefiniton,
    pricePreferencesProviderDefinition,
    pubSubServiceDefinition,
    selectedVehicleServiceDefiniton,
  } = featureServiceDefinitions;

  const loginServiceDefinition = defineLoginService({
    authorizePathname: `/authorize`,
    authorizeStateCookieName,
    loginStateCookieName,
    logoutPathname: `/logout`,
  });

  const adbContextServiceDefinition = defineAdbContextService({
    cookieName: adbContextStateCookieName,
    resetPathname: `/adb-context-reset`,
  });

  const {featureAppManager, featureServices} = createFeatureHub(integratorId, {
    moduleLoader: loadFederatedModule,
    featureServiceDefinitions: [
      ...Object.values(featureServiceDefinitions),
      adbContextServiceDefinition,
      loginServiceDefinition,
      paymentConfigServiceDefinition,
    ],
    featureServiceDependencies: {
      [adbContextServiceDefinition.id]: `^2.0.0`,
      [cartUpdateServiceDefinition.id]: `^1.0.0`,
      [endpointDirectoryDefinition.id]: `^3.0.0`,
      [historyServiceDefinition.id]: `^3.0.0`,
      [localeServiceDefinition.id]: `^3.0.0`,
      [loggerDefinition.id]: `^1.0.0`,
      [loginServiceDefinition.id]: `^3.0.0`,
      [pageContextServiceDefiniton.id]: `^1.0.0`,
      [paymentConfigServiceDefinition.id]: `^1.0.0`,
      [pricePreferencesProviderDefinition.id]: `^1.0.0`,
      [pubSubServiceDefinition.id]: `^1.0.0`,
      [selectedVehicleServiceDefiniton.id]: `^1.0.0`,
    },
    logger: createConsumerConsole(`feature-hub`),
  });

  const featureServicesContextValue: FeatureServicesContextValue = {
    adbContextService: featureServices[
      adbContextServiceDefinition.id
    ] as AdbContextServiceV2,
    cartUpdateService: featureServices[
      cartUpdateServiceDefinition.id
    ] as CartUpdateServiceV1,
    endpointDirectory: featureServices[
      endpointDirectoryDefinition.id
    ] as EndpointDirectoryV2,
    historyService: featureServices[
      historyServiceDefinition.id
    ] as HistoryServiceV3,
    localeService: featureServices[
      localeServiceDefinition.id
    ] as LocaleServiceV3,
    logger: featureServices[loggerDefinition.id] as Logger,
    loginService: featureServices[loginServiceDefinition.id] as LoginServiceV3,
    pageContextService: featureServices[
      pageContextServiceDefiniton.id
    ] as PageContextServiceV1,
    paymentConfigService: featureServices[
      paymentConfigServiceDefinition.id
    ] as PaymentConfigServiceV1,
    pricePreferencesProvider: featureServices[
      pricePreferencesProviderDefinition.id
    ] as PricePreferencesProviderV1,
    pubSubService: featureServices[
      pubSubServiceDefinition.id
    ] as PubSubServiceV1,
    selectedVehicleService: featureServices[
      selectedVehicleServiceDefiniton.id
    ] as SelectedVehicleServiceV1,
  };

  (window as any).websiteFeatureServices = featureServicesContextValue;

  const {historyService, logger} = featureServicesContextValue;
  const {history} = historyService;

  const selectedVehicleService = featureServices[
    selectedVehicleServiceDefiniton.id
  ] as SelectedVehicleServiceV1;

  connectSelectedVehicleService({selectedVehicleService, history});

  logger.debug(
    `Using "@smart/editorial-components" version "${process.env.EDITORIAL_COMPONENTS_VERSION}".`,
  );

  logger.debug(
    `Using "@smart/web-components" version "${process.env.WEB_COMPONENTS_VERSION}".`,
  );

  const debug =
    (envName !== `prod` || window.location.hostname === `localhost`) &&
    new URLSearchParams(history.location.search).has(`debug`);

  const pageModelResponseCache = new Map();

  const featureFlagsDOM = readFeatureFlagsDOM();

  const reactElement = (
    <React.StrictMode>
      <HelmetProvider>
        <FeatureHubContext.Provider value={{featureAppManager}}>
          <FeatureServicesContext.Provider value={featureServicesContextValue}>
            <IntegratorContext.Provider
              value={{
                updateLocale: updateLocale!,
                updatePageContext: updatePageContext!,
              }}
            >
              <PageModelResponseCacheContext.Provider
                value={pageModelResponseCache}
              >
                <App
                  initialPageModel={pageModel}
                  debug={debug}
                  maintenanceMode={maintenanceMode}
                  featureFlags={featureFlagsDOM}
                />
              </PageModelResponseCacheContext.Provider>
            </IntegratorContext.Provider>
          </FeatureServicesContext.Provider>
        </FeatureHubContext.Provider>
      </HelmetProvider>
    </React.StrictMode>
  );

  if (debug) {
    createRoot(getMainElement()).render(reactElement);
  } else {
    hydrateRoot(getMainElement(), reactElement);
  }

  (typeof requestIdleCallback !== `undefined`
    ? requestIdleCallback
    : setTimeout)(() => {
    const adobeLaunchScript = document.createElement(`script`);
    adobeLaunchScript.src = adobeLaunchScriptUrls[endpointDirectory.envName];
    adobeLaunchScript.async = true;

    document.head.appendChild(adobeLaunchScript);
  });
  // eslint-disable-next-line no-console
})().catch(console.error);
