import type {LinkContent} from '@smart/aem-utils';
import {
  LinkDestination,
  createId,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface HeadlineWithTextContent {
  readonly type: 'headline-with-text';
  readonly id: string;
  readonly anchorId: string;
  readonly headline: string | undefined;
  readonly copyText: string;
  readonly link: LinkContent | undefined;
  readonly highlight: boolean | undefined;
  readonly indicatorBar: boolean | undefined;
  readonly mboxName: string | undefined;
}

const HeadlineWithTextContentFragment = z.object({
  headline: z.string().nullish(),
  multilineCopyText: z.object({markdown: z.string()}),
  linkDestination: LinkDestination.nullish(),
  linkLabel: z.string().nullish(),
  highlight: z.boolean().nullish(),
  indicatorBar: z.boolean().nullish(),
  mboxName: z.string().nullish(),
});

export function createHeadlineWithTextContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'HeadlineWithTextModel'}
  >,
): HeadlineWithTextContent {
  const {
    headline,
    multilineCopyText,
    linkDestination,
    linkLabel,
    highlight,
    indicatorBar,
    mboxName,
  } = sanitizeGraphqlData(HeadlineWithTextContentFragment, contentFragment);

  return {
    type: `headline-with-text`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    headline,
    copyText: multilineCopyText.markdown,
    link: createLinkContent({destination: linkDestination, label: linkLabel}),
    highlight,
    indicatorBar,
    mboxName,
  };
}
