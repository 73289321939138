import type {Logger} from '@feature-hub/core';
import type {PageModelResponse} from '@smart/website-page-model';
import type {APIGatewayProxyEventQueryStringParameters} from 'aws-lambda';

export interface FetchPageModelOptions {
  readonly origin?: string;
  readonly headers?: Record<string, string>;
  readonly logger?: Logger;
  readonly cache: Map<string, Promise<PageModelResponse>>;
  readonly params?: APIGatewayProxyEventQueryStringParameters | null;
}

// Must not be an aysnc function so that `use()` can mutate the promise to store
// the value/error, and this mutated promise is a stable reference that is
// stored in the cache map.
// eslint-disable-next-line @typescript-eslint/promise-function-async
export function fetchPageModel(
  pathname: string,
  options: FetchPageModelOptions,
): Promise<PageModelResponse> {
  const {origin = ``, headers, logger = console, cache, params} = options;

  let promise = cache.get(pathname);

  if (promise) {
    return promise;
  }

  promise = (async () => {
    const url = `${origin}${
      process.env.BASE_URL
    }/page-model?pathname=${encodeURIComponent(pathname)}&${new URLSearchParams(
      (params as Record<string, string>) || {},
    ).toString()}`;

    const response = await fetch(url, {headers});

    logger.debug(
      `x-amzn-trace-id: ${response.headers.get(`x-amzn-trace-id`)}`,
      `x-lambda-request-id: ${response.headers.get(`x-lambda-request-id`)}`,
    );

    if (!response.ok) {
      logger.debug(
        `Could not fetch page model: ${response.status} (${response.statusText})`,
        `Response: ${await response.text()}`,
      );

      throw Error(`Could not fetch page model for ${pathname}.`);
    }

    return response.json() as Promise<PageModelResponse>;
  })();

  cache.set(pathname, promise);

  return promise;
}
