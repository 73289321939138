import type {FeatureAppContent} from '@smart/website-extra-language-contents';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {FeatureAppLoader} from './feature-app-loader';

export interface PaymentCalculatorFeatureAppLoaderProps {
  readonly content: FeatureAppContent;
}

export const PaymentCalculatorFeatureAppLoader = React.memo(
  ({content}: PaymentCalculatorFeatureAppLoaderProps) => {
    const {paymentConfigService} = useFeatureServices();

    const [loadPaymentCalculator, setLoadPaymentCalculator] =
      React.useState(false);

    React.useEffect(() => {
      if (loadPaymentCalculator) {
        return;
      }

      return paymentConfigService?.registerCalculatorLoader(() => {
        setLoadPaymentCalculator(true);
      });
    }, [loadPaymentCalculator]);

    const {baseUrl, src} = content;

    return loadPaymentCalculator ? (
      <FeatureAppLoader
        featureAppId="payment-calculator"
        baseUrl={baseUrl}
        src={src}
      />
    ) : null;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

PaymentCalculatorFeatureAppLoader.displayName = `PaymentCalculatorFeatureAppLoader`;
