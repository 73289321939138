import {Heading, Text, TextButton, TextLink} from '@smart/web-components';
import type {ExternalLinkPopUpContent} from '@smart/website-extra-language-contents';
import * as React from 'react';
import type {DialogRef} from './dialog';
import {Dialog} from './dialog';
import styles from './external-link-dialog.module.scss';

export interface ExternalLinkDialogProps {
  readonly content: ExternalLinkPopUpContent;
}

export const ExternalLinkDialog = React.memo(
  React.forwardRef<DialogRef, ExternalLinkDialogProps>(({content}, ref) => {
    const dialogRef = React.useRef<DialogRef>(null);
    const [externalLinkUrl, setExternalLinkUrl] = React.useState<string>();

    React.useImperativeHandle<DialogRef, DialogRef>(
      ref,
      () => ({
        close: () => dialogRef.current?.close(),
        showModal: () => dialogRef.current?.showModal(),
      }),
      [],
    );

    React.useEffect(() => {
      if (externalLinkUrl) {
        return;
      }

      const abortController = new AbortController();

      document.body.addEventListener(
        `click`,
        (event) => {
          if (
            event.altKey ||
            event.ctrlKey ||
            event.metaKey ||
            event.shiftKey
          ) {
            return;
          }

          const anchorElement =
            event.target instanceof HTMLElement &&
            findAnchorElement(event.target);

          if (anchorElement && isExternalUrl(anchorElement.href)) {
            event.preventDefault();
            setExternalLinkUrl(anchorElement.href);
          }
        },
        {signal: abortController.signal},
      );

      return () => abortController.abort();
    }, [externalLinkUrl]);

    const handleClose = React.useCallback(
      () => setExternalLinkUrl(undefined),
      [],
    );

    React.useEffect(() => {
      if (externalLinkUrl) {
        dialogRef.current?.showModal();
      }
    }, [externalLinkUrl]);

    const closeDialog = React.useCallback(() => {
      dialogRef.current?.close();
    }, []);

    if (!externalLinkUrl) {
      return null;
    }

    const {headline, copyText, leavePageButtonLabel, stayHereButtonLabel} =
      content;

    return (
      <Dialog ref={dialogRef} className={styles.dialog} onClose={handleClose}>
        <Heading
          tag="h1"
          variant="headline-functional-400"
          className={styles.headline}
        >
          {headline}
        </Heading>
        <Text className={styles.text}>{copyText}</Text>
        <div className={styles.buttons}>
          <TextButton
            className={styles.button}
            variant="secondary"
            onClick={closeDialog}
          >
            {stayHereButtonLabel}
          </TextButton>
          <TextLink
            className={styles.button}
            variant="button-primary"
            linkText={leavePageButtonLabel}
            href={externalLinkUrl}
            rel="noopener"
            target="_blank"
            onClick={closeDialog}
          />
        </div>
      </Dialog>
    );
  }),
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

ExternalLinkDialog.displayName = `ExternalLinkDialog`;

function findAnchorElement(
  element: HTMLElement,
): HTMLAnchorElement | undefined {
  let currentElement: HTMLElement | null = element;

  do {
    if (currentElement instanceof HTMLAnchorElement) {
      return currentElement;
    }
  } while ((currentElement = currentElement.parentElement));

  return undefined;
}

function isExternalUrl(url: string): boolean {
  const {hostname, protocol, origin} = new URL(url);

  const isBlob = protocol === `blob:`;
  const smartHost = `.smart.com`;

  if (document.location.hostname === hostname) {
    return false;
  }

  if (isBlob && origin.endsWith(smartHost)) {
    return false;
  }

  return hostname !== `smart.com` && !hostname.endsWith(smartHost);
}
