import type {CreateMediaUrlOptions, LinkContent} from '@smart/aem-utils';
import {
  LinkDestination,
  createId,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
import type {ExtendedFlexibleGalleryContent} from './create-extended-flexible-gallery-content.js';
import {
  ExtendedFlexibleGalleryContentFragment,
  createExtendedFlexibleGalleryContent,
} from './create-extended-flexible-gallery-content.js';
import type {MediaStageContent} from './create-media-stage-content.js';
import {
  MediaStageContentFragment,
  createMediaStageContent,
} from './create-media-stage-content.js';
import type {RichtextContent} from './create-richtext-content.js';
import {
  RichtextContentFragment,
  createRichtextContent,
} from './create-richtext-content.js';
import type {StatementContent} from './create-statement-content.js';
import {
  StatementContentFragment,
  createStatementContent,
} from './create-statement-content.js';
import type {TeaserRowV2Content} from './create-teaser-row-v2-content.js';
import {
  TeaserRowV2ContentFragment,
  createTeaserRowV2Content,
} from './create-teaser-row-v2-content.js';
import type {TextWithMediaContent} from './create-text-with-media-content.js';
import {
  TextWithMediaContentFragment,
  createTextWithMediaContent,
} from './create-text-with-media-content.js';

export interface HeadlineV2Content {
  readonly type: 'headline-v2';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly mode?: modeType;
  readonly layout?: 'left aligned' | '2/3';
  readonly size?: 'Headline/200' | 'Headline/300';
  readonly asAccordion?: boolean;
  readonly headline: string;
  readonly subHeadline: string;
  readonly link?: LinkContent;
  readonly logos?: string[];
  readonly mboxName?: string;
  readonly components: (
    | {
        type: 'extendedFlexibleGallery';
        content: ExtendedFlexibleGalleryContent;
      }
    | {type: 'teaserRowV2'; content: TeaserRowV2Content}
    | {type: 'statement'; content: StatementContent}
    | {type: 'textWithMedia'; content: TextWithMediaContent}
    | {type: 'mediaStage'; content: MediaStageContent}
    | {type: 'richtext'; content: RichtextContent}
  )[];
}

export const HeadlineV2ContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  mode: z.string().nullish(),
  layout: z.string().nullish(),
  headline: z.string(),
  size: z.string().nullish(),
  asAccordion: z.boolean().nullish(),
  subHeadline: z.string(),
  linkLabel: z.string().nullish(),
  linkDestination: LinkDestination.nullish(),
  logos: z.array(z.object({_path: z.string()})).nullish(),
  mboxName: z.string().nullish(),
  components: z.array(
    z.discriminatedUnion(`__typename`, [
      ExtendedFlexibleGalleryContentFragment.extend({
        __typename: z.literal(`ExtendedFlexibleGalleryModel`),
        _path: z.string(),
      }),
      TeaserRowV2ContentFragment.extend({
        __typename: z.literal(`TeaserRowV2Model`),
        _path: z.string(),
      }),
      StatementContentFragment.extend({
        __typename: z.literal(`StatementModel`),
        _path: z.string(),
      }),
      TextWithMediaContentFragment.extend({
        __typename: z.literal(`TextWithMediaModel`),
        _path: z.string(),
      }),
      MediaStageContentFragment.extend({
        __typename: z.literal(`MediaStageModel`),
        _path: z.string(),
      }),
      RichtextContentFragment.extend({
        __typename: z.literal(`RichtextModel`),
        _path: z.string(),
      }),
    ]),
  ),
});
function getComponents(
  fragment: z.TypeOf<typeof HeadlineV2ContentFragment>['components'][0],
  options: CreateMediaUrlOptions,
) {
  if (fragment.__typename === `ExtendedFlexibleGalleryModel`) {
    return {
      type: `extendedFlexibleGallery` as const,
      content: createExtendedFlexibleGalleryContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'ExtendedFlexibleGalleryModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `TeaserRowV2Model`) {
    return {
      type: `teaserRowV2` as const,
      content: createTeaserRowV2Content(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'TeaserRowV2Model'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `StatementModel`) {
    return {
      type: `statement` as const,
      content: createStatementContent(
        fragment as Extract<AemContentFragment, {__typename: 'StatementModel'}>,
      ),
    };
  }
  if (fragment.__typename === `TextWithMediaModel`) {
    return {
      type: `textWithMedia` as const,
      content: createTextWithMediaContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'TextWithMediaModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `MediaStageModel`) {
    return {
      type: `mediaStage` as const,
      content: createMediaStageContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'MediaStageModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `RichtextModel`) {
    return {
      type: `richtext` as const,
      content: createRichtextContent(
        fragment as Extract<AemContentFragment, {__typename: 'RichtextModel'}>,
      ),
    };
  }

  return null;
}

export function createHeadlineV2Content(
  contentFragment: Extract<AemContentFragment, {__typename: 'HeadlineV2Model'}>,
  options: CreateMediaUrlOptions,
): HeadlineV2Content {
  const {
    removeTopSpacing,
    mode,
    layout,
    headline,
    subHeadline,
    linkLabel,
    linkDestination,
    logos,
    size,
    asAccordion,
    mboxName,
    components,
  } = sanitizeGraphqlData(HeadlineV2ContentFragment, contentFragment);
  return {
    type: `headline-v2`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    removeTopSpacing,
    mode: mode as modeType,
    size: size as HeadlineV2Content['size'],
    layout: layout as HeadlineV2Content['layout'],
    headline,
    subHeadline,
    link: createLinkContent({
      destination: linkDestination,
      label: linkLabel,
    }),
    logos:
      logos && logos.length > 0
        ? logos.map((l) => createMediaUrl(`image`, l._path, options))
        : [],
    mboxName,
    asAccordion,
    components:
      components && components.length > 0
        ? components
            .map((c) => getComponents(c, options))
            .filter(<T>(value: T | null | undefined): value is T => {
              return value !== null && value !== undefined;
            })
        : [],
  };
}
