/**
 * https://smarteurope.atlassian.net/browse/FED-1119
 * https://experienceleague.adobe.com/en/docs/platform-learn/implement-web-sdk/applications-setup/setup-target#add-flicker-handling
 */
export const adobeTargetPersonalizationScript = `(function (e, a, n, t) {
        if (a) return;
        var i = e.head;
        if (i) {
          var o = e.createElement('style');
          o.id = 'alloy-prehiding';
          o.innerText = n;
          i.appendChild(o);
          setTimeout(function () {
            if (o.parentNode) {
              o.parentNode.removeChild(o);
            }
          }, t);
        }
      })(
        document,
        document.location.href.indexOf('adobe_authoring_enabled') !== -1,
        'body { opacity: 0 !important }',
        3000,
      );`;
