import {FactsAndFigures} from '@smart/editorial-components';
import type {FactsAndFiguresContent} from '@smart/website-aem-utils';
import {
  createLegalDisclaimerProps,
  createLinkProps,
} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';

export interface FactsAndFiguresWrapperProps {
  readonly content: FactsAndFiguresContent;
}

export const FactsAndFiguresWrapper = React.memo(
  ({content}: FactsAndFiguresWrapperProps) => {
    const {componentHeadline, items: cards, indicatorBar} = content;
    const {historyService} = useFeatureServices();

    return (
      <FactsAndFigures
        componentHeadline={componentHeadline}
        items={cards.map(
          ({primaryLink, secondaryLink, legalDisclaimers, ...rest}) => ({
            ...rest,
            legalDisclaimers: createLegalDisclaimerProps(
              legalDisclaimers,
              historyService,
            ),
            primaryLink:
              primaryLink &&
              createLinkProps({historyService, content: primaryLink}),
            secondaryLink:
              secondaryLink &&
              createLinkProps({historyService, content: secondaryLink}),
          }),
        )}
        indicatorBar={indicatorBar}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

FactsAndFiguresWrapper.displayName = `FactsAndFiguresWrapper`;
