import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {useFeatureServices} from './use-feature-services';

export function useNavigationScrolling(pageModel: PageModel): void {
  const {historyService} = useFeatureServices();
  const {history} = historyService;

  React.useLayoutEffect(() => {
    if (history.action !== `POP`) {
      window.scrollTo({top: 0});
    }
  }, [pageModel]);
}
