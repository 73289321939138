import {FeatureFlagsProvider} from '@smart/feature-app';
import {ComponentProvider} from '@smart/react-components';
import type {FeatureFlagPayload} from '@smart/utils';
import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {useEffect} from 'react';
import {Page} from './components/page';
import type {MaintenanceMode} from './utils/server/fetch-maintenance-mode';

export interface AppProps {
  readonly initialPageModel: PageModel;
  readonly debug?: boolean;
  readonly maintenanceMode?: MaintenanceMode;
  readonly featureFlags: FeatureFlagPayload | null;
}

export function App({
  initialPageModel,
  debug,
  maintenanceMode,
  featureFlags,
}: AppProps): JSX.Element {
  useEffect(() => {
    document.body.classList.add(`newDesignToken`);
    return () => {
      document.body.classList.remove(`newDesignToken`);
    };
  }, []);

  return (
    <FeatureFlagsProvider context={{featureFlags}}>
      <ComponentProvider
        theme="light"
        locale={initialPageModel.locale.languageTag}
      >
        <React.Suspense>
          <Page
            initialPageModel={initialPageModel}
            debug={debug}
            maintenanceMode={maintenanceMode}
          />
        </React.Suspense>
      </ComponentProvider>
    </FeatureFlagsProvider>
  );
}
