import {
  createRootLocationTransformer,
  defineHistoryService,
} from '@feature-hub/history-service';
import {defineLogger} from '@feature-hub/logger';
import {cartUpdateServiceDefinition} from '@smart/cart-update-service';
import type {EndpointDirectoryV3} from '@smart/endpoint-directory';
import {defineEndpointDirectory} from '@smart/endpoint-directory';
import type {LocaleListenerV3, LocaleV3} from '@smart/locale-service';
import {defineLocaleService} from '@smart/locale-service';
import type {
  PageContext,
  PageContextListenerV1,
} from '@smart/page-context-service';
import {definePageContextService} from '@smart/page-context-service';
import {definePricePreferencesProvider} from '@smart/price-preferences-provider';
import {pubSubServiceDefinition} from '@smart/pub-sub-service';
import {defineSelectedVehicleService} from '@smart/selected-vehicle-service';
import {createConsumerConsole} from './create-consumer-console';

export interface DefineUniversalFeatureServicesOptions {
  readonly locale: LocaleV3;
  readonly setLocaleListener?: (listener: LocaleListenerV3) => void;
  readonly endpointDirectory: EndpointDirectoryV3;
  readonly initialPageContext: PageContext;
  readonly setPageContextListener?: (listener: PageContextListenerV1) => void;
}

export const integratorId = `smart-website`;
const isServer = typeof window === `undefined`;

export function defineUniversalFeatureServices(
  options: DefineUniversalFeatureServicesOptions,
) {
  const {
    locale,
    setLocaleListener = () => undefined,
    endpointDirectory,
    initialPageContext,
    setPageContextListener = () => undefined,
  } = options;

  const endpointDirectoryDefinition =
    defineEndpointDirectory(endpointDirectory);

  const historyServiceDefinition = defineHistoryService(
    createRootLocationTransformer({
      consumerPathsQueryParamName: `---`,
      primaryConsumerHistoryKey: integratorId,
    }),
    {
      mode: isServer ? `static` : `browser`,
      getHistoryKey: ({consumerId, consumerName}) => consumerName ?? consumerId,
    },
  );

  const localeServiceDefinition = defineLocaleService({
    locale,
    subscribe: setLocaleListener,
  });

  const loggerDefinition = defineLogger(
    isServer ? undefined : createConsumerConsole,
  );

  const pageContextServiceDefiniton = definePageContextService({
    initialPageContext,
    subscribe: setPageContextListener,
  });

  const pricePreferencesProviderDefinition = definePricePreferencesProvider({
    initialPriceType: `private`,
    initialShowPrices: true,
  });

  const marketId = locale.marketId;
  const masterMy25Pn18 = [`gb`].includes(marketId)
    ? undefined // TODO: add RHD pn18
    : `HXYH2D3C61EU000006`; // LHD pn18

  const isMy25Enabled = [`at`, `pt`, `se`, `fr`, `ch`, `nl`, `be`].includes(
    marketId,
  );
  const selectedVehicleServiceDefiniton = defineSelectedVehicleService({
    masterPn18: isMy25Enabled ? masterMy25Pn18 : undefined,
    marketId,
  });

  return {
    cartUpdateServiceDefinition,
    endpointDirectoryDefinition,
    historyServiceDefinition,
    localeServiceDefinition,
    loggerDefinition,
    pageContextServiceDefiniton,
    pricePreferencesProviderDefinition,
    pubSubServiceDefinition,
    selectedVehicleServiceDefiniton,
  };
}
