import {FullWidthTeaser} from '@smart/editorial-components';
import type {FullWidthTeaserContent} from '@smart/website-aem-utils';
import {createLegalDisclaimerProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useLink} from '../hooks/use-link';

export interface FullWidthTeaserWrapperProps {
  readonly content: FullWidthTeaserContent;
}

export const FullWidthTeaserWrapper = React.memo(
  ({content}: FullWidthTeaserWrapperProps) => {
    const {historyService} = useFeatureServices();
    const link = useLink(content.link);
    const {headline, copyText, image, legalDisclaimers} = content;

    return (
      <FullWidthTeaser
        headline={headline}
        copyText={copyText}
        image={image}
        link={link}
        legalDisclaimers={createLegalDisclaimerProps(
          legalDisclaimers,
          historyService,
        )}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

FullWidthTeaserWrapper.displayName = `FullWidthTeaserWrapper`;
