import type {Content} from '@smart/website-aem-utils';
import type {ActivityMetaDataByMboxName} from '@smart/website-personalization';
import {fetchContent} from './fetch-content';

export interface FetchPersonalizationContentsOptions {
  readonly activityMetaData: ActivityMetaDataByMboxName;
  readonly signal: AbortSignal;
}

export type ContentByMboxName = Record<string, Content | undefined>;

export async function fetchPersonalizationContents(
  options: FetchPersonalizationContentsOptions,
): Promise<ContentByMboxName> {
  const {activityMetaData, signal} = options;

  return Object.fromEntries(
    await Promise.all(
      Object.entries(activityMetaData).map<
        Promise<[string, Content | undefined]>
      >(async ([mboxName, {contentFragment}]) => {
        if (!contentFragment) {
          return [mboxName, undefined];
        }

        const content = await fetchContent({contentFragment, signal});

        if (!content) {
          throw new Error(
            `Could not fetch content for ${JSON.stringify({
              mboxName,
              contentFragment,
            })}, aborting personalization.`,
          );
        }

        return [mboxName, content];
      }),
    ),
  );
}
