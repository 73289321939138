import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  ImageContent,
  LegalDisclaimers,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
import type {AddOnContent} from '../create-add-on-content.js';
import {
  AddOnContentFragment,
  PriceTagContentFragment,
  createAddOnContent,
} from '../create-add-on-content.js';

export interface ImageStageContent {
  readonly type: 'image-stage';
  readonly id: string;
  readonly anchorId: string;
  readonly headline: string | undefined;
  readonly addOn: AddOnContent | undefined;
  readonly image: Image;
  readonly link: LinkContent | undefined;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

const ImageStageContentFragment = z
  .object({
    headline: z.string().nullish(),
    addOn: AddOnContentFragment.nullish(),
    priceTag: PriceTagContentFragment.nullish(),
    linkDestination: LinkDestination.nullish(),
    linkLabel: z.string().nullish(),
    legalDisclaimer: LegalDisclaimers.nullish(),
    mboxName: z.string().nullish(),
  })
  .and(ImageContent);

export function createImageStageContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'ImageStageModel'}>,
  options: CreateMediaUrlOptions,
): ImageStageContent {
  const sanitizedContentFragment = sanitizeGraphqlData(
    ImageStageContentFragment,
    contentFragment,
  );

  const {
    headline,
    priceTag,
    addOn = priceTag,
    image,
    imageAltText,
    linkDestination,
    linkLabel,
    legalDisclaimer,
    mboxName,
  } = sanitizedContentFragment;

  return {
    type: `image-stage`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    headline,
    addOn: addOn ? createAddOnContent(addOn) : undefined,
    image: createImage({image, imageAltText}, options),
    link: createLinkContent({destination: linkDestination, label: linkLabel}),
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
  };
}
