import type {ActivityMetaDataByMboxName} from '@smart/website-personalization';

export interface FetchPersonalizationActivityMetaDataOptions {
  readonly ecid: string;
  readonly mboxNames: string[];
  readonly signal: AbortSignal;
}

export async function fetchPersonalizationActivityMetaData(
  options: FetchPersonalizationActivityMetaDataOptions,
): Promise<ActivityMetaDataByMboxName> {
  const {ecid, mboxNames, signal} = options;
  const pathname = `${process.env.PRIVATE_BASE_URL}/personalization-activity-meta-data`;

  const searchParams = new URLSearchParams([
    [`ecid`, ecid],
    ...mboxNames.map((mboxName) => [`mboxName`, mboxName]),
  ]);

  const url = `${pathname}?${searchParams.toString()}`;
  const response = await fetch(url, {signal});

  return response.ok
    ? (response.json() as Promise<ActivityMetaDataByMboxName>)
    : {};
}
