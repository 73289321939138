import type {
  CreateMediaUrlOptions,
  LegalDisclaimerContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  createId,
  createLegalDisclaimerContents,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface VideoPlayerContent {
  readonly type: 'video-player';
  readonly id: string;
  readonly anchorId: string;
  readonly modeSelector?: string;
  readonly headline: string | undefined;
  readonly posterImageUrl: string;
  readonly videoUrl: string;
  readonly autoPlay: boolean | undefined;
  readonly autoLoop: boolean | undefined;
  readonly altText: string;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

const VideoPlayerContentFragment = z.object({
  modeSelector: z.string().nullish(),
  headline: z.string().nullish(),
  posterImage: z.object({_path: z.string()}),
  video: z.object({_path: z.string()}),
  autoPlay: z.boolean().nullish(),
  autoLoop: z.boolean().nullish(),
  altText: z.string(),
  legalDisclaimer: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
});

export function createVideoPlayerContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'VideoPlayerModel'}
  >,
  options: CreateMediaUrlOptions,
): VideoPlayerContent {
  const {
    modeSelector,
    headline,
    posterImage,
    video,
    autoPlay,
    autoLoop,
    altText,
    legalDisclaimer,
    mboxName,
  } = sanitizeGraphqlData(VideoPlayerContentFragment, contentFragment);

  return {
    type: `video-player`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    modeSelector,
    headline,
    posterImageUrl: createMediaUrl(`image`, posterImage._path, options),
    videoUrl: createMediaUrl(`content`, video._path, options),
    autoPlay,
    autoLoop,
    altText,
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
  };
}
