import type {TwitterCardContent} from '@smart/website-page-model';
import React from 'react';
import {Helmet} from 'react-helmet-async';

export interface TwitterCardMetaTagsProps {
  readonly twitterCard: TwitterCardContent;
}

export function TwitterCardMetaTags({twitterCard}: TwitterCardMetaTagsProps) {
  const {site, title, description, image} = twitterCard;
  const s7Url = `${image.imageUrl}:16-9?wid=1200&hei=675&fit=crop,1`;

  return (
    <Helmet>
      <meta name="twitter:dnt" content="on" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={site} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={s7Url} />
      <meta name="twitter:image:alt" content={image.imageAltText} />
    </Helmet>
  );
}
