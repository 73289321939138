import type {Action} from 'history';
import {useEffect, useState} from 'react';
import {useFeatureServices} from '../../../hooks/use-feature-services';

export interface HistoryPath {
  action: Action | undefined;
  currentLocationSearch: string;
  currentPath: string;
  previousLocationSearch: string;
  previousPath: string;
}
export const useHistoryPath = () => {
  const {historyService} = useFeatureServices();
  const [historyState, setHistoryState] = useState<HistoryPath>({
    action: undefined,
    currentPath: historyService.rootHistory.location.pathname,
    currentLocationSearch: historyService.rootHistory.location.search,
    previousLocationSearch: ``,
    previousPath: ``,
  });

  useEffect(() => {
    const unlisten = historyService.rootHistory.listen(({location, action}) => {
      setHistoryState((prevState) => ({
        action,
        currentPath: location.pathname,
        currentLocationSearch: location.search,
        previousLocationSearch: prevState.currentLocationSearch,
        previousPath: prevState.currentPath,
      }));
    });

    return () => {
      unlisten();
    };
  }, [historyService]);

  return historyState;
};
