import {ImageStageSmall} from '@smart/editorial-components';
import type {ImageStageSmallContent} from '@smart/website-aem-utils';
import {createLegalDisclaimerProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';

export interface ImageStageSmallWrapperProps {
  readonly content: ImageStageSmallContent;
}

export const ImageStageSmallWrapper = React.memo(
  React.forwardRef<HTMLElement, ImageStageSmallWrapperProps>(
    ({content}, ref) => {
      const {historyService} = useFeatureServices();
      const {headline, image, legalDisclaimers} = content;

      return (
        <ImageStageSmall
          headline={headline}
          image={image}
          legalDisclaimers={createLegalDisclaimerProps(
            legalDisclaimers,
            historyService,
          )}
          ref={ref}
        />
      );
    },
  ),
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

ImageStageSmallWrapper.displayName = `ImageStageSmallWrapper`;
