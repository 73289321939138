import type {LocaleListenerV3} from '@smart/locale-service';
import type {PageContextListenerV1} from '@smart/page-context-service';
import * as React from 'react';

export interface IntegratorContext {
  readonly updateLocale: LocaleListenerV3;
  readonly updatePageContext: PageContextListenerV1;
}

export const IntegratorContext = React.createContext<IntegratorContext>({
  updateLocale: () => undefined,
  updatePageContext: () => undefined,
});
