import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LinkContent,
} from '@smart/aem-utils';
import {
  LinkDestination,
  createLinkContent,
  createMediaUrl,
} from '@smart/aem-utils';
import {z} from 'zod';
export interface BadgesContent {
  layout?: 'row' | 'list';
  firstBadgeItem?: BadgeItemType;
  secondBadgeItem?: BadgeItemType;
}

export type BadgeItemType = {
  link?: LinkContent;
  imageAltText?: string;
  image?: string;
};

export const BadgesContentFragment = z.object({
  layout: z.string().nullish(),
  firstBadgeLink: LinkDestination.nullish(),
  firstBadgeImageAltText: z.string().nullish(),
  firstBadgeImage: z.object({_path: z.string()}).nullish(),
  secondBadgeLink: LinkDestination.nullish(),
  secondBadgeImageAltText: z.string().nullish(),
  secondBadgeImage: z.object({_path: z.string()}).nullish(),
});

export function createBadges(
  badges?: DeepExcludeNull<z.TypeOf<typeof BadgesContentFragment>>,
  options?: CreateMediaUrlOptions,
): BadgesContent | undefined {
  if (!badges || !options) {
    return undefined;
  }

  const {
    layout,
    firstBadgeImage,
    firstBadgeImageAltText,
    firstBadgeLink,
    secondBadgeImage,
    secondBadgeImageAltText,
    secondBadgeLink,
  } = badges;

  return {
    layout: layout as BadgesContent['layout'],
    firstBadgeItem: {
      imageAltText: firstBadgeImageAltText,
      image: firstBadgeImage
        ? createMediaUrl(`image`, firstBadgeImage._path, options)
        : ``,
      link: firstBadgeLink
        ? createLinkContent({
            destination: firstBadgeLink,
            label: ``,
          })
        : undefined,
    },
    secondBadgeItem: {
      imageAltText: secondBadgeImageAltText,
      image: secondBadgeImage
        ? createMediaUrl(`image`, secondBadgeImage._path, options)
        : ``,

      link: secondBadgeLink
        ? createLinkContent({
            destination: secondBadgeLink,
            label: ``,
          })
        : undefined,
    },
  };
}
