import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import type {Personalization} from '../utils/client/fetch-personalization';

export interface UsePersonalizedPageModelOptions {
  readonly pageModel: PageModel;
  readonly personalization: Personalization | undefined;
}

export function usePersonalizedPageModel({
  pageModel,
  personalization,
}: UsePersonalizedPageModelOptions): PageModel {
  return React.useMemo(
    () => ({
      ...pageModel,
      contents: pageModel.contents.map(
        (content) =>
          (content.mboxName &&
            personalization?.activityData[content.mboxName]?.content) ||
          content,
      ),
    }),
    [pageModel, personalization],
  );
}
