import type {ActivityTrackingData} from '@smart/website-personalization';
import type {ActivityDataByMboxName} from '../../hooks/use-personalization';

export interface FetchPersonalizationActivityMetaDataOptions {
  readonly ecid: string;
  readonly activityData: ActivityDataByMboxName;
  readonly signal: AbortSignal;
}

export async function fetchPersonalizationTracking(
  options: FetchPersonalizationActivityMetaDataOptions,
): Promise<void> {
  const {ecid, activityData, signal} = options;
  const pathname = `${process.env.PRIVATE_BASE_URL}/personalization-tracking`;
  const url = `${pathname}?ecid=${encodeURIComponent(ecid)}`;

  const requestBody: ActivityTrackingData[] = Object.values(activityData).map(
    ({trackingData}) => trackingData,
  );

  if (requestBody.length === 0) {
    return;
  }

  const response = await fetch(url, {
    method: `POST`,
    body: JSON.stringify(requestBody),
    signal,
  });

  // Without parsing the response Chrome DevTools won't show the response body
  // in the network tab, which we do want to support for possible debugging
  // reasons.
  await response.json();
}
