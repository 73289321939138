import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimer,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface TeaserGroupContent {
  readonly type: 'teaser-group';
  readonly id: string;
  readonly anchorId: string;
  readonly componentHeadline: string | undefined;
  readonly items: TeaserGroupItemContent[];
  readonly highlight: boolean | undefined;
  readonly indicatorBar: boolean | undefined;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

export interface TeaserGroupItemContent {
  readonly headline: string;
  readonly teaserText: string | undefined;
  readonly teaserImage: Image;
  readonly secondaryImage: Image | undefined;
  readonly link: LinkContent | undefined;
}

const TeaserGroupItemContentFragment = z.object({
  headline: z.string(),
  teaserText: z.string().nullish(),
  teaserImage: z.object({_path: z.string()}),
  teaserImageAltText: z.string(),
  secondaryImage: z.object({_path: z.string()}).nullish(),
  secondaryImageAltText: z.string().nullish(),
  linkDestination: LinkDestination.nullish(),
  linkLabel: z.string().nullish(),
});

const TeaserGroupContentFragment = z.object({
  componentHeadline: z.string().nullish(),
  items: z.array(TeaserGroupItemContentFragment),
  highlight: z.boolean().nullish(),
  indicatorBar: z.boolean().nullish(),
  legalDisclaimers: z.array(LegalDisclaimer),
  mboxName: z.string().nullish(),
});

export function createTeaserGroupContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'TeaserGroupModel'}
  >,
  options: CreateMediaUrlOptions,
): TeaserGroupContent {
  const {
    componentHeadline,
    items,
    highlight,
    indicatorBar,
    legalDisclaimers,
    mboxName,
  } = sanitizeGraphqlData(TeaserGroupContentFragment, contentFragment);

  return {
    type: `teaser-group`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    componentHeadline,
    items: items.map(
      ({
        headline,
        teaserText,
        teaserImage,
        teaserImageAltText,
        secondaryImage,
        secondaryImageAltText,
        linkDestination,
        linkLabel,
      }): TeaserGroupItemContent => ({
        headline,
        teaserText,
        teaserImage: createImage(
          {image: teaserImage, imageAltText: teaserImageAltText},
          options,
        ),
        secondaryImage:
          secondaryImage && secondaryImageAltText
            ? createImage(
                {image: secondaryImage, imageAltText: secondaryImageAltText},
                options,
              )
            : undefined,
        link: createLinkContent({
          destination: linkDestination,
          label: linkLabel,
        }),
      }),
    ),
    highlight,
    indicatorBar,
    legalDisclaimers: legalDisclaimers
      ? createLegalDisclaimerContents(legalDisclaimers, options)
      : [],
    mboxName,
  };
}
