import * as React from 'react';
import {useAsyncReactFeatureApp} from '../hooks/use-async-react-feature-app';

export interface FeatureAppLoaderProps {
  readonly featureAppId: string;
  readonly baseUrl: string;
  readonly src: string;
}

export function FeatureAppLoader(
  props: FeatureAppLoaderProps,
): JSX.Element | null {
  const featureAppResult = useAsyncReactFeatureApp(props);

  return <>{featureAppResult.featureApp?.render()}</>;
}
