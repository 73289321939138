import type {EnvNameV3} from '@smart/endpoint-directory';
import {type LocaleV3, getCountryCode} from '@smart/locale-service';
import * as React from 'react';
import {useChatbotCustomerData} from '../hooks/use-chatbot-customer-data';
import {useFeatureServices} from '../hooks/use-feature-services';
import {getChatLocale} from '../utils/client/get-chat-locale';
import type {InitChatAction} from '../utils/client/get-chatbot';
import {getChatbot} from '../utils/client/get-chatbot';
import {createChatbotUrl} from '../utils/universal/create-chatbot-url';
import styles from './chatbot.module.scss';
import './chatbot.scss';

export interface ChatbotProps {
  readonly locale: LocaleV3;
}

export function Chatbot({locale}: ChatbotProps): JSX.Element | null {
  const isInitializedRef = React.useRef(false);
  const [isButtonVisible, setIsButtonVisible] = React.useState(true);
  const {adbContextService, endpointDirectory, logger} = useFeatureServices();
  const [currentLocale, setCurrentLocale] = React.useState<LocaleV3>(locale);

  useChatbotCustomerData();

  React.useEffect(() => {
    if (adbContextService?.adbContext) {
      setIsButtonVisible(false);
    }
  }, []);

  const openChat = React.useCallback(
    (chatbotLocale: LocaleV3) => {
      if (adbContextService?.adbContext) {
        return;
      }

      isInitializedRef.current = true;

      const {envName} = endpointDirectory;
      const market = getCountryCode(chatbotLocale.marketId).toLowerCase();

      loadStylesheet(envName);

      let chatbot = getChatbot();

      logger.debug(`Loading chatbot script.`);

      loadScript(envName, () => {
        logger.debug(`Loaded chatbot script.`);

        // The loaded script overwrites the seamly object so we need to retrieve it again.
        chatbot = getChatbot();

        const action: InitChatAction = {
          action: `init`,
          args: {
            layoutMode: `window`,
            namespace: `smart`,
            showDisclaimer: false,
            visibilty: `open`,
            context: {
              market,
              locale: getChatLocale(locale.languageTag),
            },
          },
        };

        chatbot.push(action);
        chatbot.push({
          action: `setVariables`,
          args: {
            market,
          },
        });
        chatbot.push({action: `setVisibility`, args: `open`});

        chatbot.push({
          action: `on`,
          args: [`ui.beforeStart`, () => setIsButtonVisible(false)],
        });
      });
    },
    [locale],
  );

  // We need to check if the locale has changed because the chatbot script
  // If so then we need to call openChat again to update the locale.
  React.useEffect(() => {
    if (
      currentLocale.languageTag !== locale.languageTag &&
      isInitializedRef.current === true
    ) {
      openChat(locale);
    }
    setCurrentLocale(locale);
  }, [locale]);

  return isButtonVisible ? (
    <button onClick={() => openChat(locale)} className={styles.button} />
  ) : null;
}

function loadStylesheet(envName: EnvNameV3): void {
  const linkElement = document.createElement(`link`);

  linkElement.href = createChatbotUrl({type: `css`, envName});
  linkElement.rel = `stylesheet`;

  document.head.appendChild(linkElement);
}

function loadScript(envName: EnvNameV3, onLoad: () => void): void {
  const scriptElement = document.createElement(`script`);
  scriptElement.src = createChatbotUrl({type: `js`, envName});
  scriptElement.async = true;
  scriptElement.onload = onLoad;

  document.head.appendChild(scriptElement);
}
