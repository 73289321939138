import {waitForInteractive} from './utils/client/wait-for-interactive';
import type {UniversalState} from './utils/server/create-universal-state';

export async function getUniversalState(): Promise<UniversalState> {
  await waitForInteractive();

  const textContent = document
    .querySelector(`#universalState`)!
    .textContent!.trim();

  return JSON.parse(decodeURIComponent(textContent));
}

export function getMainElement(): Element {
  return document.querySelector(`#react-root`)!;
}
