import {Text} from '@smart/web-components';
import type {InvalidContentFragmentContent} from '@smart/website-aem-utils';
import * as React from 'react';
import styles from './invalid-content-fragment-warning.module.scss';

export interface InvalidContentFragmentWarningProps {
  readonly content: InvalidContentFragmentContent;
}

export const InvalidContentFragmentWarning = React.memo(
  ({content}: InvalidContentFragmentWarningProps) => {
    return (
      <Text tag="p" className={styles.invalidContentFragmentWarning}>
        ⚠️ Invalid content fragment found at:
        <br />
        <strong>{content.contentFragmentPath}</strong>
      </Text>
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

InvalidContentFragmentWarning.displayName = `InvalidContentFragmentWarning`;
