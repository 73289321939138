export function createRenderKeyGenerator(): (key: string) => string {
  const countByKey = new Map<string, number>();

  return (key: string) => {
    const count = countByKey.get(key) ?? 0;

    countByKey.set(key, count + 1);

    return `${key}-${count}`;
  };
}
