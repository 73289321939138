import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  ImageWithTransparencySupportContent,
  LegalDisclaimers,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface FlexibleGalleryContent {
  readonly type: 'flexible-gallery';
  readonly id: string;
  readonly anchorId: string;
  readonly headline: string | undefined;
  readonly items: readonly FlexibleGalleryItemContent[];
  readonly link: LinkContent | undefined;
  readonly highlight: boolean | undefined;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

export interface FlexibleGalleryItemContent {
  readonly headline: string | undefined;
  readonly subheadline: string | undefined;
  readonly image: Image;
  readonly captionHeadline: string | undefined;
  readonly captionCopyText: string | undefined;
}

const FlexibleGalleryContentFragment = z.object({
  headline: z.string().nullish(),
  items: z.array(
    z
      .object({
        headline: z.string().nullish(),
        subheadline: z.string().nullish(),
        captionHeadline: z.string().nullish(),
        captionCopyText: z.string().nullish(),
      })
      .and(ImageWithTransparencySupportContent),
  ),
  linkDestination: LinkDestination.nullish(),
  linkLabel: z.string().nullish(),
  highlight: z.boolean().nullish(),
  legalDisclaimer: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
});

export function createFlexibleGalleryContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'FlexibleGalleryModel'}
  >,
  options: CreateMediaUrlOptions,
): FlexibleGalleryContent {
  const {
    headline,
    items,
    linkDestination,
    linkLabel,
    highlight,
    legalDisclaimer,
    mboxName,
  } = sanitizeGraphqlData(FlexibleGalleryContentFragment, contentFragment);

  return {
    type: `flexible-gallery`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    headline,
    items: items.map(
      ({
        image,
        imageAltText,
        imageTransparencySupport,
        headline: itemHeadline,
        subheadline,
        captionHeadline,
        captionCopyText,
      }): FlexibleGalleryItemContent => ({
        image: createImage(
          {image, imageAltText, imageTransparencySupport},
          options,
        ),
        headline: itemHeadline,
        subheadline,
        captionHeadline,
        captionCopyText,
      }),
    ),
    link: createLinkContent({destination: linkDestination, label: linkLabel}),
    highlight,
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
  };
}
