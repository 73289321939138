import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
} from '@smart/aem-utils';
import {
  ImageContent,
  LegalDisclaimers,
  createId,
  createImage,
  createLegalDisclaimerContents,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface ImageStageSmallContent {
  readonly type: 'image-stage-small';
  readonly id: string;
  readonly anchorId: string;
  readonly headline: string | undefined;
  readonly image: Image;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

const ImageStageSmallContentFragment = z
  .object({
    headline: z.string().nullish(),
    legalDisclaimers: LegalDisclaimers.nullish(),
    mboxName: z.string().nullish(),
  })
  .and(ImageContent);

export function createImageStageSmallContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'ImageStageSmallModel'}
  >,
  options: CreateMediaUrlOptions,
): ImageStageSmallContent {
  const sanitizedContentFragment = sanitizeGraphqlData(
    ImageStageSmallContentFragment,
    contentFragment,
  );

  const {headline, image, imageAltText, legalDisclaimers, mboxName} =
    sanitizedContentFragment;

  return {
    type: `image-stage-small`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    headline,
    image: createImage({image, imageAltText}, options),
    legalDisclaimers: legalDisclaimers
      ? createLegalDisclaimerContents(legalDisclaimers, options)
      : [],
    mboxName,
  };
}
