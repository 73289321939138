import type {EnvNameV3} from '@smart/endpoint-directory';

export interface DataLayerPageViewEvent {
  readonly event: 'pageview';

  readonly page: {
    readonly name: string;
    readonly type: string;
    readonly referringPage: string;
  };

  readonly user: TrackingUserData;

  readonly application: {
    readonly id: 'aem';
    readonly version: string;
    readonly env: EnvNameV3;
    readonly language: string;
    readonly market: string;
    readonly currency: string;
  };
}

export interface DataLayerClickEvent {
  readonly event: 'click';

  readonly eventInfo: {
    type: string;
    region: string;
    name: string;
    url: string;
    destinationUrl: string;
  };

  readonly user: TrackingUserData;

  readonly application: {
    readonly id: 'aem';
    readonly version: string;
    readonly env: EnvNameV3;
    readonly language: string;
    readonly market: string;
    readonly currency: string;
  };
}

export type DataLayerEvent = DataLayerPageViewEvent | DataLayerClickEvent;

export type TrackingUserData =
  | {
      readonly loginStatus: 'logged_in';
      readonly customerId: string;
      readonly type: 'b2c' | 'b2b';
    }
  | {readonly loginStatus: 'logged_out'};

export function pushToDataLayer(event: DataLayerEvent): void {
  ((window as any).adobeDataLayer = (window as any).adobeDataLayer || []).push(
    event,
  );
}
