import {createId, sanitizeGraphqlData} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface RichtextContent {
  readonly type: `richtext`;
  readonly id: string;
  readonly anchorId: string;
  readonly richtext: string;
  readonly mboxName: string | undefined;
  readonly enableParsingRawHtml?: boolean;
  readonly asAccordion?: boolean;
}

export const RichtextContentFragment = z.object({
  richtext: z.object({markdown: z.string()}),
  mboxName: z.string().nullish(),
  enableParsingRawHtml: z.boolean().nullish(),
  asAccordion: z.boolean().nullish(),
});

export function createRichtextContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'RichtextModel'}>,
): RichtextContent {
  const {richtext, mboxName, enableParsingRawHtml, asAccordion} =
    sanitizeGraphqlData(RichtextContentFragment, contentFragment);

  return {
    type: `richtext`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    richtext: richtext.markdown,
    mboxName,
    enableParsingRawHtml,
    asAccordion,
  };
}
