import type {LoginStateV3} from '@smart/login-service';
import type {TrackingUserData} from './push-to-data-layer';

export function createTrackingUserData(
  loginState: LoginStateV3 | undefined,
): TrackingUserData {
  if (loginState?.status !== `logged-in`) {
    return {loginStatus: `logged_out`};
  }

  const {uid, customerType} = loginState;

  return {
    loginStatus: `logged_in`,
    customerId: uid,
    type: customerType === `private` ? `b2c` : `b2b`,
  };
}
