import type {
  CreateMediaUrlOptions,
  LegalDisclaimerContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimerWithReference,
  createId,
  createLegalDisclaimerContents,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface LegalDisclaimersContent {
  readonly type: `legal-disclaimers`;
  readonly id: string;
  readonly anchorId: string;
  readonly mode?: 'light' | 'dark' | 'brabus';
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

const LegalDisclaimersContentFragment = z.object({
  mode: z.string().nullish(),
  legalDisclaimers: z.array(LegalDisclaimerWithReference),
  mboxName: z.string().nullish(),
});

export function createLegalDisclaimersContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'LegalDisclaimersModel'}
  >,
  options: CreateMediaUrlOptions,
): LegalDisclaimersContent {
  const {mode, legalDisclaimers, mboxName} = sanitizeGraphqlData(
    LegalDisclaimersContentFragment,
    contentFragment,
  );

  return {
    type: `legal-disclaimers`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    mode: mode as LegalDisclaimersContent['mode'],
    legalDisclaimers: createLegalDisclaimerContents(legalDisclaimers, options),
    mboxName,
  };
}
