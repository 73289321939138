import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LinkContent,
} from '@smart/aem-utils';
import {
  LinkDestination,
  createId,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface TeaserRowWithTextContent {
  readonly type: 'teaser-row-with-text';
  readonly id: string;
  readonly anchorId: string;
  readonly componentHeadline: string | undefined;
  readonly indicatorBar: boolean | undefined;
  readonly items: TeaserRowWithTextItemContent[];
  readonly mboxName: string | undefined;
}

export interface TeaserRowWithTextItemContent {
  readonly headline: string;
  readonly imageUrl: string;
  readonly imageAltText: string;
  readonly copyText?: string;
  readonly link: LinkContent;
}

const TeaserRowWithTextItemContentFragment = z.object({
  headline: z.string(),
  image: z.object({_path: z.string()}),
  imageAltText: z.string(),
  linkDestination: LinkDestination,
  linkLabel: z.string(),
  copyText: z.object({plaintext: z.string().nullish()}).nullish(),
});

const TeaserRowWithTextContentFragment = z.object({
  componentHeadline: z.string().nullish(),
  indicatorBar: z.boolean().nullish(),
  items: z.array(TeaserRowWithTextItemContentFragment),
  mboxName: z.string().nullish(),
});

export function createTeaserRowWithTextContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'TeaserRowWithTextModel'}
  >,
  options: CreateMediaUrlOptions,
): TeaserRowWithTextContent {
  const {componentHeadline, indicatorBar, items, mboxName} =
    sanitizeGraphqlData(TeaserRowWithTextContentFragment, contentFragment);

  return {
    type: `teaser-row-with-text`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    componentHeadline,
    items: createTeaserRowWithTextItemContents(items, options),
    indicatorBar,
    mboxName,
  };
}

function createTeaserRowWithTextItemContents(
  items: readonly DeepExcludeNull<
    z.TypeOf<typeof TeaserRowWithTextItemContentFragment>
  >[],
  options: CreateMediaUrlOptions,
): TeaserRowWithTextItemContent[] {
  return items.map(
    ({
      headline,
      image,
      imageAltText,
      copyText,
      linkDestination,
      linkLabel,
    }) => ({
      headline,
      imageUrl: createMediaUrl(`image`, image._path, options),
      imageAltText,
      copyText: copyText?.plaintext,
      link: createLinkContent({
        destination: linkDestination,
        label: linkLabel,
      }),
    }),
  );
}
