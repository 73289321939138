import type {AccordionType, modeType} from '@smart/editorial-components';
import {Accordion} from '@smart/editorial-components';
import type {AccordionContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {getItemContent} from '../utils/client/accordion-get-content';
import {PageModelContext} from './page';
export interface AccordionWrapperProps {
  readonly content: AccordionContent;
  readonly cdnOrigin: string;
}

export const AccordionWrapper = React.memo<AccordionWrapperProps>(
  ({content, cdnOrigin}) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const link = useLink(content.link, {
      websiteTrackingData: {
        type: `navigation`,
        region: `accordion`,
        name: content.link?.label || ``,
      },
      languageTag,
      marketId,
    });
    const props: AccordionType = {
      removeTopSpacing: content.removeTopSpacing,
      mode: content.mode as modeType,
      size: content.size,
      subline: content.subline,
      headline: content.headline,
      variant: content.variant,
      items: content.items.map((c) => ({
        ...c,
        content: getItemContent(c.components, cdnOrigin),
      })),
      link,
    };

    return <Accordion {...props} />;
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.cdnOrigin === nextProps.cdnOrigin,
);

AccordionWrapper.displayName = `AccordionWrapper`;
