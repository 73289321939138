import type {ErrorInfo} from 'react';
import {Component} from 'react';
import {renderToString} from 'react-dom/server';

interface Props {
  children: JSX.Element | JSX.Element;
  fallbackHandler: () => JSX.Element | null;
  reportErrorHandler: (error: Error, errorInfo?: ErrorInfo) => void;
}

interface State {
  hasError: boolean;
}

type RType = JSX.Element | null;

export class ErrorBoundary extends Component<Props, State> {
  override state: State = {
    hasError: false,
  };

  static defaultProps = {
    fallbackHandler: () => null,
    reportErrorHandler: () => undefined,
  };

  static getDerivedStateFromError(): State {
    return {hasError: true};
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const {reportErrorHandler} = this.props;

    reportErrorHandler(error, errorInfo);
    this.setState({hasError: true});
  }

  /**
   * Simulates rendering on the server, but it also has the side effect
   * of throwing errors if there's an issue with the component rendering.
   * This is how the server can detect an error.
   */
  renderOnServer(): RType {
    const {fallbackHandler, children, reportErrorHandler} = this.props;
    try {
      renderToString(children);
      /*
       Return children as is. Do not have to return output of renderToString.
       renderToString is just to find if there is any error in children we are rendering.
      */
      return children;
    } catch (e) {
      reportErrorHandler(e as Error);
      return fallbackHandler();
    }
  }

  override render(): RType {
    const {fallbackHandler, children} = this.props;
    const {hasError} = this.state;

    if (typeof window === `undefined`) {
      return this.renderOnServer();
    }

    if (hasError) {
      return fallbackHandler();
    }

    return children;
  }
}
