import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  ImageWithTransparencySupportContent,
  LegalDisclaimers,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface FlexibleTextAndImageContent {
  readonly type: 'flexible-text-and-image';
  readonly id: string;
  readonly anchorId: string;
  readonly componentHeadline: string | undefined;
  readonly headline: string | undefined;
  readonly priceTag: string | undefined;
  readonly copyText: string | undefined;
  readonly image: Image;
  readonly primaryLink: LinkContent | undefined;
  readonly secondaryLink: LinkContent | undefined;
  readonly imageLeading: boolean | undefined;
  readonly highlight: boolean | undefined;
  readonly indicatorBar: boolean | undefined;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

const FlexibleTextAndImageContentFragment = z
  .object({
    componentHeadline: z.string().nullish(),
    headline: z.string().nullish(),
    priceTag: z.object({priceTag: z.string().nullish()}).nullish(),
    multilineCopyText: z.object({markdown: z.string()}).nullish(),
    primaryLinkDestination: LinkDestination.nullish(),
    primaryLinkLabel: z.string().nullish(),
    secondaryLinkDestination: LinkDestination.nullish(),
    secondaryLinkLabel: z.string().nullish(),
    imageLeading: z.boolean().nullish(),
    highlight: z.boolean().nullish(),
    indicatorBar: z.boolean().nullish(),
    legalDisclaimer: LegalDisclaimers.nullish(),
    mboxName: z.string().nullish(),
  })
  .and(ImageWithTransparencySupportContent);

export function createFlexibleTextAndImageContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'FlexibleText_ImageModel'}
  >,
  options: CreateMediaUrlOptions,
): FlexibleTextAndImageContent {
  const {
    componentHeadline,
    headline,
    priceTag,
    multilineCopyText,
    image,
    imageAltText,
    imageTransparencySupport,
    primaryLinkDestination,
    primaryLinkLabel,
    secondaryLinkDestination,
    secondaryLinkLabel,
    imageLeading,
    highlight,
    indicatorBar,
    legalDisclaimer,
    mboxName,
  } = sanitizeGraphqlData(FlexibleTextAndImageContentFragment, contentFragment);

  return {
    type: `flexible-text-and-image`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    componentHeadline,
    headline,
    priceTag: priceTag?.priceTag,
    copyText: multilineCopyText?.markdown,
    image: createImage(
      {image, imageAltText, imageTransparencySupport},
      options,
    ),
    primaryLink: createLinkContent({
      destination: primaryLinkDestination,
      label: primaryLinkLabel,
    }),
    secondaryLink: createLinkContent({
      destination: secondaryLinkDestination,
      label: secondaryLinkLabel,
    }),
    imageLeading,
    highlight,
    indicatorBar,
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
  };
}
