import type {ToastProps} from '@smart/web-components';
import type {ToastContent} from '@smart/website-extra-language-contents';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {AnimatedToast} from './animated-toast';

export interface LoginErrorToastProps {
  readonly content: ToastContent;
}

export const LoginErrorToast = React.memo(
  ({content}: LoginErrorToastProps) => {
    const {loginService, pubSubService} = useFeatureServices();
    const [hasUnseenLoginError, setHasUnseenLoginError] = React.useState(false);

    React.useEffect(() => {
      if (loginService?.loginState.status !== `login-error`) {
        return resetLoginErrorWasShown();
      }

      if (!getLoginErrorWasShown()) {
        setHasUnseenLoginError(true);
        setLoginErrorWasShown();
      }

      return pubSubService.subscribe(`before-login`, resetLoginErrorWasShown);
    }, []);

    const handleHideAnimationEnd = React.useCallback(() => {
      setHasUnseenLoginError(false);
    }, []);

    const {variant, headline, text} = content;

    return (
      <AnimatedToast
        variant={variant as ToastProps['variant']}
        content={{headline, text}}
        show={hasUnseenLoginError}
        onHideAnimationEnd={handleHideAnimationEnd}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

const storageKey = `smart-login-error-shown`;

const storage =
  typeof window === `undefined` ? undefined : window.sessionStorage;

function setLoginErrorWasShown(): void {
  try {
    storage?.setItem(storageKey, `true`);
  } catch {}
}

function resetLoginErrorWasShown(): void {
  try {
    storage?.removeItem(storageKey);
  } catch {}
}

function getLoginErrorWasShown(): boolean {
  try {
    return storage?.getItem(storageKey) === `true`;
  } catch {
    return false;
  }
}
