import type {CreateMediaUrlOptions, LinkContent} from '@smart/aem-utils';
import {
  LinkDestination,
  createId,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
import {
  type ExtendedFlexibleGalleryContent,
  ExtendedFlexibleGalleryContentFragment,
  createExtendedFlexibleGalleryContent,
} from './create-extended-flexible-gallery-content.js';
import type {MediaStageContent} from './create-media-stage-content.js';
import {
  MediaStageContentFragment,
  createMediaStageContent,
} from './create-media-stage-content.js';
import {
  type RichtextContent,
  RichtextContentFragment,
  createRichtextContent,
} from './create-richtext-content.js';
import {
  type StatementContent,
  StatementContentFragment,
  createStatementContent,
} from './create-statement-content.js';
import {
  type TeaserRowV2Content,
  TeaserRowV2ContentFragment,
  createTeaserRowV2Content,
} from './create-teaser-row-v2-content.js';
import {
  type TextWithMediaContent,
  TextWithMediaContentFragment,
  createTextWithMediaContent,
} from './create-text-with-media-content.js';

export interface TextParagraphContent {
  readonly type: `text-paragraph`;
  readonly id: string;
  readonly anchorId: string;
  readonly layoutAlign?:
    | 'left'
    | 'center'
    | 'offset'
    | 'right50-50'
    | 'right2-3';
  readonly title?: string;
  readonly componentHeadline?: string;
  readonly logo?: string;
  readonly headlineTagType?: 'h1' | 'h2';
  readonly headlineAlign?: 'left' | 'center';
  readonly size?: 'Headline/200' | 'Headline/300';
  readonly richTextColumn1?: string;
  readonly richTextColumn2?: string;
  readonly useLimeTheme?: boolean;
  readonly asAccordion?: boolean;
  readonly removeTopSpacing?: boolean;
  readonly link?: LinkContent;
  readonly secondaryLink?: LinkContent;
  readonly mboxName: string | undefined;
  readonly renderSubscribePopupButton?: boolean;
  readonly subscribePopupButtonText?: string;
  readonly modeSelector?: modeType;
  readonly components: (
    | {
        type: 'extendedFlexibleGallery';
        content: ExtendedFlexibleGalleryContent;
      }
    | {type: 'teaserRowV2'; content: TeaserRowV2Content}
    | {type: 'statement'; content: StatementContent}
    | {type: 'textWithMedia'; content: TextWithMediaContent}
    | {type: 'mediaStage'; content: MediaStageContent}
    | {type: 'richtext'; content: RichtextContent}
  )[];
}

export const TextParagraphContentFragment = z.object({
  layoutAlign: z.string().nullish(),
  title: z.string().nullish(),
  componentHeadline: z.string().nullish(),
  headlineTagType: z.string().nullish(),
  size: z.string().nullish(),
  asAccordion: z.boolean().nullish(),
  components: z.array(
    z.discriminatedUnion(`__typename`, [
      ExtendedFlexibleGalleryContentFragment.extend({
        __typename: z.literal(`ExtendedFlexibleGalleryModel`),
        _path: z.string(),
      }),
      TeaserRowV2ContentFragment.extend({
        __typename: z.literal(`TeaserRowV2Model`),
        _path: z.string(),
      }),
      StatementContentFragment.extend({
        __typename: z.literal(`StatementModel`),
        _path: z.string(),
      }),
      TextWithMediaContentFragment.extend({
        __typename: z.literal(`TextWithMediaModel`),
        _path: z.string(),
      }),
      MediaStageContentFragment.extend({
        __typename: z.literal(`MediaStageModel`),
        _path: z.string(),
      }),
      RichtextContentFragment.extend({
        __typename: z.literal(`RichtextModel`),
        _path: z.string(),
      }),
    ]),
  ),
  logo: z.object({_path: z.string()}).nullish(),
  headlineAlign: z.string().nullish(),
  richTextColumn1: z.object({markdown: z.string().nullish()}).nullish(),
  richTextColumn2: z.object({markdown: z.string().nullish()}).nullish(),
  useLimeTheme: z.boolean().nullish(),
  removeTopSpacing: z.boolean().nullish(),
  linkLabel: z.string().nullish(),
  linkDestination: LinkDestination.nullish(),
  secondaryLinkLabel: z.string().nullish(),
  secondaryLinkDestination: LinkDestination.nullish(),
  mboxName: z.string().nullish(),
  renderSubscribePopupButton: z.boolean().nullish(),
  subscribePopupButtonText: z.string().nullish(),
  modeSelector: z.string().nullish(),
});

function getComponents(
  fragment: z.TypeOf<typeof TextParagraphContentFragment>['components'][0],
  options: CreateMediaUrlOptions,
) {
  if (fragment.__typename === `ExtendedFlexibleGalleryModel`) {
    return {
      type: `extendedFlexibleGallery` as const,
      content: createExtendedFlexibleGalleryContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'ExtendedFlexibleGalleryModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `TeaserRowV2Model`) {
    return {
      type: `teaserRowV2` as const,
      content: createTeaserRowV2Content(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'TeaserRowV2Model'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `StatementModel`) {
    return {
      type: `statement` as const,
      content: createStatementContent(
        fragment as Extract<AemContentFragment, {__typename: 'StatementModel'}>,
      ),
    };
  }
  if (fragment.__typename === `TextWithMediaModel`) {
    return {
      type: `textWithMedia` as const,
      content: createTextWithMediaContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'TextWithMediaModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `MediaStageModel`) {
    return {
      type: `mediaStage` as const,
      content: createMediaStageContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'MediaStageModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `RichtextModel`) {
    return {
      type: `richtext` as const,
      content: createRichtextContent(
        fragment as Extract<AemContentFragment, {__typename: 'RichtextModel'}>,
      ),
    };
  }

  return null;
}

export function createTextParagraphContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'TextParagraphModel'}
  >,
  options: CreateMediaUrlOptions,
): TextParagraphContent {
  const {
    layoutAlign,
    title,
    componentHeadline,
    size,
    asAccordion,
    logo,
    headlineAlign,
    richTextColumn1,
    richTextColumn2,
    useLimeTheme,
    removeTopSpacing,
    headlineTagType,
    linkLabel,
    linkDestination,
    secondaryLinkLabel,
    secondaryLinkDestination,
    mboxName,
    renderSubscribePopupButton,
    subscribePopupButtonText,
    modeSelector,
    components,
  } = sanitizeGraphqlData(TextParagraphContentFragment, contentFragment);

  return {
    type: `text-paragraph`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    layoutAlign: layoutAlign as TextParagraphContent['layoutAlign'],
    title,
    componentHeadline,
    size: size as TextParagraphContent['size'],
    logo: logo?._path
      ? createMediaUrl(`image`, logo._path, options)
      : undefined,
    headlineAlign: headlineAlign as TextParagraphContent['headlineAlign'],
    headlineTagType: headlineTagType as TextParagraphContent['headlineTagType'],
    richTextColumn1: richTextColumn1?.markdown,
    richTextColumn2: richTextColumn2?.markdown,
    useLimeTheme,
    removeTopSpacing,
    link: createLinkContent({destination: linkDestination, label: linkLabel}),
    secondaryLink: createLinkContent({
      destination: secondaryLinkDestination,
      label: secondaryLinkLabel,
    }),
    mboxName,
    asAccordion,
    components:
      components && components.length > 0
        ? components
            .map((c) => getComponents(c, options))
            .filter(<T>(value: T | null | undefined): value is T => {
              return value !== null && value !== undefined;
            })
        : [],
    renderSubscribePopupButton,
    subscribePopupButtonText,
    modeSelector: modeSelector as TextParagraphContent['modeSelector'],
  };
}
