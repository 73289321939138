import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface CarSlideItemContent {
  readonly modelName: string;
  readonly modelDescription?: string;
  readonly priceTag: string;
  readonly leasingPriceTag?: string;
  readonly priceTagAnnotation?: string;
  readonly cta?: LinkContent;
  readonly renderPopupButton?: boolean;
  readonly popupButtonText?: string;
}

export interface CarSlideContent {
  readonly type: 'car-slide';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly models: CarSlideItemContent[];
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly modeSelector?: modeType;
  readonly mboxName?: string;
}

const CarSlideItemContentFragment = z.object({
  modelName: z.string(),
  modelDescription: z.string().nullish(),
  priceTag: z.string(),
  leasingPriceTag: z.string().nullish(),
  priceTagAnnotation: z.string().nullish(),
  ctaLabel: z.string().nullish(),
  ctaDestination: LinkDestination.nullish(),
  renderPopupButton: z.boolean().nullish(),
  popupButtonText: z.string().nullish(),
});

const CarSlideContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  models: z.array(CarSlideItemContentFragment),
  legalDisclaimers: LegalDisclaimers.nullish(),
  modeSelector: z.string().nullish(),
  mboxName: z.string().nullish(),
});

export function createCarSlideContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'CarSlideModel'}>,
  options: CreateMediaUrlOptions,
): CarSlideContent {
  const {removeTopSpacing, models, legalDisclaimers, mboxName, modeSelector} =
    sanitizeGraphqlData(CarSlideContentFragment, contentFragment);
  return {
    type: `car-slide`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    removeTopSpacing,
    models: createCarSlideItems(models),
    legalDisclaimers: legalDisclaimers
      ? createLegalDisclaimerContents(
          legalDisclaimers,
          options as CreateMediaUrlOptions,
        )
      : [],
    modeSelector: modeSelector as CarSlideContent['modeSelector'],
    mboxName,
  };
}

function createCarSlideItems(
  models: readonly DeepExcludeNull<
    z.TypeOf<typeof CarSlideItemContentFragment>
  >[],
): CarSlideItemContent[] {
  return models.map((model) => {
    const {
      modelName,
      modelDescription,
      priceTag,
      leasingPriceTag,
      priceTagAnnotation,
      ctaLabel,
      ctaDestination,
      renderPopupButton,
      popupButtonText,
    } = model;

    return {
      modelName,
      modelDescription,
      priceTag,
      leasingPriceTag,
      priceTagAnnotation,
      cta: createLinkContent({
        label: ctaLabel,
        destination: ctaDestination,
      }),
      renderPopupButton,
      popupButtonText,
    };
  });
}
