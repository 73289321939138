import type {SelectedVehicleServiceV1} from '@smart/selected-vehicle-service';
import {
  getPn18FromUrl,
  readSelectedVehicleFromStorage,
  removePreselectionFromSearchParams,
  writeSelectedVehicleToStorage,
} from '@smart/selected-vehicle-service';
import type {History} from 'history';

export interface ConnectSelectedVehicleServiceOptions {
  readonly selectedVehicleService: SelectedVehicleServiceV1;
  readonly history: History;
}

const defaultPn18PerLineCode: Record<string, string> = {
  BRABUS: `HX1ETD3A61EU010418`, // Brabus
  MB27: `HX1ERD1A51EU010041`, // Pro+
  MB28: `HX1ESD2A51EU010447`, // Pemium
  MB30: `HX1ES01A51EU010251`, // Launch Edition
};

export function connectSelectedVehicleService(
  options: ConnectSelectedVehicleServiceOptions,
): void {
  const {selectedVehicleService, history} = options;

  const evaluateKeepProductDetailsParams = () => {
    return [`product-details`, `customizer`, `products`, `stock-search`].some(
      (path) =>
        window.location.pathname?.split(`/`).filter(Boolean)?.includes(path),
    );
  };

  let keepProductDetailsParams = evaluateKeepProductDetailsParams();

  selectedVehicleService.subscribe((selectedVehicle) => {
    writeSelectedVehicleToStorage(selectedVehicle);
  });

  const {
    pn18: initialPn18,
    addToCart: initialAddToCart,
    lineCodeCampaignSearch: initialLineCodeCampaignSearch,
  } = getPn18FromUrl(
    new URLSearchParams(history.location.search),
    defaultPn18PerLineCode,
  );

  const removePreselectionFromUrl = (
    lineCodeCampaignSearch: string | undefined,
  ) => {
    const search = removePreselectionFromSearchParams(
      history.location.search,
      lineCodeCampaignSearch,
    );

    if (search !== history.location.search) {
      const {pathname, hash, state} = history.location;
      history.replace({pathname, hash, search}, state);
    }
  };

  if (!keepProductDetailsParams) {
    removePreselectionFromUrl(initialLineCodeCampaignSearch);
  }

  const initialSelectedVehicleFromStorage = readSelectedVehicleFromStorage();

  if (initialPn18) {
    if (initialSelectedVehicleFromStorage?.pn18 === initialPn18) {
      selectedVehicleService.setSelectedVehicle({
        ...initialSelectedVehicleFromStorage,
        addToCart:
          initialSelectedVehicleFromStorage.addToCart || initialAddToCart,
      });
    } else {
      selectedVehicleService.setSelectedVehicle({
        pn18: initialPn18,
        userSelectedColor: false,
        addToCart: initialAddToCart,
      });
    }
  } else if (initialSelectedVehicleFromStorage) {
    selectedVehicleService.setSelectedVehicle(
      initialSelectedVehicleFromStorage,
    );
  }

  history.listen(({location}) => {
    const {pn18, addToCart, lineCodeCampaignSearch} = getPn18FromUrl(
      new URLSearchParams(location.search),
      defaultPn18PerLineCode,
    );
    keepProductDetailsParams = evaluateKeepProductDetailsParams();

    if (!keepProductDetailsParams) {
      removePreselectionFromUrl(lineCodeCampaignSearch);
    }

    if (pn18) {
      const selectedVehicle = selectedVehicleService.getSelectedVehicle();

      if (pn18 !== selectedVehicle?.pn18) {
        selectedVehicleService.setSelectedVehicle({
          pn18,
          userSelectedColor: false,
          addToCart,
        });
      } else if (addToCart && !selectedVehicle.addToCart) {
        selectedVehicleService.setSelectedVehicle({
          ...selectedVehicle,
          addToCart,
        });
      }
    }
  });
}
