import {StatusCode} from '@smart/platform-utils';
import type {PageModel} from '@smart/website-page-model';
import type {Location} from 'history';
import {fetchPageModel} from '../utils/universal/fetch-page-model';
import {use} from './use';
import {useFeatureServices} from './use-feature-services';
import {usePageModelResponseCacheContext} from './use-page-model-response-cache-context';

export function usePageModel(
  initialPageModel: PageModel,
  location: Location,
): PageModel {
  const {logger} = useFeatureServices();
  const cache = usePageModelResponseCacheContext();

  if (initialPageModel.pathname === location.pathname) {
    return initialPageModel;
  }
  const pageModelResponse = use(
    fetchPageModel(location.pathname, {logger, cache}),
  );

  if (
    pageModelResponse.statusCode === StatusCode.MOVED_PERMANENTLY ||
    pageModelResponse.statusCode === StatusCode.FOUND
  ) {
    // Can not happen during SSR, see early exit in server.tsx.
    window.location.replace(pageModelResponse.location);

    throw new Promise(() => undefined);
  }

  if (!pageModelResponse.pageModel) {
    // Either recover from the error by loading a cached page from the CDN, or
    // load the browser's default 404 error page. (Can not happen during SSR,
    // see early exit in server.tsx.)
    window.location.reload();

    throw new Promise(() => undefined);
  }

  return pageModelResponse.pageModel;
}
