import type {LocaleV3} from '@smart/locale-service';
import {CHECKOUT_INTERRUPTION_PATH} from '../constants';
import {getLanguageMappedPaths} from './get-language-mapped-paths';

export const wasCheckoutJourneyInterruptedByPreviousPage = (
  startPath: string,
  endPath: string,
  locale: LocaleV3,
) => {
  if (startPath === endPath) {
    return false;
  }

  const isOrderOverviewStartPath = getLanguageMappedPaths(
    CHECKOUT_INTERRUPTION_PATH.ORDER_OVERVIEW.START,
    locale,
  ).includes(startPath);

  const isOrderOverviewEndPath = getLanguageMappedPaths(
    CHECKOUT_INTERRUPTION_PATH.ORDER_OVERVIEW.END,
    locale,
  ).includes(endPath);

  const isDeliveryMethodStartPath = getLanguageMappedPaths(
    CHECKOUT_INTERRUPTION_PATH.DELIVERY_METHOD.START,
    locale,
  ).includes(startPath);

  const isDeliveryMethodEndPath = getLanguageMappedPaths(
    CHECKOUT_INTERRUPTION_PATH.DELIVERY_METHOD.END,
    locale,
  ).includes(endPath);

  const isPersonalDetailsStartPath = getLanguageMappedPaths(
    CHECKOUT_INTERRUPTION_PATH.PERSONAL_DETAILS.START,
    locale,
  ).includes(startPath);

  const isPersonalDetailsEndPath = getLanguageMappedPaths(
    CHECKOUT_INTERRUPTION_PATH.PERSONAL_DETAILS.END,
    locale,
  ).includes(endPath);

  return (
    (isOrderOverviewStartPath && isOrderOverviewEndPath) ||
    (isDeliveryMethodStartPath && isDeliveryMethodEndPath) ||
    (isPersonalDetailsStartPath && isPersonalDetailsEndPath)
  );
};
