import {Page, ParameterizedPage, createLinkContent} from '@smart/aem-utils';
import type {DeepExcludeNull, LinkContent} from '@smart/aem-utils';
import {z} from 'zod';

export type AddOnContent = Readonly<
  | {type: 'price-tag'; priceTag: string}
  | {type: 'in-stage-navigation'; links: LinkContent[]}
>;

export const PriceTagContentFragment = z.object({
  __typename: z.literal(`PriceTagModel`),
  priceTag: z.string().nullish(),
});

export const AddOnContentFragment = z.discriminatedUnion(`__typename`, [
  z.object({
    __typename: z.literal(`InStageNavigationModel`),
    linkDestinations: z.array(
      z.discriminatedUnion(`__typename`, [Page, ParameterizedPage]),
    ),
  }),
  PriceTagContentFragment,
]);

export function createAddOnContent(
  contentFragment: DeepExcludeNull<z.TypeOf<typeof AddOnContentFragment>>,
): AddOnContent | undefined {
  if (contentFragment.__typename === `PriceTagModel`) {
    const priceTag = contentFragment.priceTag;

    return priceTag ? {type: `price-tag`, priceTag} : undefined;
  }

  const links: LinkContent[] = [];

  for (const linkDestination of contentFragment.linkDestinations) {
    const linkContent = createLinkContent({
      destination: linkDestination,
      label:
        linkDestination.__typename === `ParameterizedPageModel`
          ? linkDestination.page.navigationLabel
          : linkDestination.navigationLabel,
    });

    if (linkContent) {
      links.push(linkContent);
    }
  }

  return {type: `in-stage-navigation`, links};
}
