import type {ReactFeatureApp} from '@feature-hub/react';

export function isReactFeatureApp(
  featureApp: unknown,
): featureApp is ReactFeatureApp {
  return (
    isObject(featureApp) &&
    (featureApp.loadingPromise === undefined ||
      isPromise(featureApp.loadingPromise)) &&
    typeof featureApp.render === `function`
  );
}

function isObject(value: unknown): value is Record<string, unknown> {
  return value !== null && typeof value === `object`;
}

function isPromise(value: unknown): value is PromiseLike<unknown> {
  return isObject(value) && typeof value.then === `function`;
}
