import type {HeaderType} from '@smart/editorial-components';
import {Header} from '@smart/editorial-components';
import type {HeaderRef} from '@smart/editorial-components/dist/components/header';
import {isGlobalFeatureFlagsEnabled} from '@smart/utils';
import {breakpoints, useScrollPosition} from '@smart/web-components';
import type {IconType} from '@smart/web-components/dist/types/icons';
import type {LinkContent} from '@smart/website-aem-utils';
import {createLinkProps} from '@smart/website-aem-utils';
import type {
  BannerContent,
  HeaderV2Content,
  PageModel,
} from '@smart/website-page-model';
import type {HeaderV2CampaignBannerContent} from '@smart/website-page-model/src/create-header-v2-content';
import {throttle} from 'radash';
import React, {useCallback} from 'react';
import {FEATURE_FLAGS} from '../../types';
import type {FeatureServicesContextValue} from '../hooks/use-feature-services';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useGarageDetails} from '../hooks/use-garage-details';
import {useIsLoggedIn} from '../hooks/use-is-logged-in';
import {useLanguageContext} from '../hooks/use-language-context';
import {useLink} from '../hooks/use-link';
import {useShoppingCartDetails} from '../hooks/use-shopping-cart-details';
import {PageModelContext} from './page';

export interface HeaderV2WrapperProps {
  readonly content: HeaderV2Content;
  readonly bannerContent?: BannerContent;
  readonly subMenuActiveKey?: string;
  readonly setIsBannerVisible: (visible: boolean) => void;
  readonly heroTemplate?: 'award' | 'offer' | 'campaign' | 'model';
  readonly backgroundColor?: string | undefined;
}

const createBannerProps = (
  setIsBannerVisible: HeaderV2WrapperProps['setIsBannerVisible'],
  setShowBanner: (value: boolean) => void,
  featureServices: FeatureServicesContextValue,
  pageModel?: PageModel,
  bannerContent?: BannerContent,
) => {
  if (!bannerContent) return undefined;

  const {headLine, theme, image, text, cta, showCloseButton, closeButtonLabel} =
    bannerContent || {};
  const {endpointDirectory, loginService, historyService, pubSubService} =
    featureServices;
  const {languageTag = ``, marketId = ``} = pageModel?.locale || {};
  const link = cta
    ? createLinkProps({
        historyService,
        content: cta,
        pubSubService,
        websiteTrackingData: {
          type: `navigation`,
          region: `header_banner`,
          name: `${cta.label}`,
        },
        envName: endpointDirectory.envName,
        loginState: loginService?.loginState,
        languageTag,
        marketId,
      })
    : undefined;

  return {
    text,
    headLine,
    theme,
    image,
    link,
    showCloseButton,
    closeButtonLabel,
    close: () => {
      if (bannerContent?.id) {
        setShowBanner(false);
        setIsBannerVisible(false);
      }
    },
  };
};

export const HeaderV2Wrapper = React.memo(
  ({
    content,
    heroTemplate,
    backgroundColor,
    subMenuActiveKey,
    bannerContent,
    setIsBannerVisible,
  }: HeaderV2WrapperProps) => {
    const [showBanner, setShowBanner] = React.useState(!!bannerContent);
    const [isHideSubMenuSecondLine, setIsHideSubMenuSecondLine] =
      React.useState(true);

    const handleScroll = useCallback(
      throttle({interval: 200}, (e) => {
        if (showBanner) {
          setIsBannerVisible(e.prevPos.y < e.currPos.y);
        }
        if (window.innerWidth < breakpoints.l && subMenuProps) {
          setIsHideSubMenuSecondLine(e.prevPos.y < e.currPos.y);
        }
        headerRef.current && headerRef.current.move(e.prevPos.y > e.currPos.y);
      }),
      [showBanner],
    );

    useScrollPosition(handleScroll);

    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};
    const featureServices = useFeatureServices();
    const {endpointDirectory, loginService, historyService} = featureServices;
    const bannerProps = createBannerProps(
      setIsBannerVisible,
      setShowBanner,
      featureServices,
      pageModel,
      bannerContent,
    );

    React.useEffect(() => {
      setShowBanner(!!bannerContent);
      setIsBannerVisible(!!bannerContent);
    }, [bannerContent]);

    const headerRef = React.useRef<HeaderRef>(null);
    const isLoggedIn = useIsLoggedIn();
    const closeMenu = React.useCallback(
      () => headerRef.current?.closeMenu(),
      [],
    );
    React.useEffect(() => historyService.history.listen(closeMenu), []);

    const {selectedLanguage, setSelectedLanguage} = useLanguageContext();
    const [_, startLanguageChangeTransition] = React.useTransition();

    const handleLanguageChange = (val: string) => {
      setSelectedLanguage(val);

      startLanguageChangeTransition(() => {
        window.location.href = window.location.pathname.replace(
          /^\/([^/]*)\//,
          `/${val}/`,
        );
      });
    };
    const enableAnonymousFlow = isGlobalFeatureFlagsEnabled(
      FEATURE_FLAGS.ENABLE_NOT_AUTH_FLOW,
      marketId,
    );

    const logoLink = useLink(content.logoLink, {onBeforePush: closeMenu});

    const myAccountLink = useLink(content.myAccountLink, {
      onBeforePush: closeMenu,
    });

    const shoppingCartLink = useLink(content.shoppingCartLink, {
      onBeforePush: closeMenu,
    });

    const shoppingCartDetails = useShoppingCartDetails({
      enabled: shoppingCartLink !== undefined,
    });

    const myGarageLink = useLink(content.myGarageLink, {
      consumerLocations: [
        {historyKey: `my-account-app`, location: {pathname: `/garage`}},
      ],
      onBeforePush: closeMenu,
    });

    const logoutLink = {
      href: `#`,
      label: content.logoutLabel || `Logout`,
      onClick: () => {
        closeMenu();
        loginService?.logout();
      },
    };

    // TODO: verify that this is not needed for ENABLE_NOT_AUTH_FLOW feature
    const isGarageDetailsEnabled =
      myGarageLink !== undefined &&
      loginService?.loginState.status === `logged-in`;

    const garageDetails = useGarageDetails({
      enabled: isGarageDetailsEnabled,
    });

    const notEmpty = <T,>(value: T | null | undefined): value is T => {
      return value !== null && value !== undefined;
    };

    const getLink = (
      name: string,
      link?: LinkContent,
      type?: string,
      region?: string,
    ) => {
      return link
        ? createLinkProps({
            historyService,
            onBeforePush: closeMenu,
            content: link,
            websiteTrackingData: {
              type: type || `navigation`,
              region: region || `main_nav.quick_menu`,
              name,
            },
            envName: endpointDirectory.envName,
            loginState: loginService?.loginState,
            languageTag,
            marketId,
          })
        : undefined;
    };

    const visibleMenuItems = React.useMemo<
      HeaderType['visibleMenuItems']
    >(() => {
      return content.visibleMenuItems.filter(notEmpty).map((i) => {
        if (i.type === `tiles`) {
          return {
            label: i.label || ``,
            tiles: i.tiles.map((t, index) => {
              if (t.image) {
                return {
                  tileType: `carTile`,
                  label: t.headline || ``,
                  price: t.price || ``,
                  imageUrl: t.image.imageUrl,
                  link: getLink(`${t.headline}.${index}`, t.link),
                  primaryLink: getLink(
                    `${t.headline}.${index}.${t.primaryCta?.label}`,
                    t.primaryCta,
                  ),
                  secondaryLink: getLink(
                    `${t.headline}.${index}.${t.secondaryCta?.label}`,
                    t.secondaryCta,
                  ),
                  contentTag: t.contentTag,
                };
              }

              return {
                tileType: `contentTile`,
                headlineText: t.headline || ``,
                launchText: t.price || ``,
                link: getLink(`${t.headline}.${index}`, t.link),
              };
            }),
          };
        }
        return createLinkProps({
          historyService,
          onBeforePush: closeMenu,
          content: i,
          websiteTrackingData: {
            type: `navigation`,
            region: `main_nav`,
            name: i.label,
          },
          envName: endpointDirectory.envName,
          loginState: loginService?.loginState,
          languageTag,
          marketId,
        });
      });
    }, [content]);

    const getCampaignBanner = (
      myContent?: HeaderV2CampaignBannerContent,
    ): HeaderType['campaign'] => {
      return myContent
        ? {
            mediaType: myContent.mediaType || `image`,
            media: myContent.mediaUrl || ``,
            logo: myContent.logo
              ? {
                  size: myContent.logo.size,
                  url: myContent.logo.url || ``,
                  link: myContent.logo.link
                    ? createLinkProps({
                        historyService,
                        content: myContent.logo.link,
                      })
                    : undefined,
                }
              : undefined,
            variant: myContent.variant || `campaign`,
            backgroundColorOption:
              myContent.backgroundcoloroption || `premium #1`,
            fromLabel: myContent.fromLabel || ``,
            price: myContent.price || ``,
            headline: myContent.headline,
            link: myContent.link
              ? createLinkProps({
                  historyService,
                  onBeforePush: closeMenu,
                  content: myContent.link,
                })
              : undefined,
            description: myContent.description,
          }
        : undefined;
    };

    const navigationItems = React.useMemo<HeaderType['navigationItems']>(() => {
      const items = content.navigationItems.map((i) => {
        return {
          isHighlightedLinks: i.isHighlightedLinks,
          categoryLabel: i.categoryLabel || ``,
          menuItems: i.menuItems.filter(notEmpty).map((m) => {
            if (m.type === `has-second-level`) {
              return {
                label: m.label || ``,
                byline: m.byline || ``,
                contentTag: m.contentTag,
                price: m.priceTag,
                imageUrl: m.image?.imageUrl,
                campaign: getCampaignBanner(m.campaignBanner),
                subItems: m.secondLevel
                  ? m.secondLevel.map((l) => ({
                      categoryLabel: l.categoryLabel || ``,
                      menuItems: l.menuItems.filter(notEmpty).map((x) => ({
                        ...createLinkProps({
                          historyService,
                          onBeforePush: closeMenu,
                          content: x,
                          websiteTrackingData: {
                            type: `navigation`,
                            region: `main_nav.overlay`,
                            name: `${m.label}.${x.label}`,
                          },
                          envName: endpointDirectory.envName,
                          loginState: loginService?.loginState,
                          languageTag,
                          marketId,
                        }),
                        campaign: getCampaignBanner(x.campaignBanner),
                      })),
                    }))
                  : [],
              };
            }

            return {
              ...createLinkProps({
                historyService,
                onBeforePush: closeMenu,
                content: m.secondLink as LinkContent,
                pubSubService: featureServices.pubSubService,
                websiteTrackingData: {
                  type: `navigation`,
                  region: `main_nav.overlay`,
                  name: m.label || ``,
                },
                envName: endpointDirectory.envName,
                loginState: loginService?.loginState,
                languageTag,
                marketId,
              }),
              contentTag: m.contentTag,
              campaign: getCampaignBanner(m.campaignBanner),
            };
          }),
        };
      });

      return items;
    }, [content]);

    const campaign = React.useMemo<HeaderType['campaign']>(() => {
      if (content.campaignBanner) {
        return getCampaignBanner(content.campaignBanner);
      }
      return undefined;
    }, [content]);

    const {
      menuButtonLabel,
      closeButtonLabel,
      greetingText = ``,
      subMenuProps,
    } = content;
    return (
      <Header
        heroTemplate={heroTemplate}
        backgroundColor={backgroundColor}
        subMenuProps={
          subMenuProps
            ? {
                ...subMenuProps,
                activeKey: subMenuActiveKey,
                navigation: subMenuProps?.navigation
                  ? typeof subMenuProps.navigation === `string`
                    ? subMenuProps.navigation
                    : createLinkProps({
                        historyService,
                        pubSubService: featureServices.pubSubService,
                        content: subMenuProps.navigation,
                      })
                  : undefined,
                primaryLinkIcon: subMenuProps?.primaryLinkIcon as IconType,
                secondaryLinkIcon: subMenuProps?.secondaryLinkIcon as IconType,
                hideOnMobile: isHideSubMenuSecondLine,
                primaryLink: subMenuProps.primaryLink
                  ? typeof subMenuProps.primaryLink === `string`
                    ? subMenuProps.primaryLink
                    : createLinkProps({
                        historyService,
                        pubSubService: featureServices.pubSubService,
                        content: subMenuProps.primaryLink,
                      })
                  : undefined,
                primaryDropDownMenuOptions: !!subMenuProps
                  .primaryDropDownMenuOptions?.length
                  ? subMenuProps.primaryDropDownMenuOptions
                      .filter((item) => !!item)
                      .map((link) =>
                        createLinkProps({
                          historyService,
                          pubSubService: featureServices.pubSubService,
                          content: link as LinkContent,
                        }),
                      )
                  : undefined,
                secondaryLink: subMenuProps.secondaryLink
                  ? typeof subMenuProps.secondaryLink === `string`
                    ? subMenuProps.secondaryLink
                    : createLinkProps({
                        historyService,
                        pubSubService: featureServices.pubSubService,
                        content: subMenuProps.secondaryLink,
                      })
                  : undefined,
                secondaryDropDownMenuOptions: !!subMenuProps
                  ?.secondaryDropDownMenuOptions?.length
                  ? subMenuProps.secondaryDropDownMenuOptions
                      .filter((item) => !!item)
                      .map((link) =>
                        createLinkProps({
                          historyService,
                          pubSubService: featureServices.pubSubService,
                          content: link as LinkContent,
                        }),
                      )
                  : undefined,
                items: !!subMenuProps?.items?.length
                  ? subMenuProps.items.map((link) =>
                      typeof link !== `string`
                        ? createLinkProps({
                            historyService,
                            pubSubService: featureServices.pubSubService,
                            content: link as LinkContent,
                          })
                        : link,
                    )
                  : undefined,
              }
            : undefined
        }
        bannerProps={bannerProps && {...bannerProps, showBanner}}
        greetingText={greetingText}
        logoLink={logoLink}
        openMenuAriaLabel={menuButtonLabel}
        closeMenuAriaLabel={closeButtonLabel}
        myAccountLink={myAccountLink}
        myGarageBadge={garageDetails?.itemCount}
        myGarageLink={myGarageLink}
        myShoppingBadge={shoppingCartDetails?.itemCount}
        myShoppingLink={shoppingCartLink}
        logoutLink={logoutLink}
        visibleMenuItems={visibleMenuItems}
        navigationItems={navigationItems}
        campaign={campaign}
        isLoggedIn={isLoggedIn}
        showShoppingCartForAnonymous={enableAnonymousFlow}
        languageSwitch={
          content.localeSwitch?.languageSwitchLabel &&
          content.localeSwitch.languageOptions.length > 1
            ? {
                label: content.localeSwitch.languageSwitchLabel,
                value: selectedLanguage,
                onChange: handleLanguageChange,
                options: content.localeSwitch.languageOptions,
              }
            : undefined
        }
        ref={headerRef}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.content?.subMenuProps === nextProps.content?.subMenuProps &&
    prevProps.backgroundColor === nextProps.backgroundColor &&
    prevProps.heroTemplate === nextProps.heroTemplate &&
    prevProps.subMenuActiveKey === nextProps.subMenuActiveKey &&
    prevProps.bannerContent?.id === nextProps.bannerContent?.id,
);

HeaderV2Wrapper.displayName = `HeaderV2Wrapper`;
