import type {ToastProps} from '@smart/web-components';
import {Toast} from '@smart/web-components';
import * as React from 'react';
import styles from './animated-toast.module.scss';

export interface AnimatedToastProps extends ToastProps {
  readonly show: boolean;
  /**
   * The outer state that controls the `show` prop should be set to false in
   * this callback.
   */
  readonly onHideAnimationEnd: () => void;
}

export function AnimatedToast({
  show,
  onHideAnimationEnd,
  ...toastProps
}: AnimatedToastProps): JSX.Element | null {
  const animationEndCountRef = React.useRef(0);

  const elementRef: React.RefCallback<HTMLDivElement> = (element) => {
    if (!element) {
      animationEndCountRef.current = 0;
    }
  };

  const handleAnimationEnd = () => {
    // The second animation is the hide animation.
    if ((animationEndCountRef.current += 1) === 2) {
      onHideAnimationEnd();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={styles.toastContainer}
      onAnimationEnd={handleAnimationEnd}
      ref={elementRef}
    >
      <Toast {...toastProps} />
    </div>
  );
}
