import type {
  FeatureAppDefinition,
  FeatureAppScope,
  FeatureServices,
} from '@feature-hub/core';
import type {BaseFeatureApp} from '@feature-hub/react';
import * as React from 'react';
import {makeCancelable} from './make-cancelable.js';
import {useFeatureHubContext} from './use-feature-hub-context.js';

export interface UseAsyncFeatureAppScopeOptions<
  TFeatureApp extends BaseFeatureApp,
> {
  readonly isFeatureApp: (featureApp: unknown) => featureApp is TFeatureApp;
  readonly featureAppId: string;
  readonly featureAppName?: string;
  readonly baseUrl: string;
  readonly src: string;
  readonly config?: unknown;
}

export type FeatureAppScopeResult<TFeatureApp extends BaseFeatureApp> =
  | FeatureAppScopeLoadingResult
  | FeatureAppScopeLoadedResult<TFeatureApp>
  | FeatureAppScopeErrorResult;

export interface FeatureAppScopeLoadingResult {
  readonly state: 'loading';
}

export interface FeatureAppScopeLoadedResult<
  TFeatureApp extends BaseFeatureApp,
> {
  readonly state: 'loaded';
  readonly featureAppScope: FeatureAppScope<TFeatureApp>;
}

export interface FeatureAppScopeErrorResult {
  readonly state: 'error';
  readonly error: unknown;
}

export function useAsyncFeatureAppScope<TFeatureApp extends BaseFeatureApp>(
  options: UseAsyncFeatureAppScopeOptions<TFeatureApp>,
): FeatureAppScopeResult<TFeatureApp> {
  const {isFeatureApp, featureAppId, featureAppName, baseUrl, src, config} =
    options;

  const {featureAppManager} = useFeatureHubContext();

  const isFeatureAppScope = (
    featureAppScope: FeatureAppScope<unknown>,
  ): featureAppScope is FeatureAppScope<TFeatureApp> =>
    isFeatureApp(featureAppScope.featureApp);

  const createResult = (
    featureAppDefinition: FeatureAppDefinition<
      unknown,
      FeatureServices,
      unknown
    >,
  ): FeatureAppScopeLoadedResult<TFeatureApp> | FeatureAppScopeErrorResult => {
    const featureAppScope = featureAppManager.createFeatureAppScope(
      featureAppId,
      featureAppDefinition,
      {baseUrl, config, featureAppName},
    );

    return isFeatureAppScope(featureAppScope)
      ? {state: `loaded`, featureAppScope}
      : {state: `error`, error: new Error(`Unknown feature app type.`)};
  };

  const url = baseUrl
    ? `${baseUrl.replace(/\/$/, ``)}/${src.replace(/^\//, ``)}`
    : src;

  const [result, setResult] = React.useState<
    FeatureAppScopeResult<TFeatureApp>
  >({state: `loading`});

  React.useEffect(() => {
    const asyncFeatureAppDefinition =
      featureAppManager.getAsyncFeatureAppDefinition(url);

    const {promise, cancel} = makeCancelable(asyncFeatureAppDefinition.promise);

    if (asyncFeatureAppDefinition.value) {
      setResult(createResult(asyncFeatureAppDefinition.value));
    } else {
      promise
        .then((featureAppDefinition) =>
          setResult(createResult(featureAppDefinition)),
        )
        .catch((error) => setResult({state: `error`, error}));
    }

    return cancel;
  }, []);

  React.useEffect(
    () => () => {
      if (result.state === `loaded`) {
        result.featureAppScope.release?.();
      }
    },
    [result],
  );

  return result;
}
