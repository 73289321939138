import type {CreateMediaUrlOptions, LinkContent} from '@smart/aem-utils';
import {
  LinkDestination,
  createId,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
import {
  type ExtendedFlexibleGalleryContent,
  ExtendedFlexibleGalleryContentFragment,
  createExtendedFlexibleGalleryContent,
} from './create-extended-flexible-gallery-content.js';
import type {HeadlineV2Content} from './create-headline-v2-content.js';
import {
  HeadlineV2ContentFragment,
  createHeadlineV2Content,
} from './create-headline-v2-content.js';
import type {MediaStageContent} from './create-media-stage-content.js';
import {
  MediaStageContentFragment,
  createMediaStageContent,
} from './create-media-stage-content.js';
import type {RichtextContent} from './create-richtext-content.js';
import {
  RichtextContentFragment,
  createRichtextContent,
} from './create-richtext-content.js';
import type {StatementContent} from './create-statement-content.js';
import {
  StatementContentFragment,
  createStatementContent,
} from './create-statement-content.js';
import type {TeaserRowV2Content} from './create-teaser-row-v2-content.js';
import {
  TeaserRowV2ContentFragment,
  createTeaserRowV2Content,
} from './create-teaser-row-v2-content.js';
import type {TextParagraphContent} from './create-text-paragraph-content.js';
import {
  TextParagraphContentFragment,
  createTextParagraphContent,
} from './create-text-paragraph-content.js';
import type {TextWithMediaContent} from './create-text-with-media-content.js';
import {
  TextWithMediaContentFragment,
  createTextWithMediaContent,
} from './create-text-with-media-content.js';

export interface AccordionItemContent {
  readonly title: string;
  readonly isExpanded: boolean;
  readonly paragraph?: string;
  readonly image?: string;
  readonly components: (
    | {type: 'headline'; content: HeadlineV2Content}
    | {type: 'textParagraph'; content: TextParagraphContent}
    | {
        type: 'extendedFlexibleGallery';
        content: ExtendedFlexibleGalleryContent;
      }
    | {type: 'teaserRowV2'; content: TeaserRowV2Content}
    | {type: 'statement'; content: StatementContent}
    | {type: 'textWithMedia'; content: TextWithMediaContent}
    | {type: 'mediaStage'; content: MediaStageContent}
    | {type: 'richtext'; content: RichtextContent}
  )[];
}

export interface AccordionContent {
  readonly type: 'accordion';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly mode?: string;
  readonly variant: 'full' | '2/3' | '50/50';
  readonly size?: 'Headline/100' | 'Headline/50' | 'Paragraph/300';
  readonly subline?: string;
  readonly headline?: string;
  readonly items: AccordionItemContent[];
  readonly link?: LinkContent;
  readonly mboxName?: string;
}

const AccordionItemContentFragment = z.object({
  title: z.string(),
  isExpanded: z.boolean().nullish(),
  paragraph: z.string().nullish(),
  image: z.object({_path: z.string()}).nullish(),
  components: z.array(
    z.discriminatedUnion(`__typename`, [
      HeadlineV2ContentFragment.extend({
        __typename: z.literal(`HeadlineV2Model`),
        _path: z.string(),
      }),
      TextParagraphContentFragment.extend({
        __typename: z.literal(`TextParagraphModel`),
        _path: z.string(),
      }),
      ExtendedFlexibleGalleryContentFragment.extend({
        __typename: z.literal(`ExtendedFlexibleGalleryModel`),
        _path: z.string(),
      }),
      TeaserRowV2ContentFragment.extend({
        __typename: z.literal(`TeaserRowV2Model`),
        _path: z.string(),
      }),
      StatementContentFragment.extend({
        __typename: z.literal(`StatementModel`),
        _path: z.string(),
      }),
      TextWithMediaContentFragment.extend({
        __typename: z.literal(`TextWithMediaModel`),
        _path: z.string(),
      }),
      MediaStageContentFragment.extend({
        __typename: z.literal(`MediaStageModel`),
        _path: z.string(),
      }),
      RichtextContentFragment.extend({
        __typename: z.literal(`RichtextModel`),
        _path: z.string(),
      }),
    ]),
  ),
});

const AccordionContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  mode: z.string().nullish(),
  variant: z.string().nullish(),
  size: z.string().nullish(),
  subline: z.string().nullish(),
  headline: z.string().nullish(),
  items: z.array(AccordionItemContentFragment).nullish(),
  linkLabel: z.string().nullish(),
  linkDestination: LinkDestination.nullish(),
  mboxName: z.string().nullish(),
});

function getComponents(
  fragment: z.TypeOf<typeof AccordionItemContentFragment>['components'][0],
  options: CreateMediaUrlOptions,
) {
  if (fragment.__typename === `HeadlineV2Model`) {
    return {
      type: `headline` as const,
      content: createHeadlineV2Content(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'HeadlineV2Model'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `TextParagraphModel`) {
    return {
      type: `textParagraph` as const,
      content: createTextParagraphContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'TextParagraphModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `ExtendedFlexibleGalleryModel`) {
    return {
      type: `extendedFlexibleGallery` as const,
      content: createExtendedFlexibleGalleryContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'ExtendedFlexibleGalleryModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `TeaserRowV2Model`) {
    return {
      type: `teaserRowV2` as const,
      content: createTeaserRowV2Content(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'TeaserRowV2Model'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `StatementModel`) {
    return {
      type: `statement` as const,
      content: createStatementContent(
        fragment as Extract<AemContentFragment, {__typename: 'StatementModel'}>,
      ),
    };
  }
  if (fragment.__typename === `TextWithMediaModel`) {
    return {
      type: `textWithMedia` as const,
      content: createTextWithMediaContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'TextWithMediaModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `MediaStageModel`) {
    return {
      type: `mediaStage` as const,
      content: createMediaStageContent(
        fragment as Extract<
          AemContentFragment,
          {__typename: 'MediaStageModel'}
        >,
        options,
      ),
    };
  }
  if (fragment.__typename === `RichtextModel`) {
    return {
      type: `richtext` as const,
      content: createRichtextContent(
        fragment as Extract<AemContentFragment, {__typename: 'RichtextModel'}>,
      ),
    };
  }

  return null;
}

function createAccordionItemsContent(
  items: z.TypeOf<typeof AccordionItemContentFragment>[],
  options: CreateMediaUrlOptions,
): AccordionItemContent[] {
  return items.map((item) => {
    const {title, isExpanded, components, paragraph, image} = item;
    return {
      title,
      paragraph: paragraph || undefined,
      image:
        image != null
          ? createMediaUrl(`image`, image._path, options)
          : undefined,
      isExpanded: isExpanded || false,
      components:
        components && components.length > 0
          ? components
              .map((c) => getComponents(c, options))
              .filter(<T>(value: T | null | undefined): value is T => {
                return value !== null && value !== undefined;
              })
          : [],
    };
  });
}

export function createAccordionContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'AccordionModel'}>,
  options: CreateMediaUrlOptions,
): AccordionContent {
  const {
    removeTopSpacing,
    mode,
    variant,
    size,
    subline,
    headline,
    items,
    mboxName,
    linkLabel,
    linkDestination,
  } = sanitizeGraphqlData(AccordionContentFragment, contentFragment);

  return {
    type: `accordion`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    removeTopSpacing,
    mode,
    variant:
      variant == null ? `full` : (variant as AccordionContent['variant']),
    size: size as AccordionContent['size'],
    subline,
    headline,
    items: createAccordionItemsContent(items || [], options),
    link: createLinkContent({
      destination: linkDestination,
      label: linkLabel,
    }),
    mboxName,
  };
}
