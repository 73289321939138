import type {OpenGraphWebsiteContent} from '@smart/website-page-model';
import React from 'react';
import {Helmet} from 'react-helmet-async';

export interface OpenGraphMetaTagsProps {
  readonly openGraphWebsite: OpenGraphWebsiteContent;
  readonly languageTag: string;
  readonly pageUrl: string;
}

const imageWidth = `1200`;
const imageHeight = `630`;

export function OpenGraphMetaTags({
  openGraphWebsite,
  languageTag,
  pageUrl,
}: OpenGraphMetaTagsProps) {
  const {title, description, image} = openGraphWebsite;
  const s7Url = `${image.imageUrl}:16-9?wid=${imageWidth}&hei=${imageHeight}&fit=crop,1`;

  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={languageTag} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={s7Url} />
      <meta property="og:image:secure_url" content={s7Url} />
      <meta property="og:image:alt" content={image.imageAltText} />
      <meta property="og:image:type" content={image.mimeType} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
    </Helmet>
  );
}
