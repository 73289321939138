import React from 'react';
import {Helmet} from 'react-helmet-async';
import type {VoiceOfCustomerTypeProps} from '../types';

export const Survey = ({config: {script, id}}: VoiceOfCustomerTypeProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{script}</script>
      </Helmet>
      <div id={id}></div>
    </>
  );
};
