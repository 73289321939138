import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {getActiveChatbotSessionLocale} from '../utils/client/get-active-chatbot-session-locale';

export function useEnableChatbot(pageModel: PageModel): boolean {
  const {enableChatbot} = pageModel;

  const [hasActiveSession, setHasActiveSession] = React.useState(false);

  React.useEffect(() => {
    if (!enableChatbot && getActiveChatbotSessionLocale()) {
      setHasActiveSession(true);
    }
  }, []);

  return enableChatbot || hasActiveSession;
}
