import type {HistoryServiceV3} from '@feature-hub/history-service';
import type {SmartPv1} from '@smart/analytics';
import {getCountryCode, getCurrency, getLanguage} from '@smart/locale-service';
import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {createTrackingUserData} from '../utils/client/create-tracking-user-data';
import {pushToDataLayer} from '../utils/client/push-to-data-layer';
import {useFeatureServices} from './use-feature-services';

const version = process.env.VERSION!;

export function usePageViewTracking(pageModel: PageModel): void {
  const {endpointDirectory, historyService, loginService} =
    useFeatureServices();

  const currentReferrerRef = React.useRef(
    typeof window !== `undefined` ? document.referrer : ``,
  );

  const nextReferrerRef = React.useRef(``);

  React.useEffect(() => {
    const {
      locale: {marketId, languageTag},
      analytics: {pageName = `unknown`, pageType = `unknown`} = {},
    } = pageModel;

    const page: Required<SmartPv1['page']> = {
      name: pageName,
      type: pageType,
      referringPage: currentReferrerRef.current,
      url: window.location.href,
    };

    pushToDataLayer({
      event: `pageview`,
      page,
      user: createTrackingUserData(loginService?.loginState),
      application: {
        id: `aem`,
        version,
        env: endpointDirectory.envName,
        language: getLanguage(languageTag),
        market: getCountryCode(marketId).toLowerCase(),
        currency: getCurrency(marketId),
      },
    });
  }, [pageModel]);

  React.useEffect(() => {
    nextReferrerRef.current = getReferrerUrl(historyService);

    return historyService.rootHistory.listen(() => {
      const nextReferrer = getReferrerUrl(historyService);

      if (nextReferrer !== nextReferrerRef.current) {
        currentReferrerRef.current = nextReferrerRef.current;
        nextReferrerRef.current = nextReferrer;
      }
    });
  }, [historyService]);
}

function getReferrerUrl(historyService: HistoryServiceV3): string {
  const {pathname, search} = historyService.rootHistory.location;

  return new URL(
    historyService.rootHistory.createHref({pathname, search}),
    document.location.origin,
  ).href;
}
