import * as React from 'react';
import type {Personalization} from '../utils/client/fetch-personalization';
import {fetchPersonalizationTracking} from '../utils/client/fetch-personalization-tracking';

export function usePersonalizationTracking(
  personalization: Personalization | undefined,
): void {
  React.useEffect(() => {
    if (!personalization) {
      return;
    }

    const {ecid, activityData} = personalization;
    const abortController = new AbortController();

    void fetchPersonalizationTracking({
      ecid,
      activityData,
      signal: abortController.signal,
    });

    return () => abortController.abort();
  }, [personalization]);
}
