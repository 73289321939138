import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useFeatureServices} from '../../../hooks/use-feature-services';
import {INACTIVITY_TIMEOUT_MS} from '../constants';
import {
  useHistoryPath,
  useInactivityCallback,
  useInterruptionCallback,
  useLocale,
} from '../hooks';
import type {VoiceOfCustomerTypeProps} from '../types';

export const InterruptionPopup = ({
  config: {id, script},
}: VoiceOfCustomerTypeProps) => {
  const [hasPopupBeenDisplayed, setHasPopupBeenDisplayed] = useState(false);
  const [isUserInactive, setIsUserInactive] = useState(false);
  const [interruptionPageName, setInterruptionPageName] = useState(`unknown`);
  const {adbContextService, pricePreferencesProvider, loginService} =
    useFeatureServices();
  const path = useHistoryPath();
  const locale = useLocale();

  // TODO: use 'usePriceType' hook once /app is migrated and uses @smart/feature-app/node for creating 'useFeatureServices' context
  const customerType =
    loginService?.loginState.status === `logged-in`
      ? loginService?.loginState.customerType
      : pricePreferencesProvider.getPriceType();

  useInactivityCallback({
    setHasPopupBeenDisplayed,
    setInterruptionPageName,
    setIsUserInactive,
    enabled: !hasPopupBeenDisplayed,
    locale,
    path,
    timeout: INACTIVITY_TIMEOUT_MS,
  });

  useInterruptionCallback({
    setHasPopupBeenDisplayed,
    setInterruptionPageName,
    locale,
    path,
  });

  if (
    adbContextService?.adbContext?.hasCustomer ||
    customerType === `commercial`
  )
    return null;
  if (!hasPopupBeenDisplayed) return null;

  // NOTE: DO NOT MODIFY SCRIPT. Used by qualtrics to track interruption
  const qualtricsInterruptionActiveScript = `var isInterruptionActive = ${hasPopupBeenDisplayed}; var interruptionPageName = "${interruptionPageName}";`;

  // NOTE: DO NOT MODIFY SCRIPT. Used by qualtrics to track inactivity
  const qualtricsInactivityActiveScript = `var isUserInactive = ${isUserInactive}; var interruptionPageName = "${interruptionPageName}";`;

  return (
    <>
      <Helmet>
        {/* NOTE: DO NOT MODIFY ID. Used by qualtrics to track interruption */}
        <script type="text/javascript" id="qualtrics-interruption-script">
          {qualtricsInterruptionActiveScript}
        </script>
        {/* NOTE: DO NOT MODIFY ID. Used by qualtrics to track inactivity */}
        <script type="text/javascript" id="qualtrics-inactivity-script">
          {qualtricsInactivityActiveScript}
        </script>
        {/* NOTE: qualtrics main script should be loaded last to get access to the variables inserted above */}
        <script type="text/javascript">{script}</script>
      </Helmet>
      <div id={id}></div>
    </>
  );
};
