import type {ConsumerLocationV3} from '@feature-hub/history-service';
import type {LinkType} from '@smart/web-components';
import type {LinkContent} from '@smart/website-aem-utils';
import {createLinkProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from './use-feature-services';

export interface UseLinkOptions {
  readonly consumerLocations?: ConsumerLocationV3[];
  readonly onBeforePush?: () => void;
  readonly websiteTrackingData?: {
    type: string;
    region: string;
    name: string;
  };
  readonly languageTag?: string;
  readonly marketId?: string;
}

export function useLink(
  content: LinkContent,
  options?: UseLinkOptions,
): LinkType;
export function useLink(
  content: undefined,
  options?: UseLinkOptions,
): undefined;
export function useLink(
  content: LinkContent | undefined,
  options?: UseLinkOptions,
): LinkType | undefined;
export function useLink(
  content: LinkContent | undefined,
  options?: UseLinkOptions,
): LinkType | undefined {
  const {endpointDirectory, loginService, historyService} =
    useFeatureServices();

  return React.useMemo<LinkType | undefined>(() => {
    return content
      ? createLinkProps({
          historyService,
          content,
          envName: endpointDirectory.envName,
          loginState: loginService?.loginState,
          ...options,
        })
      : undefined;
  }, [content]);
}
