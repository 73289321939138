import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  ImageWithTransparencySupportContent,
  LegalDisclaimer,
  LegalDisclaimerWithReference,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface FactsAndFiguresContent {
  readonly type: 'facts-and-figures';
  readonly id: string;
  readonly anchorId: string;
  readonly componentHeadline: string | undefined;
  readonly items: FactsAndFiguresCardContent[];
  readonly indicatorBar: boolean | undefined;
  readonly mboxName: string | undefined;
}

export interface FactsAndFiguresCardContent {
  readonly tabTitle: string;
  readonly headline: string;
  readonly image: Image;
  readonly items: FactsAndFiguresItemContent[];
  readonly primaryLink: LinkContent | undefined;
  readonly secondaryLink: LinkContent | undefined;
  readonly legalDisclaimers: LegalDisclaimerContent[];
}

export interface FactsAndFiguresItemContent {
  readonly label: string;
  readonly value: string;
  readonly reference: string | undefined;
}

const FactsAndFiguresContentFragment = z.object({
  componentHeadline: z.string().nullish(),
  items: z.array(
    z
      .object({
        tabTitle: z.string(),
        headline: z.string(),
        items: z.array(
          z.object({
            label: z.string(),
            value: z.string(),
            legalDisclaimers: z.array(LegalDisclaimerWithReference),
          }),
        ),
        primaryLinkDestination: LinkDestination.nullish(),
        primaryLinkLabel: z.string().nullish(),
        secondaryLinkDestination: LinkDestination.nullish(),
        secondaryLinkLabel: z.string().nullish(),
        legalDisclaimers: z.array(LegalDisclaimer),
      })
      .and(ImageWithTransparencySupportContent),
  ),
  indicatorBar: z.boolean().nullish(),
  mboxName: z.string().nullish(),
});

export function createFactsAndFiguresContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'Facts_FiguresModel'}
  >,
  options: CreateMediaUrlOptions,
): FactsAndFiguresContent {
  const {
    componentHeadline,
    items: cards,
    indicatorBar,
    mboxName,
  } = sanitizeGraphqlData(FactsAndFiguresContentFragment, contentFragment);

  return {
    type: `facts-and-figures`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    componentHeadline,
    items: cards.map(
      ({
        tabTitle,
        headline,
        image,
        imageAltText,
        imageTransparencySupport,
        items,
        primaryLinkDestination,
        primaryLinkLabel,
        secondaryLinkDestination,
        secondaryLinkLabel,
        legalDisclaimers,
      }): FactsAndFiguresCardContent => ({
        tabTitle,
        headline,
        image: createImage(
          {image, imageAltText, imageTransparencySupport},
          options,
        ),
        items: items.map(
          ({
            label,
            value,
            legalDisclaimers: itemLegalDisclaimers,
          }): FactsAndFiguresItemContent => {
            const disclaimerReferences = new Set(
              itemLegalDisclaimers
                .map(({reference}) => reference)
                .filter(Boolean),
            );

            return {
              label,
              value,
              reference:
                disclaimerReferences.size > 0
                  ? [...disclaimerReferences].join(`,`)
                  : undefined,
            };
          },
        ),
        primaryLink: createLinkContent({
          destination: primaryLinkDestination,
          label: primaryLinkLabel,
        }),
        secondaryLink: createLinkContent({
          destination: secondaryLinkDestination,
          label: secondaryLinkLabel,
        }),
        legalDisclaimers: createLegalDisclaimerContents(
          legalDisclaimers,
          options,
        ),
      }),
    ),
    indicatorBar,
    mboxName,
  };
}
