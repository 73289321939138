import {Loader} from '@smart/web-components';
import classnames from 'classnames';
import * as React from 'react';
import {useAsyncReactFeatureApp} from '../hooks/use-async-react-feature-app';
import styles from './feature-app-loader-with-loading-ui.module.scss';

export interface FeatureAppLoaderWithLoadingUiProps {
  readonly featureAppId: string;
  readonly featureAppName: string;
  readonly baseUrl: string;
  readonly src: string;
  readonly config?: unknown;
  readonly isFirstContentOnPage?: boolean;
  readonly isBannerVisible?: boolean;
}

const renderWithoutMargins = (
  featureAppName: string,
  config?: Record<string, unknown>,
) => {
  if (featureAppName === `forms` && config?.variant === `subscribe_popup`)
    return true;
  const includesApps = [`visualizer`, `comparison`, `smart-choices`];
  if (includesApps.includes(featureAppName)) return true;
  return false;
};

export const FeatureAppLoaderWithLoadingUi = React.memo(
  React.forwardRef<HTMLElement, FeatureAppLoaderWithLoadingUiProps>(
    ({isFirstContentOnPage, isBannerVisible, ...options}, ref) => {
      const featureAppResult = useAsyncReactFeatureApp(options);
      const loadingId = React.useId();
      return (
        <section
          className={classnames([
            styles.featureAppLoader,
            {[styles.first!]: isFirstContentOnPage},
            {
              [styles.noMargins!]: renderWithoutMargins(
                options.featureAppName,
                options.config as Record<string, unknown>,
              ),
            },
            {[styles.bannerVisible!]: isBannerVisible},
          ])}
          ref={ref}
        >
          <div hidden={featureAppResult.state === `loading`}>
            {featureAppResult.featureApp?.render()}
          </div>
          {featureAppResult.state === `loading` && (
            <div className={styles.loaderWrapper}>
              <Loader
                hiddenLoadingStatus="Loading..." /* TODO: use localized loading status */
                messageId={loadingId}
                size="medium"
              />
            </div>
          )}
        </section>
      );
    },
  ),
);

FeatureAppLoaderWithLoadingUi.displayName = `FeatureAppLoaderWithLoadingUi`;
