import type {PageModelResponse} from '@smart/website-page-model';
import * as React from 'react';

export const PageModelResponseCacheContext = React.createContext<
  Map<string, Promise<PageModelResponse>> | undefined
>(undefined);

export function usePageModelResponseCacheContext(): Map<
  string,
  Promise<PageModelResponse>
> {
  const cache = React.useContext(PageModelResponseCacheContext);

  if (!cache) {
    throw new Error(
      `PageModelResponseCacheContext provider is missing. Note: It's value must not be shared across server requests.`,
    );
  }

  return cache;
}
