import {Accordion} from '@smart/editorial-components';
import {RichText} from '@smart/web-components';
import type {RichtextContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {buildCookieListMarkdown} from '../utils/client/build-cookie-list-markdown';
import styles from './richtext-wrapper.module.scss';
export interface RichtextWrapperProps {
  readonly content: RichtextContent;
  readonly inAccordion?: boolean;
}
type AccordionItemType = {
  content: JSX.Element | undefined;
  title: string;
  isExpanded: boolean;
};
export const RichtextWrapper = React.memo(
  ({content, inAccordion = false}: RichtextWrapperProps) => {
    const [cookieListMarkdown, setCookieListMarkdown] = React.useState(``);
    const [accordionlist, setAccordionList] = React.useState<
      AccordionItemType[]
    >([]);
    const [contentBeforeAccordion, setContentBeforeAccordion] =
      React.useState(``);
    React.useEffect(() => {
      if ((window as any).OneTrust) {
        setCookieListMarkdown(buildCookieListMarkdown());
        return;
      }
      const abortController = new AbortController();
      window.addEventListener(
        `onetrustConsentChange`,
        () => setCookieListMarkdown(buildCookieListMarkdown()),
        {signal: abortController.signal, once: true},
      );

      return () => abortController.abort();
    }, []);
    const richtext = React.useMemo(
      () => content.richtext.replace(/@@COOKIE_LIST@@/g, cookieListMarkdown),
      [cookieListMarkdown],
    );

    const getAccordion = (richtexts: string) => {
      if (!richtexts) return {sections: [], preContent: ``};

      const lines = richtexts.split(`\n`);
      const sections = [];
      let preContent = ``;
      let currentSection = {title: ``, content: ``};
      let isFirstFound = false;
      const matchTitleLine = /^\s*##\s*(.*)/;

      for (const line of lines) {
        if (!isFirstFound && line.startsWith(`## `)) {
          isFirstFound = true;
        }

        if (!isFirstFound) {
          preContent += line + `\n`;
        } else {
          if (line.match(matchTitleLine) && line.startsWith(`## `)) {
            if (currentSection.title || currentSection.content) {
              sections.push({...currentSection});
            }
            const titles = line.replace(/^##/, ``).trim();
            currentSection = {title: titles, content: ``};
          } else {
            currentSection.content += line + `\n`;
          }
        }
      }

      sections.push({...currentSection});

      const updatedArrayData = sections.map((item) => ({
        ...item,
        content:
          item.content === `\n` ? undefined : (
            <RichText
              className={styles[`richtext-in-accordion`]}
              mdString={item.content}
              enableParsingRawHtml={content.enableParsingRawHtml}
            />
          ),
        isExpanded: false,
      }));

      return {
        updatedArrayData,
        preContent,
      };
    };
    React.useEffect(() => {
      const {updatedArrayData, preContent} = getAccordion(richtext);
      const arrayData = updatedArrayData || [];
      setAccordionList(arrayData);
      setContentBeforeAccordion(preContent);
    }, [richtext]);
    return (
      <>
        {content.asAccordion ? (
          <>
            <RichText
              className={styles[`richtext-in-accordion`]}
              mdString={contentBeforeAccordion}
              wrapperElement="section"
              enableParsingRawHtml={content.enableParsingRawHtml}
            />
            <Accordion items={accordionlist} variant="full" />
          </>
        ) : (
          <RichText
            className={
              inAccordion ? styles[`richtext-in-accordion`] : styles.richtext
            }
            mdString={richtext}
            wrapperElement="section"
            enableParsingRawHtml={content.enableParsingRawHtml}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

RichtextWrapper.displayName = `RichtextWrapper`;
