import {
  TextWithMedia,
  type TextWithMediaType,
} from '@smart/editorial-components';
import type {TextWithMediaContent} from '@smart/website-aem-utils';
import {createLegalDisclaimerProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useLink} from '../hooks/use-link';
import {PageModelContext} from './page';

export interface TextWithMediaWrapperProps {
  readonly content: TextWithMediaContent;
  readonly cdnOrigin: string;
}

const customVideoStylesBaseUrl = process.env.CUSTOM_VIDEO_STYLES_BASE_URL!;

export const TextWithMediaWrapper = React.memo(
  ({content, cdnOrigin}: TextWithMediaWrapperProps) => {
    const {historyService} = useFeatureServices();
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const primaryLink = useLink(content.primaryLink, {
      websiteTrackingData: {
        type: `navigation`,
        region: `text_media`,
        name: content.primaryLink?.label || ``,
      },
      languageTag,
      marketId,
    });
    const secondaryLink = useLink(content.secondaryLink, {
      websiteTrackingData: {
        type: `navigation`,
        region: `text_media`,
        name: content.secondaryLink?.label || ``,
      },
      languageTag,
      marketId,
    });

    const textWithMedia: TextWithMediaType = {
      alignment: content.alignment,
      headline: content.headline,
      contentText: content.contentText,
      image: {
        imageUrl: content.imageUrl || ``,
        imageAltText: content.mediaAltText || ``,
      },
      video: {
        landscapeUrl: content.videoLandscapeUrl || ``,
        portraitUrl: content.videoPortraitUrl || ``,
        posterImageUrl: content.videoPosterImageUrl || ``,
        mediaAltText: content.mediaAltText || ``,
      },
      customVideoStylesBaseUrl: new URL(customVideoStylesBaseUrl, cdnOrigin)
        .href,
      legalDisclaimers: createLegalDisclaimerProps(
        content.legalDisclaimers,
        historyService,
      ),
      useLimeGreen: content.useLimeGreen,
      removeTopSpacing: content.removeTopSpacing,
      primaryLink,
      secondaryLink,
      logos: content.logos,
      mode: content.modeSelector,
    };

    return <TextWithMedia {...textWithMedia} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

TextWithMediaWrapper.displayName = `TextWithMediaWrapper`;
