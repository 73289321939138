import type {Content} from '@smart/website-aem-utils';
import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';

export function useIsStageInViewport(
  elementRef: React.RefObject<HTMLElement>,
  pageModel: PageModel,
): boolean {
  const [isStageInViewport, setIsStageInViewport] = React.useState(
    isFirstContentStageLike(pageModel),
  );

  const intersectionObserverRef = React.useRef<IntersectionObserver>();

  React.useEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(([entry]) => {
      if (entry?.target.isConnected) {
        setIsStageInViewport(entry.isIntersecting || false);
      }
    });
  }, []);

  React.useEffect(() => {
    const element = elementRef.current;

    if (!element || !isFirstContentStageLike(pageModel)) {
      return setIsStageInViewport(false);
    }

    intersectionObserverRef.current?.observe(element);

    return () => intersectionObserverRef.current?.unobserve(element);
  }, [pageModel]);

  return isStageInViewport;
}

const stageTypes: Content['type'][] = [
  `image-stage`,
  `image-stage-small`,
  `video-stage`,
  `hero-stage`,
];

const stageLikeFeatureAppNames = [`customizer`];

function isFirstContentStageLike(pageModel: PageModel): boolean {
  const [firstContent] = pageModel.contents;

  return firstContent?.type
    ? stageTypes.includes(firstContent.type) ||
        (firstContent.type === `feature-app` &&
          stageLikeFeatureAppNames.includes(firstContent.featureAppName))
    : false;
}
