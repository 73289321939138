import type {EnvNameV3} from '@smart/endpoint-directory';

export interface CreateChatbotUrlOptions {
  readonly type: 'css' | 'js';
  readonly envName: EnvNameV3;
}

export function createChatbotUrl(options: CreateChatbotUrlOptions): string {
  const {type, envName} = options;

  return `https://cdn${
    envName === `prod` ? `` : `-test`
  }.conversationalsdevelopment.nl/smart/client/v1/index.${type}`;
}
