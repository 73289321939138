import type {HeadlineV2Type} from '@smart/editorial-components';
import {HeadlineV2} from '@smart/editorial-components';
import type {HeadlineV2Content} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {getItemContent} from '../utils/client/accordion-get-content';
import {PageModelContext} from './page';
export interface HeadlineV2WrapperProps {
  readonly content: HeadlineV2Content;
  readonly cdnOrigin: string;
}

export const HeadlineV2Wrapper = React.memo(
  ({content, cdnOrigin}: HeadlineV2WrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const link = useLink(content.link, {
      websiteTrackingData: {
        type: `navigation`,
        region: `headline_text_v2`,
        name: content.link?.label || ``,
      },
      languageTag,
      marketId,
    });
    const props: HeadlineV2Type = {
      removeTopSpacing: content.removeTopSpacing,
      mode: content.mode,
      layout: content.layout,
      headline: content.headline,
      subHeadline: content.subHeadline,
      link,
      size: content.size,
      asAccordion: content.asAccordion,
      logos: content.logos,
      content: getItemContent(content.components, cdnOrigin),
    };
    return <HeadlineV2 {...props} />;
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.cdnOrigin === nextProps.cdnOrigin,
);

HeadlineV2.displayName = `HeadlineV2Wrapper`;
