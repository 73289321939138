import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  ImageContent,
  LegalDisclaimers,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface FullWidthTeaserContent {
  readonly type: 'full-width-teaser';
  readonly id: string;
  readonly anchorId: string;
  readonly headline?: string;
  readonly copyText?: string;
  readonly image: Image;
  readonly link: LinkContent;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

const FullWidthTeaserContentFragment = z
  .object({
    headline: z.string().nullish(),
    copyText: z.string().nullish(),
    linkLabel: z.string(),
    linkDestination: LinkDestination,
    legalDisclaimer: LegalDisclaimers.nullish(),
    mboxName: z.string().nullish(),
  })
  .and(ImageContent);

export function createFullWidthTeaserContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'FullWidthTeaserModel'}
  >,
  options: CreateMediaUrlOptions,
): FullWidthTeaserContent {
  const {
    headline,
    copyText,
    image,
    imageAltText,
    linkDestination,
    linkLabel,
    legalDisclaimer,
    mboxName,
  } = sanitizeGraphqlData(FullWidthTeaserContentFragment, contentFragment);

  return {
    type: `full-width-teaser`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    headline,
    copyText,
    image: createImage({image, imageAltText}, options),
    link: createLinkContent({
      destination: linkDestination,
      label: linkLabel,
    }),
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
  };
}
