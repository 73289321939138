import type {ReactFeatureApp} from '@feature-hub/react';
import type {
  FeatureAppLoadedResult,
  FeatureAppLoadingResult,
} from '@feature-hub/react-hooks';
import {
  isReactFeatureApp,
  useAsyncFeatureAppScope,
  useFeatureApp,
} from '@feature-hub/react-hooks';

export interface UseAsyncReactFeatureAppOptions {
  readonly featureAppId: string;
  readonly featureAppName?: string;
  readonly baseUrl: string;
  readonly src: string;
  readonly config?: unknown;
}

export function useAsyncReactFeatureApp(
  options: UseAsyncReactFeatureAppOptions,
):
  | FeatureAppLoadingResult<ReactFeatureApp>
  | FeatureAppLoadedResult<ReactFeatureApp> {
  const featureAppScopeResult = useAsyncFeatureAppScope({
    ...options,
    isFeatureApp: isReactFeatureApp,
  });

  const featureAppResult = useFeatureApp({featureAppScopeResult});

  if (featureAppResult.state === `error`) {
    throw featureAppResult.error;
  }

  if (featureAppResult.state === `timeout`) {
    throw new Error(
      `The Feature App with the ID "${options.featureAppId}" did not resolve its loading promise in time (${featureAppResult.timeoutInSeconds}s).`,
    );
  }

  return featureAppResult;
}
