import type {
  CreateMediaUrlOptions,
  LegalDisclaimerContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  createId,
  createLegalDisclaimerContents,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface MediaStageContent {
  readonly type: 'media-stage';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly mode?: string;
  readonly aspectRatio?: '16/9' | '4/3';
  readonly enableSideSpacing?: boolean;
  readonly contentType: 'image' | 'video';
  readonly imageUrl?: string;
  readonly videoLandscapeUrl?: string;
  readonly videoPortraitUrl?: string;
  readonly altText?: string;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName?: string;
}

export const MediaStageContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  mode: z.string().nullish(),
  aspectRatio: z.string().nullish(),
  enableSideSpacing: z.boolean().nullish(),
  image: z.object({_path: z.string()}).nullish(),
  videoLandscape: z.object({_path: z.string()}).nullish(),
  videoPortrait: z.object({_path: z.string()}).nullish(),
  altText: z.string().nullish(),
  legalDisclaimers: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
});

export function createMediaStageContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'MediaStageModel'}>,
  options: CreateMediaUrlOptions,
): MediaStageContent {
  const {
    removeTopSpacing,
    mode,
    aspectRatio,
    enableSideSpacing,
    image,
    videoLandscape,
    videoPortrait,
    altText,
    legalDisclaimers,
    mboxName,
  } = sanitizeGraphqlData(MediaStageContentFragment, contentFragment);

  return {
    type: `media-stage`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    removeTopSpacing,
    mode,
    aspectRatio: aspectRatio as MediaStageContent['aspectRatio'],
    enableSideSpacing,
    contentType:
      videoLandscape != null || videoPortrait != null ? `video` : `image`,
    imageUrl:
      image != null ? createMediaUrl(`image`, image._path, options) : undefined,
    videoLandscapeUrl:
      videoLandscape != null
        ? createMediaUrl(`content`, videoLandscape._path, options)
        : undefined,
    videoPortraitUrl:
      videoPortrait != null
        ? createMediaUrl(`content`, videoPortrait._path, options)
        : undefined,
    altText,
    legalDisclaimers: legalDisclaimers
      ? createLegalDisclaimerContents(
          legalDisclaimers,
          options as CreateMediaUrlOptions,
        )
      : [],
    mboxName,
  };
}
