// "This is a workaround for a bug to get the demo running.
// TODO: replace with real implementation when the bug is fixed."
// Source: https://codesandbox.io/s/ypcz33?file=/Albums.js:545-1187

export type StatefulPromise<TValue> = PromiseLike<TValue> & {
  isPending?: boolean;
  value?: TValue;
  reason?: unknown;
};

export function use<TValue>(promise: StatefulPromise<TValue>): TValue {
  if (promise.value) {
    return promise.value;
  } else if (promise.reason) {
    throw promise.reason;
  } else if (promise.isPending) {
    throw promise;
  } else {
    promise.isPending = true;

    promise.then(
      (value) => {
        promise.value = value;
      },
      (reason) => {
        promise.reason = reason;
      },
    );

    throw promise;
  }
}
