import type {LocaleV3} from '@smart/locale-service';
import {useEffect} from 'react';
import {
  isValidInterruptionEndPath,
  isValidInterruptionStartPath,
} from '../utils';
import {getInterruptionPage} from '../utils/get-interruption-page';
import {wasCheckoutJourneyInterruptedByPreviousPage} from '../utils/was-checkout-journey-interrupted-by-previous-page';
import type {HistoryPath} from './use-history-path';

type UseInterruptionCallback = {
  setHasPopupBeenDisplayed: (value: boolean) => void;
  setInterruptionPageName: (value: string) => void;
  path: HistoryPath;
  locale: LocaleV3;
};
export const useInterruptionCallback = ({
  setHasPopupBeenDisplayed,
  setInterruptionPageName,
  path,
  locale,
}: UseInterruptionCallback) => {
  useEffect(() => {
    const wasJourneyInterrupted =
      isValidInterruptionStartPath(path.previousPath, locale) &&
      isValidInterruptionEndPath(path.currentPath, locale);

    const wasJourneyInterruptedByBackButton =
      isValidInterruptionStartPath(path.previousPath, locale) &&
      path.action === `POP`;

    const wasJourneyInterruptedByGoingToPreviousPageInCheckout =
      wasCheckoutJourneyInterruptedByPreviousPage(
        `${path.previousPath}${safeDecodeURIComponent(
          path.previousLocationSearch,
        )}`,
        `${path.currentPath}${safeDecodeURIComponent(
          path.currentLocationSearch,
        )}`,
        locale,
      );

    const interruptedPageName = getInterruptionPage({
      interruptionType: `interruption`,
      locale,
      path,
    });

    if (
      wasJourneyInterrupted ||
      wasJourneyInterruptedByBackButton ||
      wasJourneyInterruptedByGoingToPreviousPageInCheckout
    ) {
      setHasPopupBeenDisplayed(true);
      setInterruptionPageName(interruptedPageName);
      return;
    }
    setHasPopupBeenDisplayed(false);
  }, [path]);
};

function safeDecodeURIComponent(value: string) {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    return null;
  }
}
