import {HeadlineWithText} from '@smart/editorial-components';
import type {HeadlineWithTextContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';

export interface HeadlineWithTextWrapperProps {
  readonly content: HeadlineWithTextContent;
}

export const HeadlineWithTextWrapper = React.memo(
  ({content}: HeadlineWithTextWrapperProps) => {
    const link = useLink(content.link);

    return (
      <HeadlineWithText
        headline={content.headline}
        highlight={content.highlight}
        copyText={content.copyText}
        link={link}
        indicatorBar={content.indicatorBar}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

HeadlineWithTextWrapper.displayName = `HeadlineWithTextWrapper`;
