import React from 'react';
import {useFeatureServices} from '../../../hooks/use-feature-services';

export function useLocale() {
  const {localeService} = useFeatureServices();
  const [locale, setLocale] = React.useState(localeService.getLocale());

  React.useEffect(() => localeService.subscribe(setLocale), [localeService]);

  return locale;
}
