import type {FeatureAppContent} from '@smart/website-extra-language-contents';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {FeatureAppLoader} from './feature-app-loader';

export interface BottomBarFeatureAppLoaderProps {
  readonly content: FeatureAppContent;
}

export const BottomBarFeatureAppLoader = React.memo(
  ({content}: BottomBarFeatureAppLoaderProps) => {
    const {selectedVehicleService} = useFeatureServices();

    const [loadBottomBar, setLoadBottomBar] = React.useState(
      selectedVehicleService.getSelectedVehicle() !== undefined,
    );

    React.useEffect(() => {
      if (loadBottomBar) {
        return;
      }

      return selectedVehicleService.subscribe(() => setLoadBottomBar(true));
    }, [loadBottomBar]);

    const {baseUrl, src} = content;

    return loadBottomBar ? (
      <FeatureAppLoader featureAppId="bottom-bar" baseUrl={baseUrl} src={src} />
    ) : null;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

BottomBarFeatureAppLoader.displayName = `BottomBarFeatureAppLoader`;
