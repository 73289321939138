import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface ExtendedFlexibleGalleryItemContent {
  readonly contentType: 'image' | 'video';
  readonly imageUrl?: string;
  readonly videoLandscapeUrl?: string;
  readonly videoPortraitUrl?: string;
  readonly videoPosterImageUrl?: string;
  readonly mediaAltText?: string;
  readonly headline?: string;
  readonly copyText?: string;
  readonly link?: LinkContent;
  readonly itemId: string;
}

export interface ExtendedFlexibleGalleryContent {
  readonly type: 'extended-flexible-gallery';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly variant: 'highlight' | 'playful' | 'ample';
  readonly componentHeadline?: string;
  readonly items: ExtendedFlexibleGalleryItemContent[];
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName?: string;
  readonly modeSelector?: modeType;
}

const ExtendedFlexibleGalleryItemContentFragment = z.object({
  image: z.object({_path: z.string()}).nullish(),
  videoLandscape: z.object({_path: z.string()}).nullish(),
  videoPortrait: z.object({_path: z.string()}).nullish(),
  videoPosterImage: z.object({_path: z.string()}).nullish(),
  mediaAltText: z.string().nullish(),
  headline: z.string().nullish(),
  copyText: z.object({markdown: z.string().nullish()}).nullish(),
  linkLabel: z.string().nullish(),
  linkDestination: LinkDestination.nullish(),
});

export const ExtendedFlexibleGalleryContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  variant: z.string(),
  componentHeadline: z.string().nullish(),
  items: z.array(ExtendedFlexibleGalleryItemContentFragment),
  legalDisclaimer: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
  modeSelector: z.string().nullish(),
});

export function createExtendedFlexibleGalleryContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'ExtendedFlexibleGalleryModel'}
  >,
  options: CreateMediaUrlOptions,
): ExtendedFlexibleGalleryContent {
  const {
    removeTopSpacing,
    variant,
    componentHeadline,
    items,
    legalDisclaimer,
    modeSelector,
  } = sanitizeGraphqlData(
    ExtendedFlexibleGalleryContentFragment,
    contentFragment,
  );
  return {
    type: `extended-flexible-gallery`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    removeTopSpacing,
    variant: variant as ExtendedFlexibleGalleryContent['variant'],
    componentHeadline,
    items: createExtendedFlexibleGalleryContents(items, options),
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    modeSelector:
      modeSelector as ExtendedFlexibleGalleryContent['modeSelector'],
  };
}

function createExtendedFlexibleGalleryContents(
  items: readonly DeepExcludeNull<
    z.TypeOf<typeof ExtendedFlexibleGalleryItemContentFragment>
  >[],
  options: CreateMediaUrlOptions,
): ExtendedFlexibleGalleryItemContent[] {
  return items.map((item) => {
    const {
      image,
      videoLandscape,
      videoPortrait,
      videoPosterImage,
      mediaAltText,
      headline,
      copyText,
      linkLabel,
      linkDestination,
    } = item;
    return {
      contentType: image != null ? `image` : `video`,
      imageUrl:
        image != null
          ? createMediaUrl(`image`, image._path, options)
          : undefined,
      videoLandscapeUrl:
        videoLandscape != null
          ? createMediaUrl(`content`, videoLandscape._path, options)
          : undefined,
      videoPortraitUrl:
        videoPortrait != null
          ? createMediaUrl(`content`, videoPortrait._path, options)
          : undefined,
      videoPosterImageUrl:
        videoPosterImage != null
          ? createMediaUrl(`image`, videoPosterImage._path, options)
          : undefined,
      mediaAltText,
      headline,
      copyText: copyText?.markdown,
      link: createLinkContent({
        destination: linkDestination,
        label: linkLabel,
      }),
      itemId: createId(item),
    };
  });
}
