import type {LocaleV3} from '@smart/locale-service';
import {useEffect, useState} from 'react';
import {isValidInterruptionStartPath} from '../utils';
import {getInterruptionPage} from '../utils/get-interruption-page';
import type {HistoryPath} from './use-history-path';

type UseInactivityCallback = {
  setHasPopupBeenDisplayed: (value: boolean) => void;
  setInterruptionPageName: (value: string) => void;
  setIsUserInactive: (value: boolean) => void;
  path: HistoryPath;
  timeout: number;
  locale: LocaleV3;
  enabled: boolean;
};

export const useInactivityCallback = ({
  setHasPopupBeenDisplayed,
  setInterruptionPageName,
  setIsUserInactive,
  path,
  timeout,
  locale,
  enabled,
}: UseInactivityCallback) => {
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    if (
      !enabled ||
      hasTriggered ||
      !isValidInterruptionStartPath(path.currentPath, locale)
    ) {
      return;
    }

    let timer: number;
    const resetTimer = () => {
      clearTimeout(timer);
      timer = window.setTimeout(() => {
        setHasPopupBeenDisplayed(true);
        setIsUserInactive(true);
        setHasTriggered(true);
        const interruptedPageName = getInterruptionPage({
          interruptionType: `inactivity`,
          locale,
          path,
        });
        setInterruptionPageName(interruptedPageName);
      }, timeout);
    };

    window.addEventListener(`mousemove`, resetTimer);
    window.addEventListener(`scroll`, resetTimer);
    window.addEventListener(`keydown`, resetTimer);

    resetTimer();

    return () => {
      clearTimeout(timer);
      window.removeEventListener(`mousemove`, resetTimer);
      window.removeEventListener(`scroll`, resetTimer);
      window.removeEventListener(`keydown`, resetTimer);
    };
  }, [path, timeout]);
};
