import * as React from 'react';
import {useFeatureServices} from './use-feature-services';

export function useIsLoggedIn(): boolean {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const {loginService} = useFeatureServices();

  React.useEffect(() => {
    if (loginService?.loginState.status === `logged-in`) {
      setIsLoggedIn(true);
    }
  }, []);

  return isLoggedIn;
}
