import type {ProductTeaserCardType} from '@smart/editorial-components';
import {ProductTeaserCard} from '@smart/editorial-components';
import type {ProductTeaserCardContent} from '@smart/website-aem-utils';
import {
  createLegalDisclaimerProps,
  createLinkProps,
} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {PageModelContext} from './page';

export interface ProductTeaserCardWrapperProps {
  readonly content: ProductTeaserCardContent;
}

export const ProductTeaserCardWrapper = React.memo(
  ({content}: ProductTeaserCardWrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const {endpointDirectory, loginService, historyService, pubSubService} =
      useFeatureServices();

    const props: ProductTeaserCardType = {
      teaserList: content.teaserList.map(
        ({
          image,
          headline,
          title,
          subTitle,
          highlightValue1,
          highlightLabel1,
          highlightValue2,
          highlightLabel2,
          highlightValue3,
          highlightLabel3,
          richText,
          firstLink,
          secondLink,
          price,
          leasingPrice,
          renderSubscribePopupButton,
          subscribePopupButtonText,
        }) => ({
          image,
          headline,
          title: title || ``,
          subTitle,
          highlightValue1,
          highlightLabel1,
          highlightValue2,
          highlightLabel2,
          highlightValue3,
          highlightLabel3,
          richText,
          firstLink:
            firstLink &&
            createLinkProps({
              historyService,
              content: firstLink,
              websiteTrackingData: {
                type: `navigation`,
                region: `teaser_row_product`,
                name: `${firstLink.label}.${title}`,
              },
              envName: endpointDirectory.envName,
              loginState: loginService?.loginState,
              languageTag,
              marketId,
            }),
          secondLink:
            secondLink &&
            createLinkProps({
              historyService,
              content: secondLink,
              websiteTrackingData: {
                type: `navigation`,
                region: `teaser_row_product`,
                name: `${secondLink.label}.${title}`,
              },
              envName: endpointDirectory.envName,
              loginState: loginService?.loginState,
              languageTag,
              marketId,
            }),
          price,
          leasingPrice,
          renderSubscribePopupButton,
          subscribePopupButtonText,
        }),
      ),
      legalDisclaimers: createLegalDisclaimerProps(
        content.legalDisclaimers,
        historyService,
      ) || [{legalText: ``}],
      removeTopSpacing: content.removeTopSpacing,
      aspectRatio: content.aspectRatio,
      publishFunc: pubSubService.publish,
    };
    return <ProductTeaserCard {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

ProductTeaserCardWrapper.displayName = `ProductTeaserCardWrapper`;
