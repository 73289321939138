import type {EnvNameV3} from '@smart/endpoint-directory';
import {CONFIGURATION} from '../constants';

export const getConfiguration = (envName: EnvNameV3, preview?: boolean) => {
  const env = envName === `prod` ? envName : `stage`;
  const isPreviewProd = preview === true && env === `prod`;

  const survey = CONFIGURATION.survey[env];
  const interruption = isPreviewProd
    ? CONFIGURATION.interruption.prod.preview
    : CONFIGURATION.interruption[env];

  return {
    survey: {id: survey.id, script: survey.script},
    interruption: {id: interruption.id, script: interruption.script},
  };
};
