import {z} from 'zod';

export const InternalPageLink = z.object({
  __typename: z.literal(`InternalPageModel`),
  navigationLabel: z.string(),
  queryParams: z.string().nullish(),
  linkDestination: z.object({
    _path: z.string(),
  }),
});
