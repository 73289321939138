import type {
  CreateMediaUrlOptions,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  ImageWithTransparencySupportContent,
  LegalDisclaimers,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface HeadlineWithImageContent {
  readonly type: 'headline-with-image';
  readonly id: string;
  readonly anchorId: string;
  readonly componentHeadline: string | undefined;
  readonly headline: string | undefined;
  readonly image: Image;
  readonly link: LinkContent | undefined;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly highlight: boolean | undefined;
  readonly indicatorBar: boolean | undefined;
  readonly mboxName: string | undefined;
}

const HeadlineWithImageContentFragment = z
  .object({
    componentHeadline: z.string().nullish(),
    headline: z.string().nullish(),
    linkDestination: LinkDestination.nullish(),
    linkLabel: z.string().nullish(),
    legalDisclaimer: LegalDisclaimers.nullish(),
    highlight: z.boolean().nullish(),
    indicatorBar: z.boolean().nullish(),
    mboxName: z.string().nullish(),
  })
  .and(ImageWithTransparencySupportContent);

export function createHeadlineWithImageContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'HeadlineWithImageModel'}
  >,
  options: CreateMediaUrlOptions,
): HeadlineWithImageContent {
  const {
    componentHeadline,
    headline,
    image,
    imageAltText,
    imageTransparencySupport,
    linkDestination,
    linkLabel,
    legalDisclaimer,
    highlight,
    indicatorBar,
    mboxName,
  } = sanitizeGraphqlData(HeadlineWithImageContentFragment, contentFragment);

  return {
    type: `headline-with-image`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    componentHeadline,
    headline,
    image: createImage(
      {image, imageAltText, imageTransparencySupport},
      options,
    ),
    link: createLinkContent({destination: linkDestination, label: linkLabel}),
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    highlight,
    indicatorBar,
    mboxName,
  };
}
