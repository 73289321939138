import {
  INTERRUPTION_SCRIPT_PREVIEW_PROD,
  INTERRUPTION_SCRIPT_PROD,
  INTERRUPTION_SCRIPT_STAGE,
  SURVEY_SCRIPT_PROD,
  SURVEY_SCRIPT_STAGE,
} from './scripts';

export const CONFIGURATION = {
  interruption: {
    stage: {
      script: INTERRUPTION_SCRIPT_STAGE,
      id: `ZN_2Vlof4LxIKKaybk`,
    },
    prod: {
      script: INTERRUPTION_SCRIPT_PROD,
      id: `ZN_efW3TGZUJP1XpwG`,
      preview: {
        script: INTERRUPTION_SCRIPT_PREVIEW_PROD,
        id: `ZN_9ThJfNr9UIUuwiB`,
      },
    },
  },
  survey: {
    stage: {
      script: SURVEY_SCRIPT_STAGE,
      id: `ZN_1FVOdDH2NjNmbZA`,
    },
    prod: {
      script: SURVEY_SCRIPT_PROD,
      id: `ZN_9uVa5xKJWrsoMRw`,
    },
  },
} as const;

const PATH = {
  CART: `cart`,
  CHECKOUT: `checkout`,
  DELIVERY_METHOD: `delivery-method`,
  ORDER_OVERVIEW: `order-overview`,
  PERSONAL_DETAILS: `personal-details`,
  PRODUCTS: `products`,
  SHOPPING_CART: `shopping-cart`,
} as const;

export const INTERRUPTION_START_PATHS: ReadonlyArray<string> = [
  `/${PATH.CHECKOUT}/`,
  `/${PATH.CHECKOUT}`,
  `/${PATH.CHECKOUT}/?---=%7B"${PATH.SHOPPING_CART}"%3A"%2F${PATH.PERSONAL_DETAILS}"%7D`,
  `/${PATH.CHECKOUT}/?---=%7B"${PATH.SHOPPING_CART}"%3A"%2F${PATH.PERSONAL_DETAILS}"%7D/`,
  `/${PATH.CHECKOUT}/?---=%7B"${PATH.SHOPPING_CART}"%3A"%2F${PATH.DELIVERY_METHOD}"%7D`,
  `/${PATH.CHECKOUT}/?---=%7B"${PATH.SHOPPING_CART}"%3A"%2F${PATH.DELIVERY_METHOD}"%7D/`,
  `/${PATH.CHECKOUT}/?---=%7B"${PATH.SHOPPING_CART}"%3A"%2F${PATH.ORDER_OVERVIEW}"%7D`,
  `/${PATH.CHECKOUT}/?---=%7B"${PATH.SHOPPING_CART}"%3A"%2F${PATH.ORDER_OVERVIEW}"%7D/`,
  `/${PATH.CART}/`,
  `/${PATH.CART}`,
];

export const INTERRUPTION_END_PATHS: ReadonlyArray<string> = [
  `/`,
  `/${PATH.PRODUCTS}/`,
  `/${PATH.PRODUCTS}`,
];

export const CHECKOUT_INTERRUPTION_PATH = {
  ORDER_OVERVIEW: {
    START: [
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.ORDER_OVERVIEW}"}`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.ORDER_OVERVIEW}"}/`,
    ],
    END: [
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.DELIVERY_METHOD}"}`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.DELIVERY_METHOD}"}/`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.PERSONAL_DETAILS}"}`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.PERSONAL_DETAILS}"}/`,
      `/${PATH.CHECKOUT}/`,
      `/${PATH.CHECKOUT}`,
    ],
  },
  DELIVERY_METHOD: {
    START: [
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.DELIVERY_METHOD}"}`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"{${PATH.DELIVERY_METHOD}"}/`,
    ],
    END: [
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.PERSONAL_DETAILS}"}`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.PERSONAL_DETAILS}"}/`,
      `/${PATH.CHECKOUT}/`,
      `/${PATH.CHECKOUT}`,
    ],
  },
  PERSONAL_DETAILS: {
    START: [
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.PERSONAL_DETAILS}"}`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/${PATH.PERSONAL_DETAILS}"}/`,
    ],
    END: [
      `/${PATH.CHECKOUT}/`,
      `/${PATH.CHECKOUT}`,
      `/${PATH.CHECKOUT}/?---={"${PATH.SHOPPING_CART}":"/"}`,
    ],
  },
};

const ONE_MINUTE_MS = 60 * 1000;

export const INACTIVITY_TIMEOUT_MS = 3 * ONE_MINUTE_MS;
