import type {GarageDetails} from '@smart/website-garage-details';
import * as React from 'react';
import {useFeatureServices} from './use-feature-services';

export interface UseGarageDetailsOptions {
  readonly enabled: boolean;
}

export function useGarageDetails({
  enabled,
}: UseGarageDetailsOptions): GarageDetails | undefined {
  const [garageDetails, setGarageDetails] = React.useState<GarageDetails>();
  const {logger, loginService, pubSubService} = useFeatureServices();
  const fetchAbortControllerRef = React.useRef<AbortController>();

  React.useEffect(() => {
    if (!enabled || loginService?.loginState.status !== `logged-in`) {
      return;
    }

    const fetchGarageDetails = () => {
      fetchAbortControllerRef.current?.abort();

      const fetchAbortController = (fetchAbortControllerRef.current =
        new AbortController());

      fetch(`${process.env.PRIVATE_BASE_URL}/garage-details`, {
        signal: fetchAbortController.signal,
      })
        .then(async (response) => {
          if (response.ok) {
            setGarageDetails(await response.json());
          } else if (response.status === 401) {
            logger.warn(`The user has an invalid session and is logged out.`);
            loginService.logout();
          } else {
            logger.warn(`Unable to fetch garage details.`);
          }
        })
        .catch((error) => {
          if (!fetchAbortController.signal.aborted) {
            logger.error(`Unable to fetch garage details.`, error);
          }
        });
    };

    fetchGarageDetails();

    const subscribeAbortController = new AbortController();

    pubSubService.subscribe(`garage-update`, fetchGarageDetails, {
      signal: subscribeAbortController.signal,
    });

    return () => {
      subscribeAbortController.abort();
      fetchAbortControllerRef.current?.abort();
    };
  }, [enabled]);

  return garageDetails;
}
