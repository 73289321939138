import * as React from 'react';
import {getChatbot} from '../utils/client/get-chatbot';
import {useFeatureServices} from './use-feature-services';

export function useChatbotCustomerData(): void {
  const {adbContextService, logger, loginService, pubSubService} =
    useFeatureServices();

  const fetchAbortControllerRef = React.useRef<AbortController>();

  React.useEffect(() => {
    if (
      loginService?.loginState.status !== `logged-in` ||
      adbContextService?.adbContext
    ) {
      return;
    }

    const fetchChatBotCustomerData = () => {
      fetchAbortControllerRef.current?.abort();

      const abortController = (fetchAbortControllerRef.current =
        new AbortController());

      logger.debug(`Fetching chatbot customer data.`);

      fetch(`${process.env.PRIVATE_BASE_URL}/chatbot-customer-data`, {
        signal: abortController.signal,
      })
        .then(async (response) => {
          if (response.ok) {
            logger.debug(`Setting chatbot customer data.`);

            getChatbot().push({
              action: `setVariables`,
              args: await response.json(),
            });
          } else if (response.status === 401) {
            logger.warn(`The user has an invalid session and is logged out.`);
            loginService.logout();
          } else if (response.status === 403) {
            logger.warn(`Got malformed ecom customer data.`);
          } else {
            logger.warn(`Unable to fetch chatbot customer data.`);
          }
        })
        .catch((error) => {
          if (!abortController.signal.aborted) {
            logger.error(`Unable to fetch chatbot customer data.`, error);
          }
        });
    };

    fetchChatBotCustomerData();

    const subscribeAbortController = new AbortController();

    pubSubService.subscribe(`cart-update`, fetchChatBotCustomerData, {
      signal: subscribeAbortController.signal,
    });

    return () => {
      subscribeAbortController.abort();
      fetchAbortControllerRef.current?.abort();
    };
  }, []);
}
