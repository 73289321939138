import * as React from 'react';

declare global {
  var _satellite: {getVar?(name: string): unknown} | undefined;
}

interface OneTrustConsent {
  readonly adobeTarget?: boolean;
}

export function useEcidForPersonalization(): string | undefined {
  return React.useSyncExternalStore<string | undefined>(
    (listener) => {
      const abortController = new AbortController();

      window.addEventListener(`onetrustConsentMappingChange`, listener, {
        signal: abortController.signal,
      });

      return () => abortController.abort();
    },
    () => {
      const ecid = window._satellite?.getVar?.(`ECID`);
      const consent = window._satellite?.getVar?.(`onetrustConsent`);

      if (typeof ecid !== `string` || !isOnetrustConsentObject(consent)) {
        return undefined;
      }

      return consent.adobeTarget ? ecid : undefined;
    },
    () => undefined,
  );
}

function isOnetrustConsentObject(value: unknown): value is OneTrustConsent {
  return value !== null && typeof value === `object`;
}
