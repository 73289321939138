import type {
  CreateMediaUrlOptions,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface TextWithMediaContent {
  readonly type: 'text-with-media';
  readonly id: string;
  readonly alignment: 'left' | 'right';
  readonly anchorId: string;
  readonly legalText?: string;
  readonly headline?: string;
  readonly contentText?: string;
  readonly imageUrl?: string;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly videoLandscapeUrl?: string;
  readonly videoPortraitUrl?: string;
  readonly videoPosterImageUrl?: string;
  readonly mediaAltText?: string;
  readonly useLimeGreen?: boolean;
  readonly removeTopSpacing?: boolean;
  readonly primaryLink?: LinkContent;
  readonly secondaryLink?: LinkContent;
  readonly logos?: string[];
  readonly mboxName?: string;
  readonly modeSelector?: modeType;
}

export const TextWithMediaContentFragment = z.object({
  alignment: z.string(),
  image: z.object({_path: z.string()}).nullish(),
  videoLandscape: z.object({_path: z.string()}).nullish(),
  videoPortrait: z.object({_path: z.string()}).nullish(),
  videoPosterImage: z.object({_path: z.string()}).nullish(),
  mediaAltText: z.string().nullish(),
  headline: z.string().nullish(),
  contentText: z.object({markdown: z.string().nullish()}).nullish(),
  legalDisclaimer: LegalDisclaimers.nullish(),
  useLimeGreen: z.boolean().nullish(),
  removeTopSpacing: z.boolean().nullish(),
  primaryLinkLabel: z.string().nullish(),
  primaryLinkDestination: LinkDestination.nullish(),
  secondaryLinkLabel: z.string().nullish(),
  secondaryLinkDestination: LinkDestination.nullish(),
  logos: z.array(z.object({_path: z.string()})).nullish(),
  mboxName: z.string().nullish(),
  modeSelector: z.string().nullish(),
});

export function createTextWithMediaContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'TextWithMediaModel'}
  >,
  options: CreateMediaUrlOptions,
): TextWithMediaContent {
  const {
    alignment,
    headline,
    contentText,
    image,
    videoLandscape,
    videoPortrait,
    videoPosterImage,
    mediaAltText,
    useLimeGreen,
    removeTopSpacing,
    primaryLinkLabel,
    primaryLinkDestination,
    secondaryLinkLabel,
    secondaryLinkDestination,
    logos,
    legalDisclaimer,
    mboxName,
    modeSelector,
  } = sanitizeGraphqlData(TextWithMediaContentFragment, contentFragment);
  return {
    type: `text-with-media`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    alignment: alignment as TextWithMediaContent['alignment'],
    imageUrl:
      image != null ? createMediaUrl(`image`, image._path, options) : undefined,
    videoLandscapeUrl:
      videoLandscape != null
        ? createMediaUrl(`content`, videoLandscape._path, options)
        : undefined,
    videoPortraitUrl:
      videoPortrait != null
        ? createMediaUrl(`content`, videoPortrait._path, options)
        : undefined,
    videoPosterImageUrl:
      videoPosterImage != null
        ? createMediaUrl(`image`, videoPosterImage._path, options)
        : undefined,
    mediaAltText,
    headline,
    contentText: contentText?.markdown,
    useLimeGreen,
    removeTopSpacing,
    primaryLink: createLinkContent({
      destination: primaryLinkDestination,
      label: primaryLinkLabel,
    }),
    secondaryLink: createLinkContent({
      destination: secondaryLinkDestination,
      label: secondaryLinkLabel,
    }),
    logos:
      logos && logos.length > 0
        ? logos.map((l) => createMediaUrl(`image`, l._path, options))
        : [],
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
    modeSelector: modeSelector as TextWithMediaContent['modeSelector'],
  };
}
