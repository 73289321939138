import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
} from 'react';

interface LanguageContextProps {
  readonly selectedLanguage: string;
  readonly setSelectedLanguage: Dispatch<SetStateAction<string>>;
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined,
);

export function useLanguageContext() {
  const languageContext = useContext(LanguageContext);

  if (!languageContext) {
    throw new Error(`LanguageContext is missing`);
  }

  const {selectedLanguage, setSelectedLanguage} = languageContext;

  return {selectedLanguage, setSelectedLanguage};
}
