import type {EnvNameV3} from '@smart/endpoint-directory';

export const adobeLaunchScriptUrls: Record<EnvNameV3, string> = {
  dev: `https://assets.adobedtm.com/2c8f96943be2/96643b245f76/launch-296f3e6a06c3-development.min.js`,
  sit: `https://assets.adobedtm.com/2c8f96943be2/96643b245f76/launch-296f3e6a06c3-development.min.js`,
  stage: `https://assets.adobedtm.com/2c8f96943be2/96643b245f76/launch-1ede71972e1f-staging.min.js`,
  prod: `https://assets.adobedtm.com/2c8f96943be2/96643b245f76/launch-4011d81f12cc.min.js`,
};

export const globalGatewayPageUrls: Record<EnvNameV3, string> = {
  dev: `https://dev.global.srv.smart.com`,
  sit: `https://dev.global.srv.smart.com`,
  stage: `https://stage.global.srv.smart.com`,
  prod: `https://global.smart.com`,
};

const cookieProDomainId = `b5cf8f07-4459-412d-820d-c7e419f4671a`;

export const cookieProDomainIds: Record<EnvNameV3, string> = {
  dev: `${cookieProDomainId}-test`,
  sit: `${cookieProDomainId}-test`,
  stage: cookieProDomainId,
  prod: cookieProDomainId,
};

export const cookieProMetaDataUrls: Record<EnvNameV3, string> = {
  dev: `https://cdn.cookielaw.org/consent/${cookieProDomainIds.dev}/${cookieProDomainIds.dev}.json`,
  sit: `https://cdn.cookielaw.org/consent/${cookieProDomainIds.dev}/${cookieProDomainIds.dev}.json`,
  stage: `https://cdn.cookielaw.org/consent/${cookieProDomainIds.stage}/${cookieProDomainIds.stage}.json`,
  prod: `https://cdn.cookielaw.org/consent/${cookieProDomainIds.prod}/${cookieProDomainIds.prod}.json`,
};

export const chatbotApiHostnames: Record<EnvNameV3, string> = {
  dev: `api.uat.seamly-app.com`,
  sit: `api.uat.seamly-app.com`,
  stage: `api.uat.seamly-app.com`,
  prod: `api.seamly-app.com`,
};

export const awsRumApplicationIds: Record<EnvNameV3, string> = {
  dev: `55ceb26f-ccf5-4e36-87a1-e1927ddc24a9`,
  sit: `f723e1c7-4b75-4e09-a0cc-040137cac435`,
  stage: `c8f6bb19-fff8-4b1b-afc5-c69f01fc3905`,
  prod: `d0f2a091-cff7-4502-b8a2-3839e0f0fdea`,
};
