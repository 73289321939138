import type {EnvNameV3} from '@smart/endpoint-directory';
import type {LoginStateV3} from '@smart/login-service';
import type {LinkType} from '@smart/web-components';
import type {LinkContent} from '@smart/website-aem-utils';
import {createLinkProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from './use-feature-services';

type useLinksOptions = {
  readonly websiteTrackingData?: {
    type: string;
    region: string;
  };
  readonly envName?: EnvNameV3;
  readonly loginState?: LoginStateV3;
  readonly languageTag?: string;
  readonly marketId?: string;
};

export function useLinks(
  contents: readonly LinkContent[],
  useLinksOptions?: useLinksOptions,
): LinkType[] {
  const {historyService} = useFeatureServices();

  return React.useMemo<LinkType[]>(
    () =>
      contents.map((content) =>
        createLinkProps({
          historyService,
          content,
          websiteTrackingData:
            useLinksOptions && useLinksOptions.websiteTrackingData
              ? {...useLinksOptions.websiteTrackingData, name: content.label}
              : undefined,
          envName: useLinksOptions?.envName,
          loginState: useLinksOptions?.loginState,
          languageTag: useLinksOptions?.languageTag,
          marketId: useLinksOptions?.marketId,
        }),
      ),
    [contents],
  );
}
