import {getLanguage} from '@smart/locale-service';

const localeByLanguage = new Map<string, string>([
  [`de`, `de-informal`],
  [`es`, `es-informal`],
  [`fr`, `fr`],
  [`it`, `it`],
  [`nl`, `nl-informal`],
  [`pt`, `pt-PT`],
  [`sv`, `sv`],
]);

export function getChatLocale(languageTag: string): string {
  return localeByLanguage.get(getLanguage(languageTag)) || `en-GB`;
}
