import type {FeatureHubContextConsumerValue} from '@feature-hub/react';
import {FeatureHubContext} from '@feature-hub/react';
import * as React from 'react';

export function useFeatureHubContext(): FeatureHubContextConsumerValue {
  const context = React.useContext(FeatureHubContext);

  if (!context) {
    throw new Error(`No Feature Hub context was provided.`);
  }

  return {logger: console, ...context};
}
