import classnames from 'classnames';
import * as React from 'react';
import {hasHTMLDialogElementSupport} from '../utils/client/has-html-dialog-element-support';
import styles from './dialog.module.scss';

export type DialogProps = React.PropsWithChildren<{
  readonly className?: string;
  readonly id?: string;
  readonly ariaLabel?: string;
  readonly onClose?: () => void;
}>;

export interface DialogRef {
  showModal(): void;
  close(): void;
}

const loadPolyfill = async () => {
  if (hasHTMLDialogElementSupport()) return Promise.resolve();
  return import(`dialog-polyfill`);
};

export const Dialog = React.forwardRef<DialogRef, DialogProps>(
  ({children, className, id, ariaLabel, onClose}, ref) => {
    const dialogElementRef = React.useRef<HTMLDialogElement>(null);
    const [isPolyfillReady, setPolyfillReady] = React.useState(false);

    React.useImperativeHandle<DialogRef, DialogRef>(
      ref,
      () => ({
        showModal: () => dialogElementRef.current?.showModal(),
        close: () => dialogElementRef.current?.close(),
      }),
      [],
    );

    React.useEffect(() => {
      if (!dialogElementRef.current || !onClose) {
        return;
      }

      const abortController = new AbortController();

      dialogElementRef.current.addEventListener(`close`, onClose, {
        signal: abortController.signal,
      });

      return () => abortController.abort();
    }, [onClose]);

    React.useEffect(() => {
      const self = dialogElementRef.current;
      if (isPolyfillReady || !self) return;
      let isSubscribed = true;
      loadPolyfill()
        .then((polyfill) => {
          if (!polyfill) return;
          polyfill.default.registerDialog(self);
        })
        .catch((err) => {
          console.warn(`dialog-polyfill could not be loaded`, err);
        })
        .finally(() => {
          if (isSubscribed) setPolyfillReady(true);
        });
      return () => {
        isSubscribed = false;
      };
    }, [dialogElementRef.current, isPolyfillReady]);

    return (
      <dialog
        className={classnames(styles.dialog, className)}
        id={id}
        aria-label={ariaLabel}
        ref={dialogElementRef}
        data-nosnippet="true"
      >
        <div className={styles.main}>{children}</div>
      </dialog>
    );
  },
);
