import type {InStageNavigationItemType} from '@smart/editorial-components';
import type {AddOnContent} from '@smart/website-aem-utils/src/create-add-on-content';
import {useLinks} from './use-links';

export interface UseInStageNavigationItemsOptions {
  readonly addOn: AddOnContent | undefined;
  readonly pathname: string;
}

export function useInStageNavigationItems({
  addOn,
  pathname,
}: UseInStageNavigationItemsOptions): InStageNavigationItemType[] | undefined {
  const inStageNavigationLinks: InStageNavigationItemType[] = useLinks(
    addOn?.type === `in-stage-navigation` ? addOn.links : [],
  ).map((inStageNavigationLink) => ({
    ...inStageNavigationLink,
    isActive: inStageNavigationLink.href === pathname,
  }));

  return inStageNavigationLinks.length === 0
    ? undefined
    : inStageNavigationLinks;
}
