import {useInitialIntersection} from '@smart/web-components';
import * as React from 'react';

export type LazyContainerProps = React.PropsWithChildren<{
  readonly initiallyVisible?: boolean;
}>;

export function LazyContainer({
  children,
  initiallyVisible = false,
}: LazyContainerProps): JSX.Element {
  const [visible, setVisible] = React.useState(initiallyVisible);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const handleIntersection = React.useCallback(() => setVisible(true), []);

  useInitialIntersection(containerRef, handleIntersection, `100%`);

  return <div ref={containerRef}>{visible ? children : undefined}</div>;
}
