import type {
  CreateMediaUrlOptions,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
export interface TableContent {
  readonly type: 'table';
  readonly id: string;
  readonly anchorId: string;
  readonly mode?: string;
  readonly layoutAlign?: '100' | '2-3' | '50-50';
  readonly headline?: string;
  readonly subline?: string;
  readonly hasHeaderRow?: boolean;
  readonly hasHeaderColumn?: boolean;
  readonly removeTopSpacing?: boolean;
  readonly headerColumn: string[];
  readonly dataSource: string[][];
  readonly disclaimers?: LegalDisclaimerContent[];
  readonly link?: LinkContent | undefined;
  readonly mboxName?: string | undefined;
  readonly headerRow: string[];
}

const TableContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  mode: z.string().nullish(),
  layoutAlign: z.string().nullish(),
  subline: z.string().nullish(),
  headline: z.string().nullish(),
  hasHeaderRow: z.boolean().nullish(),
  hasHeaderColumn: z.boolean().nullish(),
  headerRow: z.array(z.string()).nullish(),
  headerColumn: z.array(z.string()).nullish(),
  dataSource: z.array(z.array(z.string())).nullish(),
  disclaimers: LegalDisclaimers.nullish(),
  linkLabel: z.string().nullish(),
  linkDestination: LinkDestination.nullish(),
  mboxName: z.string().nullish(),
});

export function createTableContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'TableModel'}>,
  options: CreateMediaUrlOptions,
): TableContent {
  const {
    removeTopSpacing,
    mode,
    layoutAlign,
    subline,
    headline,
    hasHeaderRow,
    hasHeaderColumn,
    headerRow,
    headerColumn,
    dataSource,
    disclaimers,
    linkLabel,
    linkDestination,
    mboxName,
  } = sanitizeGraphqlData(TableContentFragment, contentFragment);

  return {
    type: `table`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    removeTopSpacing,
    mode,
    layoutAlign: layoutAlign as TableContent['layoutAlign'],
    subline,
    headline,
    hasHeaderRow,
    hasHeaderColumn,
    headerRow: headerRow || [],
    headerColumn: headerColumn || [],
    dataSource: dataSource || [],
    disclaimers: createLegalDisclaimerContents(disclaimers || [], options),
    link: createLinkContent({
      destination: linkDestination,
      label: linkLabel,
    }),
    mboxName,
  };
}
