import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {IntegratorContext} from '../contexts/integrator-context';
import {isFeatureAppDefined} from '../utils/universal/is-feature-app-defined';

export function useUpdateIntegratorContext(pageModel: PageModel): void {
  const {updateLocale, updatePageContext} = React.useContext(IntegratorContext);

  React.useEffect(() => {
    updateLocale(pageModel.locale);

    updatePageContext({
      isCustomizerFeatureAppDefined: isFeatureAppDefined(
        pageModel,
        `CustomizerFeatureAppModel`,
      ),
    });
  }, [pageModel]);
}
