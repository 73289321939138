import type {FeatureAppModelType} from '@smart/website-aem-utils';
import type {PageModel} from '@smart/website-page-model';

export function isFeatureAppDefined(
  pageModel: PageModel,
  modelType: FeatureAppModelType,
): boolean {
  return pageModel.contents.some(
    (content) =>
      content.type === `feature-app` && content.modelType === modelType,
  );
}
