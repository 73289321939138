import type {Logger} from '@feature-hub/core';
import type {HistoryServiceV3} from '@feature-hub/history-service';
import type {AdbContextServiceV2} from '@smart/adb-context-service';
import type {CartUpdateServiceV1} from '@smart/cart-update-service';
import type {EndpointDirectoryV3} from '@smart/endpoint-directory';
import type {LocaleServiceV3} from '@smart/locale-service';
import type {LoginServiceV3} from '@smart/login-service';
import type {PageContextServiceV1} from '@smart/page-context-service';
import type {PaymentConfigServiceV1} from '@smart/payment-config-service';
import type {PricePreferencesProviderV1} from '@smart/price-preferences-provider';
import type {PubSubServiceV1} from '@smart/pub-sub-service';
import type {SelectedVehicleServiceV1} from '@smart/selected-vehicle-service';
import * as React from 'react';

export interface FeatureServicesContextValue {
  readonly adbContextService?: AdbContextServiceV2;
  readonly cartUpdateService: CartUpdateServiceV1;
  readonly endpointDirectory: EndpointDirectoryV3;
  readonly historyService: HistoryServiceV3;
  readonly localeService: LocaleServiceV3;
  readonly logger: Logger;
  readonly loginService?: LoginServiceV3;
  readonly pageContextService: PageContextServiceV1;
  readonly paymentConfigService?: PaymentConfigServiceV1;
  readonly pricePreferencesProvider: PricePreferencesProviderV1;
  readonly pubSubService: PubSubServiceV1;
  readonly selectedVehicleService: SelectedVehicleServiceV1;
}

export const FeatureServicesContext = React.createContext<
  FeatureServicesContextValue | undefined
>(undefined);

export function useFeatureServices(): FeatureServicesContextValue {
  const featureServices = React.useContext(FeatureServicesContext);

  if (!featureServices) {
    throw new Error(`FeatureServicesContext provider is missing.`);
  }

  return featureServices;
}
