import type {ChatbotCustomerData} from '@smart/website-chatbot-customer-data';

export type ChatAction =
  | InitChatAction
  | SetVisibilityChatAction
  | OnChatAction
  | SetVariablesAction;

export interface InitChatAction {
  readonly action: 'init';
  readonly args: {
    readonly layoutMode: 'window';
    readonly namespace: string;
    readonly showDisclaimer: boolean;
    readonly visibilty: Visibilty;
    readonly context?: ChatbotVariables;
  };
}

export type Visibilty = 'open' | 'minimized' | 'hidden';

export interface SetVisibilityChatAction {
  readonly action: `setVisibility`;
  readonly args: Visibilty;
}

export interface OnChatAction {
  readonly action: `on`;
  readonly args: readonly ['ui.beforeStart', () => void];
}

export interface SetVariablesAction {
  readonly action: 'setVariables';
  readonly args: ChatbotVariables;
}

export type ChatbotVariables = {
  readonly market?: string;
  readonly locale?: string; // Expected format: Language Tags (BCP 47) e.g., en-GB
} & ChatbotCustomerData;

export function getChatbot(): ChatAction[] {
  return ((window as any).seamly = (window as any).seamly || []);
}
