export async function waitForInteractive(): Promise<void> {
  if (document.readyState === `interactive`) {
    return;
  }

  return new Promise((resolve) => {
    document.addEventListener(`readystatechange`, () => {
      if (document.readyState === `interactive`) {
        resolve();
      }
    });
  });
}
