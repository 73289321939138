import type {
  ExtendedFlexibleGalleryContent,
  HeadlineV2Content,
  MediaStageContent,
  RichtextContent,
  StatementContent,
  TeaserRowV2Content,
  TextParagraphContent,
  TextWithMediaContent,
} from '@smart/website-aem-utils';
import React from 'react';
import {ExtendedFlexibleGalleryWrapper} from '../../components/extended-flexible-gallery-wrapper';
import {HeadlineV2Wrapper} from '../../components/headline-v2-wrapper';
import {MediaStageWrapper} from '../../components/media-stage-wrapper';
import {RichtextWrapper} from '../../components/richtext-wrapper';
import {StatementWrapper} from '../../components/statement-wrapper';
import {TeaserRowV2Wrapper} from '../../components/teaser-row-v2-wrapper';
import {TextParagraphWrapper} from '../../components/text-paragraph-wrapper';
import {TextWithMediaWrapper} from '../../components/text-with-media-wrapper';

export interface AccordionContent {
  readonly components: (
    | {
        type: 'extendedFlexibleGallery';
        content: ExtendedFlexibleGalleryContent;
      }
    | {type: 'teaserRowV2'; content: TeaserRowV2Content}
    | {type: 'statement'; content: StatementContent}
    | {type: 'textWithMedia'; content: TextWithMediaContent}
    | {type: 'mediaStage'; content: MediaStageContent}
    | {type: 'richtext'; content: RichtextContent}
    | {type: 'headline'; content: HeadlineV2Content}
    | {type: 'textParagraph'; content: TextParagraphContent}
  )[];
}

export const getItemContent = (
  accordionContent: AccordionContent['components'],
  cdnOrigin: string,
) => {
  if (accordionContent.length === 0) {
    return null;
  }
  return accordionContent.map((c) => {
    switch (c.type) {
      case `headline`:
        return <HeadlineV2Wrapper content={c.content} cdnOrigin={cdnOrigin} />;
      case `textParagraph`:
        return (
          <TextParagraphWrapper content={c.content} cdnOrigin={cdnOrigin} />
        );
      case `extendedFlexibleGallery`:
        return <ExtendedFlexibleGalleryWrapper content={c.content} />;
      case `teaserRowV2`:
        return <TeaserRowV2Wrapper content={c.content} />;
      case `statement`:
        return <StatementWrapper content={c.content} />;
      case `textWithMedia`:
        return (
          <TextWithMediaWrapper content={c.content} cdnOrigin={cdnOrigin} />
        );
      case `mediaStage`:
        return (
          <MediaStageWrapper
            content={{...c.content, enableSideSpacing: true}}
            cdnOrigin={cdnOrigin}
          />
        );
      case `richtext`:
        return <RichtextWrapper content={c.content} inAccordion />;

      default:
        return null;
    }
  });
};
