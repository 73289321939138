import {LegalDisclaimerStandalone} from '@smart/editorial-components';
import type {LegalDisclaimersContent} from '@smart/website-aem-utils';
import {createLegalDisclaimerProps} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';

export interface LegalDisclaimersWrapperProps {
  readonly content: LegalDisclaimersContent;
}

export const LegalDisclaimersWrapper = React.memo(
  ({content}: LegalDisclaimersWrapperProps) => {
    const {historyService} = useFeatureServices();
    return (
      <LegalDisclaimerStandalone
        mode={content.mode}
        legalDisclaimers={createLegalDisclaimerProps(
          content.legalDisclaimers,
          historyService,
        )}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

LegalDisclaimersWrapper.displayName = `LegalDisclaimersWrapper`;
