import type {
  CreateMediaUrlOptions,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
import type {AddOnContent} from '../create-add-on-content.js';
import {
  AddOnContentFragment,
  PriceTagContentFragment,
  createAddOnContent,
} from '../create-add-on-content.js';

export interface VideoStageContent {
  readonly type: 'video-stage';
  readonly id: string;
  readonly anchorId: string;
  readonly headline: string | undefined;
  readonly posterImageUrl: string;
  readonly videoLandscapeUrl: string;
  readonly videoPortraitUrl: string;
  readonly autoPlay: boolean | undefined;
  readonly videoControls: boolean | undefined;
  readonly autoLoop: boolean | undefined;
  readonly altText: string;
  readonly addOn: AddOnContent | undefined;
  readonly link: LinkContent | undefined;
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName: string | undefined;
}

const VideoStageContentFragment = z.object({
  headline: z.string().nullish(),
  posterImage: z.object({_path: z.string()}),
  videoLandscape: z.object({_path: z.string()}),
  videoPortrait: z.object({_path: z.string()}),
  autoPlay: z.boolean().nullish(),
  videoControls: z.boolean().nullish(),
  autoLoop: z.boolean().nullish(),
  addOn: AddOnContentFragment.nullish(),
  priceTag: PriceTagContentFragment.nullish(),
  altText: z.string(),
  linkDestination: LinkDestination.nullish(),
  linkLabel: z.string().nullish(),
  legalDisclaimer: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
});

export function createVideoStageContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'VideoStageModel'}>,
  options: CreateMediaUrlOptions,
): VideoStageContent {
  const sanitizedContentFragment = sanitizeGraphqlData(
    VideoStageContentFragment,
    contentFragment,
  );

  const {
    headline,
    posterImage,
    videoLandscape,
    videoPortrait,
    autoPlay,
    videoControls,
    autoLoop,
    altText,
    priceTag,
    addOn = priceTag,
    linkDestination,
    linkLabel,
    legalDisclaimer,
    mboxName,
  } = sanitizedContentFragment;

  return {
    type: `video-stage`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    headline,
    posterImageUrl: createMediaUrl(`image`, posterImage._path, options),
    videoLandscapeUrl: createMediaUrl(`content`, videoLandscape._path, options),
    videoPortraitUrl: createMediaUrl(`content`, videoPortrait._path, options),
    autoPlay,
    videoControls,
    autoLoop,
    altText,
    addOn: addOn ? createAddOnContent(addOn) : undefined,
    link: createLinkContent({destination: linkDestination, label: linkLabel}),
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
  };
}
