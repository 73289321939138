export interface OneTrustObject {
  GetDomainData(): OneTrustDomainData;
  ToggleInfoDisplay(): void;
}

export interface OneTrustDomainData {
  readonly Groups: OneTrustDomainDataGroup[];
  readonly GeneralVendors: OneTrustVendor[];
}

export interface OneTrustDomainDataGroup {
  readonly Cookies: OneTrustCookie[];
}

export interface OneTrustVendor {
  readonly Name: string;
  readonly Description: string;
  readonly PrivacyPolicyUrl: string;
  readonly Cookies: OneTrustCookie[];
}

export interface OneTrustCookie {
  readonly Name: string;
  readonly Host: string;
  readonly isThirdParty: boolean;
  readonly description: string;
}

export function getOneTrustObject(): OneTrustObject | undefined {
  const oneTrustObject = (window as any).OneTrust;

  return isOneTrustObject(oneTrustObject) ? oneTrustObject : undefined;
}

function isOneTrustObject(value: unknown): value is OneTrustObject {
  return typeof value === `object` && value !== null;
}
