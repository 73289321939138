import type {DeepExcludeNull, LinkContent} from '@smart/aem-utils';
import {
  LinkDestination,
  createId,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
export interface UspItemsShamContent {
  readonly iconName?: string;
  readonly description?: string;
  readonly highlightSubline?: string;
  readonly title?: string;
  readonly textLink?: LinkContent;
}

export interface UspContent {
  readonly type: 'usp';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly variant?: 'full-width' | '50/50' | '2/3';
  readonly mode?: modeType;
  readonly layout?: 'list' | 'grid';
  readonly iconType?:
    | 'icon'
    | 'icon-accent'
    | 'icon-dark-grey'
    | 'number'
    | 'number-accent'
    | 'number-dark-grey'
    | 'highlight';
  readonly subline?: string;
  readonly headline?: string;
  readonly paragraph?: string;
  readonly uspItems?: UspItemsShamContent[];
  readonly link?: LinkContent;
  readonly mboxName?: string;
}

const UspItemsFragment = z.object({
  iconName: z.string().nullish(),
  description: z.string().nullish(),
  textDescription: z
    .object({
      markdown: z.string().nullish(),
    })
    .nullish(),
  highlightSubline: z.string().nullish(),
  title: z.string().nullish(),
  textLabel: z.string().nullish(),
  textDestination: LinkDestination.nullish(),
});

const UspContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  variant: z.string().nullish(),
  mode: z.string().nullish(),
  layout: z.string().nullish(),
  iconType: z.string().nullish(),
  subline: z.string().nullish(),
  headline: z.string().nullish(),
  paragraph: z.object({markdown: z.string().nullish()}).nullish(),
  uspItems: z.array(UspItemsFragment).nullish(),
  linkLabel: z.string().nullish(),
  linkDestination: LinkDestination.nullish(),
  mboxName: z.string().nullish(),
});

export function createUspContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'UspModel'}>,
): UspContent {
  const {
    removeTopSpacing,
    iconType,
    uspItems,
    variant,
    mode,
    layout,
    subline,
    headline,
    paragraph,
    linkLabel,
    linkDestination,
    mboxName,
  } = sanitizeGraphqlData(UspContentFragment, contentFragment);

  return {
    type: `usp`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    removeTopSpacing,
    iconType: iconType as UspContent['iconType'],
    variant: variant as UspContent['variant'],
    layout: layout as UspContent['layout'],
    subline,
    headline,
    uspItems: createUSPItems(uspItems || []),
    paragraph: paragraph?.markdown,
    mode: mode as UspContent['mode'],
    link: createLinkContent({
      destination: linkDestination,
      label: linkLabel,
    }),
    mboxName,
  };
}

function createUSPItems(
  models: readonly DeepExcludeNull<z.TypeOf<typeof UspItemsFragment>>[],
): UspItemsShamContent[] {
  return models.map((model) => {
    const {
      iconName,
      description,
      textDescription,
      highlightSubline,
      title,
      textLabel,
      textDestination,
    } = model;

    return {
      iconName,
      description: textDescription?.markdown ?? description,
      highlightSubline,
      title,
      textLink: createLinkContent({
        destination: textDestination,
        label: textLabel,
      }),
    };
  });
}
