import * as React from 'react';
import styles from './navigation-indicator.module.scss';

export function NavigationIndicator(): JSX.Element {
  return (
    <div className={styles.navigationIndicator}>
      <div className={styles.progressBar}></div>
    </div>
  );
}
