import {isGlobalFeatureFlagsEnabled} from '@smart/platform-utils';
import type {ShoppingCartDetails} from '@smart/website-shopping-cart-details';
import * as React from 'react';
import {FEATURE_FLAGS} from '../../types';
import {useLocale} from '../components/voice-of-customer/hooks';
import {getDemoVehicleMode} from '../utils/client/get-demo-vehicle-mode';
import {useFeatureServices} from './use-feature-services';

export interface UseShoppingCartDetailsOptions {
  readonly enabled: boolean;
}

export function useShoppingCartDetails({
  enabled,
}: UseShoppingCartDetailsOptions): ShoppingCartDetails | undefined {
  const [shoppingCartDetails, setShoppingCartDetails] =
    React.useState<ShoppingCartDetails>();

  const {
    cartUpdateService,
    logger,
    loginService,
    pubSubService,
    adbContextService,
  } = useFeatureServices();
  const locale = useLocale();
  const {marketId} = locale;
  const fetchAbortControllerRef = React.useRef<AbortController>();

  React.useEffect(() => {
    const enableAnonymousFlow = isGlobalFeatureFlagsEnabled(
      FEATURE_FLAGS.ENABLE_NOT_AUTH_FLOW,
      marketId,
    );

    if (
      (!enabled || loginService?.loginState.status !== `logged-in`) &&
      !enableAnonymousFlow
    ) {
      return;
    }

    const fetchShoppingCartDetails = () => {
      fetchAbortControllerRef.current?.abort();

      const fetchAbortController = (fetchAbortControllerRef.current =
        new AbortController());

      const isDemoVehicleSession = getDemoVehicleMode();

      const isDemoVehicle =
        isDemoVehicleSession && adbContextService?.adbContext?.hasCustomer;

      const searchParams = new URLSearchParams({
        isDemoVehicle: isDemoVehicle ? `true` : `false`,
        enableAnonymousFlow: String(enableAnonymousFlow),
      });

      fetch(
        `${
          process.env.PRIVATE_BASE_URL
        }/shopping-cart-details/?${searchParams.toString()}`,
        {
          signal: fetchAbortController.signal,
        },
      )
        .then(async (response) => {
          if (response.ok) {
            setShoppingCartDetails(await response.json());
          } else if (response.status === 401) {
            logger.warn(`The user has an invalid session and is logged out.`);
            loginService?.logout();
          } else {
            logger.warn(`Unable to fetch shopping cart details.`);
          }
        })
        .catch((error) => {
          if (!fetchAbortController.signal.aborted) {
            logger.error(`Unable to fetch shopping cart details.`, error);
          }
        });
    };

    fetchShoppingCartDetails();

    const subscribeAbortController = new AbortController();

    cartUpdateService.subscribe(fetchShoppingCartDetails, {
      signal: subscribeAbortController.signal,
    });

    pubSubService.subscribe(`cart-update`, fetchShoppingCartDetails, {
      signal: subscribeAbortController.signal,
    });

    return () => {
      subscribeAbortController.abort();
      fetchAbortControllerRef.current?.abort();
    };
  }, [enabled]);

  return shoppingCartDetails;
}
