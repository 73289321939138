import type {BaseFeatureApp} from '@feature-hub/react';
import * as React from 'react';
import {makeCancelable} from './make-cancelable.js';
import type {FeatureAppScopeResult} from './use-async-feature-app-scope.js';

export interface UseFeatureAppOptions<TFeatureApp extends BaseFeatureApp> {
  readonly featureAppScopeResult: FeatureAppScopeResult<TFeatureApp>;
  readonly timeoutInSeconds?: number;
}

export type FeatureAppResult<TFeatureApp extends BaseFeatureApp> =
  | FeatureAppLoadingResult<TFeatureApp>
  | FeatureAppLoadedResult<TFeatureApp>
  | FeatureAppTimeoutResult
  | FeatureAppErrorResult;

export interface FeatureAppLoadingResult<TFeatureApp extends BaseFeatureApp> {
  readonly state: 'loading';
  readonly featureApp?: TFeatureApp;
}

export interface FeatureAppLoadedResult<TFeatureApp extends BaseFeatureApp> {
  readonly state: 'loaded';
  readonly featureApp: TFeatureApp;
}

export interface FeatureAppTimeoutResult {
  readonly state: 'timeout';
  readonly timeoutInSeconds: number;
}

export interface FeatureAppErrorResult {
  readonly state: 'error';
  readonly error: unknown;
}

export function useFeatureApp<TFeatureApp extends BaseFeatureApp>(
  options: UseFeatureAppOptions<TFeatureApp>,
): FeatureAppResult<TFeatureApp> {
  const {featureAppScopeResult, timeoutInSeconds = 30} = options;

  const [result, setResult] = React.useState<FeatureAppResult<TFeatureApp>>(
    () => {
      if (featureAppScopeResult.state === `loaded`) {
        const {featureApp} = featureAppScopeResult.featureAppScope;

        return {
          state: featureApp.loadingPromise ? `loading` : `loaded`,
          featureApp,
        };
      }

      return featureAppScopeResult;
    },
  );

  if (featureAppScopeResult.state === `loaded`) {
    if (result.state === `loading` && !result.featureApp) {
      const {featureApp} = featureAppScopeResult.featureAppScope;

      setResult({
        state: featureApp.loadingPromise ? `loading` : `loaded`,
        featureApp,
      });
    }
  } else if (featureAppScopeResult.state === `error`) {
    setResult(featureAppScopeResult);
  }

  React.useEffect(() => {
    if (featureAppScopeResult.state !== `loaded`) {
      return;
    }

    const {featureApp} = featureAppScopeResult.featureAppScope;

    if (!featureApp.loadingPromise) {
      return;
    }

    const {promise, cancel} = makeCancelable(
      Promise.race<
        FeatureAppLoadedResult<TFeatureApp> | FeatureAppTimeoutResult
      >([
        featureApp.loadingPromise.then(() => ({state: `loaded`, featureApp})),
        new Promise((resolve) =>
          setTimeout(resolve, timeoutInSeconds * 1000),
        ).then(() => ({state: `timeout`, timeoutInSeconds})),
      ]),
    );

    promise
      .then(setResult)
      .catch((error) => setResult({state: `error`, error}));

    return cancel;
  }, [featureAppScopeResult]);

  return result;
}
