import type {Content} from '@smart/website-aem-utils';
import type {ActivityContentFragment} from '@smart/website-personalization';

export interface FetchContentOptions {
  readonly contentFragment: ActivityContentFragment;
  readonly signal: AbortSignal;
}

export async function fetchContent(
  options: FetchContentOptions,
): Promise<Content | undefined> {
  const {contentFragment, signal} = options;
  const {modelPath, path} = contentFragment;
  const pathname = `${process.env.BASE_URL}/content`;
  const searchParams = new URLSearchParams({modelPath, path});
  const url = `${pathname}?${searchParams.toString()}`;
  const response = await fetch(url, {signal});

  return response.ok ? (response.json() as Promise<Content>) : undefined;
}
