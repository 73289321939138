import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
import {
  type LogoContainerContent,
  LogoContainerContentFragment,
  createLogoContainer,
} from '../create-logo-container.js';

export type BackgroundColorOption =
  | 'premium #1'
  | 'premium #3'
  | 'brabus'
  | 'pulse'
  | 'pro+'
  | 'pro,pure+,pure';

export type CampaignItemType = {
  readonly logo?: LogoContainerContent;
} & PatternType;

export type OfferItemType = {
  readonly price?: string;
  readonly fromLabel?: string;
  readonly backgroundColorOption: BackgroundColorOption;
} & PatternType;

export type PatternType = {
  readonly imageLandscapeUrl?: string;
  readonly imagePortraitUrl?: string;
  readonly mediaAltText?: string;
  readonly headLine?: string;
  readonly subHeadLine?: string;
  readonly primaryLink?: LinkContent;
  readonly renderPopupButton?: boolean;
};
export type ItemsType = OfferItemType | CampaignItemType;
export interface MidBannerContent {
  readonly type: 'mid-banner';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing: boolean;
  readonly modeSelector?: modeType;
  readonly variant: 'campaign' | 'award' | 'offer';
  readonly items: ItemsType[];
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName?: string;
}

const MidBannerItemsContentFragment = z.object({
  lmage: z.object({_path: z.string()}).nullish(),
  mediaAltText: z.string().nullish(),
  imagePortrait: z.object({_path: z.string()}).nullish(),
  backgroundColorOption: z.string().nullish(),
  fromLabel: z.string().nullish(),
  price: z.string().nullish(),
  headLine: z.string().nullish(),
  subHeadLine: z.string().nullish(),
  primaryLinkLabel: z.string().nullish(),
  primaryLinkDestination: LinkDestination.nullish(),
  logo: LogoContainerContentFragment.nullish(),
  renderPopupButton: z.boolean().nullish(),
});

const MidBannerContentFragment = z.object({
  removeTopSpacing: z.boolean(),
  variant: z.string(),
  modeSelector: z.string().nullish(),
  items: z.array(MidBannerItemsContentFragment),
  legalDisclaimer: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
});

function createMidBannerItems(
  items: readonly DeepExcludeNull<
    z.TypeOf<typeof MidBannerItemsContentFragment>
  >[],
  options: CreateMediaUrlOptions,
) {
  return items.map((item) => {
    const {
      lmage,
      imagePortrait,
      backgroundColorOption,
      fromLabel,
      price,
      headLine,
      subHeadLine,
      logo,
      primaryLinkLabel,
      mediaAltText,
      primaryLinkDestination,
      renderPopupButton,
    } = item;
    return {
      imageLandscapeUrl:
        lmage != null
          ? createMediaUrl(`image`, lmage._path, options)
          : undefined,
      imagePortraitUrl:
        imagePortrait != null
          ? createMediaUrl(`image`, imagePortrait._path, options)
          : undefined,
      backgroundColorOption: backgroundColorOption as BackgroundColorOption,
      fromLabel,
      price,
      headLine,
      mediaAltText,
      subHeadLine,
      primaryLink: createLinkContent({
        destination: primaryLinkDestination,
        label: primaryLinkLabel,
      }),
      logo: createLogoContainer(logo, options),
      renderPopupButton,
    };
  });
}

export function createMidBannerContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'MidBannerModel'}>,
  options: CreateMediaUrlOptions,
): MidBannerContent {
  const {
    removeTopSpacing,
    variant,
    modeSelector,
    items,
    legalDisclaimer,
    mboxName,
  } = sanitizeGraphqlData(MidBannerContentFragment, contentFragment);
  return {
    type: `mid-banner`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    removeTopSpacing,
    variant: variant as MidBannerContent['variant'],
    modeSelector: modeSelector as modeType,
    items: createMidBannerItems(items, options),
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
  };
}
