import type {OneTrustCookie} from './get-one-trust-object';
import {getOneTrustObject} from './get-one-trust-object';

export function buildCookieListMarkdown(): string {
  const oneTrustObject = getOneTrustObject();

  if (!oneTrustObject) {
    return ``;
  }

  const domainData = oneTrustObject.GetDomainData();
  const markdown: string[] = [];

  for (const {
    Name: vendorName,
    Description: vendorDescription,
    PrivacyPolicyUrl,
    Cookies,
  } of domainData.GeneralVendors) {
    markdown.push(`### ${vendorName}`, ``);

    if (vendorDescription) {
      markdown.push(`${vendorDescription}`, ``);
    }

    if (PrivacyPolicyUrl) {
      markdown.push(`${PrivacyPolicyUrl}`, ``);
    }

    markdown.push(`#### Cookies`, ``);

    for (const {Name: cookieName, description: cookieDescription} of Cookies) {
      markdown.push(`**${cookieName}**`, ``);

      if (cookieDescription) {
        markdown.push(cookieDescription, ``);
      }
    }
  }

  const unknownCookies: OneTrustCookie[] = [];

  for (const {Cookies} of domainData.Groups) {
    unknownCookies.push(...Cookies);
  }

  if (unknownCookies.length > 0) {
    markdown.push(`---`, ``, `### Cookies`, ``);
  }

  for (const {
    Name: cookieName,
    description: cookieDescription,
  } of unknownCookies) {
    markdown.push(`**${cookieName}**`, ``);

    if (cookieDescription) {
      markdown.push(cookieDescription, ``);
    }
  }

  return markdown.join(`\n`);
}
