import type {LinkContent} from '@smart/aem-utils';
import {
  LinkDestination,
  createId,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface StatementContent {
  readonly type: 'statement';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly mode?: string;
  readonly subline?: string;
  readonly headline: string;
  readonly primaryLink?: LinkContent;
  readonly secondaryLink?: LinkContent;
  readonly mboxName?: string;
}

export const StatementContentFragment = z.object({
  removeTopSpacing: z.boolean().nullish(),
  mode: z.string().nullish(),
  subline: z.string().nullish(),
  headline: z.string(),
  primaryLinkLabel: z.string().nullish(),
  primaryLinkDestination: LinkDestination.nullish(),
  secondaryLinkLabel: z.string().nullish(),
  secondaryLinkDestination: LinkDestination.nullish(),
  mboxName: z.string().nullish(),
});

export function createStatementContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'StatementModel'}>,
): StatementContent {
  const {
    removeTopSpacing,
    mode,
    subline,
    headline,
    primaryLinkLabel,
    primaryLinkDestination,
    secondaryLinkLabel,
    secondaryLinkDestination,
    mboxName,
  } = sanitizeGraphqlData(StatementContentFragment, contentFragment);

  return {
    type: `statement`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    removeTopSpacing,
    mode,
    subline,
    headline,
    primaryLink: createLinkContent({
      destination: primaryLinkDestination,
      label: primaryLinkLabel,
    }),
    secondaryLink: createLinkContent({
      destination: secondaryLinkDestination,
      label: secondaryLinkLabel,
    }),
    mboxName,
  };
}
